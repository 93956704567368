import React from "react";
import PropTypes from "prop-types";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Close as CloseIcon } from "@mui/icons-material";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      "& .MuiDialog-container > .MuiPaper-root": {
        backgroundColor: "#fff",
        "& .MuiDialogTitle-root": {
          "& .MuiIconButton-root": {
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
          },
        },
        "& .MuiDialogContent-root": {
          "& .MuiCardContent-root": {
            backgroundColor: "#E5E5E5",
          },
          "& .MuiCollapse-root": {
            backgroundColor: "#E5E5E5",
          },
        },
      },
    },
  };
});

const Modal = ({
  fullWidth,
  maxWidth,
  openModal,
  onCloseModal,
  onScrollContent,
  dialogTitle,
  titleWithClose,
  fullScreen,
  dividers,
  children,
  footerChildren,
  ...props
}) => {
  const classes = useStyles();

  const handleClose = () => {
    onCloseModal();
  };

  const handleScroll = (e) => {
    onScrollContent(e);
  };

  const header = (dialogTitle || titleWithClose) && (
    <DialogTitle id="dialog-title">
      {titleWithClose && (
        <IconButton
          aria-label="close"
          onClick={handleClose}
          size="large"
          color="inherit"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      )}
      {dialogTitle}
    </DialogTitle>
  );

  const footer = footerChildren && (
    <DialogActions>{footerChildren}</DialogActions>
  );

  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      className={`${classes.root} ${props.className ?? ""}`}
      open={openModal}
      onClose={handleClose}
      fullScreen={fullScreen}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      sx={props.sx}
    >
      {header}
      <DialogContent
        onScroll={handleScroll}
        id="dialog-description"
        dividers={dividers}
      >
        {children}
      </DialogContent>
      {footer}
    </Dialog>
  );
};

Modal.propTypes = {
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs", false]),
  openModal: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onScrollContent: PropTypes.func,
  dialogTitle: PropTypes.node,
  titleWithClose: PropTypes.bool,
  fullScreen: PropTypes.bool,
  dividers: PropTypes.bool,
  children: PropTypes.node.isRequired,
  footerChildren: PropTypes.element,
};

Modal.defaultProps = {
  openModal: false,
  titleWithClose: false,
  fullScreen: false,
  dividers: false,
};

export default Modal;

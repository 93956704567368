import { Fragment, useState, useRef } from "react";
import PropTypes from "prop-types";
import { TextField, Fab } from "@mui/material";
import { styled } from "@mui/material/styles";

import Alert from "base-components/Alert";

import { useHttp } from "hooks";
import { CONTACT_ENDPOINTS } from "constants/Api";

const Form = styled("form")(({ theme }) => ({
  padding: theme.spacing(1, 4, 3),
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

const MoreInformationForm = ({
  productFilter,
  page,
  description,
  callbackFunc,
}) => {
  const [alert, setAlert] = useState({
    position: { vertical: "top", horizontal: "center" },
    open: false,
    message: "",
    hideDuration: 4000,
    status: "success",
  });
  const firstnameRef = useRef(null);
  const surnameRef = useRef(null);
  const emailRef = useRef(null);
  const telRef = useRef(null);

  const { sendRequest: postHttpUserInformation } = useHttp();

  const onCloseAlertHandler = () => {
    setAlert((prevAlert) => ({
      ...prevAlert,
      open: false,
    }));
  };

  const submitUserInformation = (e) => {
    e.preventDefault();

    const {
      province,
      startdate,
      enddate,
      productType,
      size = "",
      model = "",
    } = productFilter;

    const body = {
      name: firstnameRef.current.value,
      surname: surnameRef.current.value,
      email: emailRef.current.value,
      tel: telRef.current.value,
      province,
      startdate,
      enddate,
      productType,
      size,
      model,
      page,
      description,
    };

    if (!body.name || !body.surname || !body.tel) {
      setAlert((prevAlert) => ({
        ...prevAlert,
        open: true,
        position: { vertical: "bottom", horizontal: "center" },
        message:
          "กรุณากรอก ชื่อ-สกุล และเบอร์โทรศัพท์ เพื่อใช้เป็นข้อมูลสำหรับติดต่อกลับ",
        status: "error",
      }));
      return;
    }

    postHttpUserInformation(
      {
        endpoint: CONTACT_ENDPOINTS.create,
        method: "POST",
        body,
      },
      (postResp) => {
        console.log(postResp);
        setAlert((prevAlert) => ({
          ...prevAlert,
          open: true,
          message: "ส่งข้อมูลเรียบร้อยแล้ว เจ้าหน้าที่จะติดต่อกลับภายหลัง",
        }));
        callbackFunc();
      }
    );
  };

  return (
    <Fragment>
      <Form onSubmit={submitUserInformation}>
        <TextField
          variant="standard"
          label="ชื่อ"
          type="text"
          inputRef={firstnameRef}
          sx={{ mt: 1, mb: 2 }}
        />
        <TextField
          variant="standard"
          label="นามสกุล"
          type="text"
          inputRef={surnameRef}
          sx={{ mb: 2 }}
        />
        <TextField
          variant="standard"
          label="เบอร์โทร"
          type="tel"
          inputRef={telRef}
          sx={{ mb: 2 }}
        />
        <TextField
          variant="standard"
          label="อีเมล์"
          type="email"
          inputRef={emailRef}
          helperText={<small>ไม่บังคับ</small>}
          sx={{ mb: 5 }}
        />
        <Fab
          color="secondary"
          type="submit"
          variant="extended"
          sx={{ width: "75%", mx: "auto" }}
        >
          ส่งรายละเอียด
        </Fab>
      </Form>

      <Alert
        position={alert.position}
        open={alert.open}
        message={alert.message}
        hideDuration={alert.hideDuration}
        status={alert.status}
        onClose={onCloseAlertHandler}
      />
    </Fragment>
  );
};

MoreInformationForm.propTypes = {
  productFilter: PropTypes.shape({
    province: PropTypes.string,
    productType: PropTypes.string,
    startdate: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.string,
    ]),
    enddate: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.string,
    ]),
    size: PropTypes.string,
    model: PropTypes.string,
  }),
  page: PropTypes.oneOf(["home", "filter"]),
  // description: PropTypes.oneOf(["long term", "not found"]),
  description: PropTypes.string,
  callbackFunc: PropTypes.func,
};

MoreInformationForm.defaultProps = {
  callbackFunc: () => {},
};

export default MoreInformationForm;

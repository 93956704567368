import PropTypes from "prop-types";

import {
  FormControl as MuiFormControl,
  InputLabel,
  Select,
  MenuItem,
  ListSubheader,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const FormControl = styled(MuiFormControl, {
  shouldForwardProp: (prop) => prop !== "inputValueFontSize",
})(({ inputValueFontSize }) => ({
  minWidth: "100%",
  minHeight: 50,
  "& .MuiInputBase": {
    "&-input": {
      fontSize: inputValueFontSize === "sm" && "small",
    },
  },
}));

const SelectDropdownInput = ({
  subheaderName,
  firstMenuItem,
  menuPropName,
  menuItems,
  selectProp,
  selected,
  onSelect,
  inputValueFontSize,
  ...commonProps
}) => {
  const handleSelect = (e) => {
    const { name, value } = e.target;
    onSelect(name, value);
  };

  const subheaderElement = subheaderName && (
    <ListSubheader>{subheaderName}</ListSubheader>
  );

  const firstMenuElement = firstMenuItem && (
    <MenuItem value={firstMenuItem.value}>{firstMenuItem.label}</MenuItem>
  );

  const menuItemElements =
    menuItems.length > 0 &&
    menuItems.map((item, idx) => (
      <MenuItem
        key={item.id ? item.id : idx}
        value={selectProp ? item[selectProp] : item}
      >
        {menuPropName ? item[menuPropName] : item}
      </MenuItem>
    ));

  return (
    <FormControl
      variant={commonProps.variant}
      inputValueFontSize={inputValueFontSize}
      sx={commonProps.className}
    >
      <InputLabel id={`${commonProps.name}-select-input`}>
        {commonProps.label}
      </InputLabel>

      <Select
        labelId={`${commonProps.name}-select-input`}
        id={`${commonProps.name}-select`}
        name={commonProps.name}
        value={selected}
        onChange={handleSelect}
      >
        {subheaderElement}
        {firstMenuElement}
        {menuItemElements}
      </Select>
    </FormControl>
  );
};

SelectDropdownInput.propTypes = {
  label: PropTypes.node.isRequired,
  subheaderName: PropTypes.string.isRequired,
  firstMenuItem: PropTypes.exact({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }),
  menuItems: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.arrayOf(PropTypes.object),
  ]).isRequired,
  variant: PropTypes.oneOf(["filled", "outlined", "standard"]),
};

SelectDropdownInput.defaultProps = {
  menuItems: [],
  variant: "standard",
};

export default SelectDropdownInput;

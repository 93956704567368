import PropTypes from "prop-types";

import { Paper, Typography, IconButton, Collapse } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ArrowDropUp as ArrowDropUpIcon } from "@mui/icons-material";

const StatusBar = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "color",
})(({ theme, color: { themeColor, intense } }) => {
  return {
    position: "relative",
    flexGrow: 1,
    padding: theme.spacing(1, 3),
    borderRadius: 0,
    backgroundColor: theme.palette[themeColor][intense],
  };
});

const StageCard = ({
  stageName,
  title,
  color,
  countItems,
  expanded,
  onClickArrow,
  ...props
}) => {
  const handleClickArrow = (state, expanded) => () => {
    onClickArrow(state, expanded);
  };

  const countItemsText = countItems > 1 && `(${countItems})`;

  return (
    <StatusBar elevation={0} color={color}>
      <IconButton
        onClick={handleClickArrow(stageName, expanded)}
        size="large"
        sx={(theme) => ({
          position: "absolute",
          top: -5,
          right: -5,
          transform: "rotate(0deg)",
          marginLeft: "auto",
          transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
          }),
          ...(expanded && {
            transform: "rotate(180deg)",
          }),
        })}
      >
        <ArrowDropUpIcon color={color.themeColor} />
      </IconButton>
      <Typography color={color.themeColor} variant="subtitle2">
        {title} {countItemsText}
      </Typography>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {props.children}
      </Collapse>
    </StatusBar>
  );
};

StageCard.propTypes = {
  title: PropTypes.string,
  color: PropTypes.exact({
    themeColor: PropTypes.string,
    intense: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  countItems: PropTypes.number,
  stageName: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
};

StageCard.defaultProps = {
  countItems: 1,
};

export default StageCard;

import PropTypes from "prop-types";

import { styled } from "@mui/material";

const Gap = styled("div")(({ theme, gap }) => {
  if (gap && (typeof gap === "string" || typeof gap === "number"))
    return { minHeight: theme.spacing(gap) };
  if (gap && typeof gap === "object")
    return {
      ...(gap.xs && { minHeight: theme.spacing(gap.xs) }),
      ...(gap.sm && {
        [theme.breakpoints.up("sm")]: {
          minHeight: theme.spacing(gap.sm),
        },
      }),
      ...(gap.md && {
        [theme.breakpoints.up("md")]: {
          minHeight: theme.spacing(gap.md),
        },
      }),
      ...(gap.lg && {
        [theme.breakpoints.up("lg")]: {
          minHeight: theme.spacing(gap.lg),
        },
      }),
      ...(gap.xl && {
        [theme.breakpoints.up("xl")]: {
          minHeight: theme.spacing(gap.xl),
        },
      }),
    };
  return theme.mixins.toolbar;
});

const Offset = ({ gap }) => <Gap gap={gap} />;

Offset.propTypes = {
  gap: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.shape({
      xs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      sm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      md: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      lg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      xl: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ]),
};

Offset.defaultProps = {
  gap: 20,
};

export default Offset;

import { Fragment } from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

import {
  FormControl as MuiFormControl,
  InputLabel,
  Select,
  ListSubheader,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const FormControl = styled(MuiFormControl, {
  shouldForwardProp: (prop) => prop !== "size",
})(({ theme, size }) => {
  const isMedium = size === "medium";
  return {
    width: "100%",
    "& .MuiFormLabel-root": {
      fontSize: isMedium ? "1rem" : ".75rem",
    },
    "& .MuiInputBase-root": {
      fontSize: isMedium ? "1rem" : ".75rem",
      "& .MuiFilledInput-input": {
        paddingTop: theme.spacing(isMedium ? 1.5 : 0.5),
      },
      "& .MuiOutlinedInput-input": {
        paddingTop: theme.spacing(isMedium ? 1.5 : 0.5),
        paddingLeft: theme.spacing(isMedium ? 1.5 : 0.5),
      },
    },
  };
});

const InlineFormControl = styled(MuiFormControl, {
  shouldForwardProp: (prop) => prop !== "size" && prop !== "helperText",
})(({ theme, width, size }) => {
  const isMedium = size === "medium";
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiFormLabel-root": {
      fontSize: isMedium ? "1rem" : ".75rem",
    },
    "& .MuiInputLabel-formControl": {
      display: "flex",
      justifyContent: "flex-start",
      alignSelf: "flex-start",
      position: "static",
      transform: "translate(0,0)",
      flex: `1 0 ${width.label}`,
      paddingTop: theme.spacing(isMedium ? 1.5 : 0.5),
      paddingLeft: 0,
      [theme.breakpoints.up("md")]: {
        justifyContent: "center",
        paddingLeft: theme.spacing(2),
      },
    },
    "& .MuiInputBase-root": {
      flexBasis: width.input,
      fontSize: isMedium ? "1rem" : ".75rem",
      "& .MuiFilledInput-input": {
        paddingTop: theme.spacing(isMedium ? 1.5 : 0.5),
      },
      "& .MuiOutlinedInput-input": {
        paddingTop: theme.spacing(isMedium ? 1.5 : 0.5),
        paddingLeft: theme.spacing(isMedium ? 1.5 : 0.5),
      },
    },
  };
});

const InputWithHelperText = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flexBasis: "100%",
  "& .MuiFormHelperText-contained": {
    margin: theme.spacing(0.5, 0),
  },
}));

const SelectDropdownInput = ({
  control,
  defaultValue,
  subheaderName,
  firstMenuItem,
  menuItems,
  valueProp,
  menuProp,
  onChangeInputSelect,
  ...props
}) => {
  return (
    <Controller
      name={props.name}
      defaultValue={defaultValue}
      control={control}
      render={({ field: { name, value, onChange }, fieldState: { error } }) => {
        const onChangeSelect = (callback) => (newValue) => {
          if (onChangeInputSelect)
            return onChangeInputSelect(name, newValue, callback);
          callback(newValue);
        };

        const SelectFormLayout = (style = "default", parentProps, children) => {
          const components = {
            default: (
              <FormControl {...parentProps} error={!!parentProps.error}>
                <InputLabel
                  id={parentProps.htmlFor}
                  error={!!parentProps.error}
                >
                  {parentProps.label}
                </InputLabel>
                {children}
              </FormControl>
            ),
            inline: (
              <InlineFormControl {...parentProps} error={!!parentProps.error}>
                <InputLabel
                  id={parentProps.htmlFor}
                  error={!!parentProps.error}
                >
                  {parentProps.label}
                </InputLabel>
                {children}
              </InlineFormControl>
            ),
          };

          return components[style];
        };

        const subheaderElement = subheaderName && (
          <ListSubheader>{subheaderName}</ListSubheader>
        );
        const firstMenuElement = firstMenuItem && (
          <MenuItem value={firstMenuItem.value}>{firstMenuItem.label}</MenuItem>
        );
        const menuItemElements =
          menuItems.length > 0 &&
          menuItems.map((item, idx) => (
            <MenuItem
              key={item.id ? item.id : idx}
              value={valueProp ? item[valueProp] : item}
            >
              {menuProp ? item[menuProp] : item}
            </MenuItem>
          ));

        const helperContent =
          !error && props.helperText ? (
            <FormHelperText>{props.helperText}</FormHelperText>
          ) : null;

        const errorText = error?.message ? (
          <FormHelperText error={!!error}>{error.message}</FormHelperText>
        ) : null;

        return SelectFormLayout(
          props.inline ? "inline" : "default",
          {
            variant: props.variant,
            htmlFor: `${name}${props.inline ? "-inline" : ""}__select`,
            label: props.label,
            disabled: props.disabled,
            width: props.width,
            size: props.size,
            error,
            sx: props.sx,
          },
          props.inline ? (
            <InputWithHelperText>
              <Select
                labelId={`${name}-inline__select`}
                name={name}
                value={value}
                onChange={onChangeSelect(onChange)}
              >
                {subheaderElement}
                {firstMenuElement}
                {menuItemElements}
              </Select>
              {errorText}
              {helperContent}
            </InputWithHelperText>
          ) : (
            <Fragment>
              <Select
                labelId={`${name}__select`}
                name={name}
                value={value}
                onChange={onChangeSelect(onChange)}
              >
                {subheaderElement}
                {firstMenuElement}
                {menuItemElements}
              </Select>
              {helperContent}
            </Fragment>
          )
        );
      }}
    />
  );
};

SelectDropdownInput.propTypes = {
  control: PropTypes.object.isRequired,
  label: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  onChangeInputSelect: PropTypes.func,
  subheaderName: PropTypes.string,
  inline: PropTypes.bool,
  helperText: PropTypes.node,
  firstMenuItem: PropTypes.exact({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }),
  menuItems: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.arrayOf(PropTypes.object),
  ]).isRequired,
  width: PropTypes.shape({
    label: PropTypes.string,
    input: PropTypes.string,
  }),
  variant: PropTypes.oneOf(["filled", "outlined", "standard"]),
  size: PropTypes.oneOf(["small", "medium"]),
};

SelectDropdownInput.defaultProps = {
  disabled: false,
  menuItems: [],
  variant: "standard",
  size: "small",
  inline: true,
  width: {
    label: "25%",
    input: "75%",
  },
};

export default SelectDropdownInput;

import React from "react";

import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const MachineTypeCard = styled("div", {
  shouldForwardProp: (prop) =>
    prop !== "isActive" && prop !== "selected" && prop !== "imgWidth",
})(({ theme, isActive, selected, imgWidth }) => {
  let textStyles = {
    color: theme.palette.grey[500],
  };
  if (isActive && selected)
    textStyles = { color: theme.palette.primary.main, fontWeight: "bold" };
  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",
    borderRadius: "10px",
    margin: theme.spacing(0.25),
    WebkitTransition: "all .3ss ease-in-out",
    MozTransition: "all .3s ease-in-out",
    OTransition: "all .3s ease-in-out",
    transition: "all .3s ease-in-out",
    filter: !isActive && "blur(1px) grayscale(100%)",
    border:
      isActive && selected
        ? `4px solid ${theme.palette.primary.main}`
        : "2px solid #797777",
    "& img": {
      margin: theme.spacing(0.5, "auto", 0),
      objectFit: "contain",
      overflow: "hidden",
      width: imgWidth || "100%",
    },
    "& .MuiTypography-root": {
      fontSize: ".75rem",
      [theme.breakpoints.up("nxs")]: {
        fontSize: "1rem",
      },
      ...textStyles,
    },
  };
});

const MachineTypeList = ({
  nameTH,
  imgUrl,
  imgWidth,
  isActive,
  selected,
  onSelectProduct,
}) => {
  const handleSelectProduct = (productName) => () => {
    onSelectProduct(productName);
  };

  return (
    <MachineTypeCard
      isActive={isActive}
      selected={selected}
      imgWidth={imgWidth}
      onClick={handleSelectProduct(nameTH)}
    >
      <img src={imgUrl} alt={`${nameTH}__img`} />
      <Typography textAlign="center">{nameTH}</Typography>
    </MachineTypeCard>
  );
};

export default MachineTypeList;

import { Fragment, forwardRef } from "react";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

const UnorderedList = styled("ul")({ marginTop: 0 });

const ContentPDPA = forwardRef((_, ref) => {
  return (
    <Fragment>
      <Typography component="p" variant="subtitle2" sx={{ mb: 3 }}>
        นโยบายคุ้มครองข้อมูลส่วนบุคคลฉบับนี้ (“<strong>นโยบาย</strong>”)
        ทำขึ้นโดยบริษัท เกษตรอินโน จำกัด (เรียกว่า “<strong>บริษัท</strong>”)
        เพื่อแจ้งรายละเอียดเกี่ยวกับการเก็บรวบรวม ใช้ และ เปิดเผย (“
        <strong>ประมวลผล</strong>”) ข้อมูลส่วนบุคคลของท่าน
        ตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 (“
        <strong>พ.ร.บ.ฯ</strong>”)
      </Typography>
      <Typography component="p" variant="subtitle2" sx={{ mb: 1 }}>
        <strong>ข้อมูลที่เก็บรวบรวมจากท่าน</strong>: บริษัทเก็บรวบรวม
        <strong>ข้อมูลส่วนบุคคล</strong>ของท่าน
        ซึ่งเป็นข้อมูลที่อาจระบุตัวตนหรือเชื่อมโยงไปยังตัวตนของท่านได้ทั้งทางตรงและทางอ้อม
        เช่น ชื่อ นามสกุล เลขบัตรประจำตัวประชาชน หมายเลขโทรศัพท์ ที่อยู่อีเมล
        ที่อยู่ และข้อมูลอื่น ๆ ที่เกี่ยวข้องกับท่าน
        ในการใช้เว็บไซต์หรือแอปพลิเคชันของบริษัท (“<strong>เว็บไซต์</strong>”)
        รวมถึงข้อมูลทางเทคนิคอื่น ๆ เช่น ที่อยู่ไอพี คุกกี้ เป็นต้น
      </Typography>
      <Typography component="p" variant="subtitle2" sx={{ mb: 3 }}>
        นอกจากนี้หากท่านซื้อสินค้าหรือบริการผ่านทางเว็บไซต์หรือช่องทางอื่น
        บริษัทอาจเก็บรวบรวมข้อมูลเพิ่มเติม เช่น รายละเอียดเกี่ยวกับคำสั่งซื้อ
        การซื้อสินค้าและ/หรือบริการของบริษัทโดยท่าน ข้อมูลเกี่ยวกับการชำระเงิน
        เช่น บัญชีธนาคาร บัตรเครดิตหรือบัตรเดบิต รวมถึงชื่อผู้ถือบัตร (ถ้ามี)
        เป็นต้น ทั้งนี้{" "}
        <strong>
          บริษัทจะไม่เก็บรวบรวมข้อมูลส่วนบุคคลที่มี ความอ่อนไหวจากท่าน เช่น
          เชื้อชาติ ความคิดเห็นทางการเมือง ศาสนาหรือปรัชญา ประวัติอาชญากรรม
          ข้อมูลสุขภาพ เป็นต้น เว้นแต่ท่านได้ให้ความยินยอมโดยชัดแจ้ง
        </strong>
      </Typography>
      <Typography component="p" variant="subtitle2" sx={{ mb: 3 }}>
        <strong>บริษัทเก็บรวบรวมข้อมูลส่วนบุคคลของท่านอย่างไร</strong>:
        บริษัทเก็บรวบรวมข้อมูลส่วนบุคคลจากท่านโดยตรงผ่านทางเว็บไซต์หรือแอปพลิเคชัน
        หรือ เมื่อท่านเข้าร่วมกิจกรรมต่าง ๆ ของบริษัท
        หรือช่องทางอื่นใดที่บริษัทได้รับข้อมูล ส่วนบุคคลจากท่านโดยตรง และ
        บริษัทอาจเก็บรวบรวมข้อมูลส่วนบุคคลจากแหล่งอื่น เช่น นายจ้าง
        สถานที่จัดงานอบรมต่าง ๆ บริษัทในเครือ ผู้แทนจำหน่ายของบริษัทในเครือ
        บุคคลแวดล้อม (เช่น ญาติ เพื่อนบ้าน ผู้ใหญ่บ้าน เป็นต้น)
        หรือคู่ค้าหรือบุคคลภายนอกอื่น ๆ หรือตามแหล่งข้อมูลสาธารณะ เช่น
        อินเทอร์เน็ต สื่อสังคมออนไลน์ (เช่น Facebook เป็นต้น) หรือ
        แหล่งข้อมูลอื่น ๆ ที่เปิดเผยต่อสาธารณะ เป็นต้น
      </Typography>
      <Typography component="div" variant="subtitle2" sx={{ mb: 3 }}>
        <strong>บริษัทใช้ข้อมูลส่วนบุคคลของท่านอย่างไร</strong>:
        บริษัทประมวลผลข้อมูลส่วนบุคคลของท่านเพื่อวัตถุประสงค์ดังต่อไปนี้
        <UnorderedList>
          <li>
            การติดต่อสื่อสารกับท่าน การทำความรู้จักท่าน
            และการยืนยันความถูกต้องของข้อมูลส่วนบุคคลที่บริษัทได้รับ
          </li>
          <li>
            เพื่อเสนอขาย และการจัดการที่เกี่ยวกับสินค้าและบริการของบริษัท
            รวมทั้งเพื่อติดต่อรับคำสั่งซื้อและจัดส่งสินค้า ตามคำสั่งซื้อของท่าน
          </li>
          <li>
            การทำสัญญาและการปฏิบัติตามสัญญาระหว่างท่านกับบริษัท
            และกิจกรรมหรือธุรกรรมอื่น ๆ ที่เกี่ยวข้อง
          </li>
          <li>
            การให้บริการหรือสินค้าแก่ท่าน เช่น ให้บริการเว็บไซต์หรือแอปพลิเคชัน
            ข้อมูลสินค้าหรือบริการของบริษัท ซ่อมแซมสินค้า รับประกันสินค้า
            ให้คำปรึกษาเกี่ยวกับสินค้าหรือบริการของบริษัท เป็นต้น
          </li>
          <li>การปรับปรุงและพัฒนาสินค้าและบริการ</li>
          <li>
            การดำเนินการใด ๆ ที่จำเป็นต่อการให้บริการผ่านเว็บไซต์ หรือ
            ช่องทางอิเล็กทรอนิกส์อื่น ๆ{" "}
          </li>
          <li>
            เพื่อป้องกันการฝ่าฝืน และการปฏิบัติตามกฎหมาย
            รวมถึงการดำเนินการตามคำสั่งของพนักงานเจ้าหน้าที่
            และหน่วยงานทางปกครอง
          </li>
          <li>
            เพื่อประโยชน์ในการประกอบธุรกิจ เช่น การโฆษณา การประชาสัมพันธ์
            การส่งเสริมการขาย หรือการติดต่อสื่อสารอื่น ๆ
            เพื่อเสนอหรือปรับปรุงสินค้าหรือบริการ
            เพื่อจัดกิจกรรมเกี่ยวกับการตลาด การฝึกอบรม การวิเคราะห์ข้อมูล
            การตลาดแบบตรง การจัดการและทดสอบระบบสารสนเทศ
            ระบบและมาตรการควบคุมเพื่อรักษาความปลอดภัย
            เพื่อการตรวจสอบภายในของบริษัท การเก็บ บันทึก สำรอง
            หรือทำลายข้อมูลส่วนบุคคล เป็นต้น
          </li>
        </UnorderedList>
      </Typography>
      <Typography component="p" variant="subtitle2" sx={{ mb: 3 }}>
        <strong>การเปิดเผยข้อมูลส่วนบุคคลของท่าน</strong>:
        เพื่อการดำเนินการตามวัตถุประสงค์ข้างต้น บริษัทอาจเปิดเผย หรือ โอนข้อมูล
        ส่วนบุคคลของท่านให้แก่บุคคลดังต่อไปนี้ เช่น
        ผู้แทนจำหน่ายของบริษัทในเครือ บริษัทในเครือ
        ผู้จัดงานหรือผู้สนับสนุนการจัดกิจกรรมต่าง ๆ ของบริษัท
        ลูกค้าของบริษัทที่เกี่ยวข้องกับการจัดกิจกรรมต่าง ๆ
        บุคคลภายนอกผู้ให้บริการ ที่เกี่ยวข้อง เช่น ธนาคาร สำนักงานบัญชี เป็นต้น
        บริษัทอาจเปิดเผยข้อมูลส่วนบุคคลของท่านต่อบุคคลที่ท่านได้ให้ความยินยอมให้รับข้อมูลส่วนบุคคลของท่าน
        หรือกรณี ผู้ที่เกี่ยวข้องหากมีการควบรวมกิจการหรือเข้าซื้อธุรกิจ
        ตลอดจนศาล พนักงานเจ้าหน้าที่หรือหน่วยงานของรัฐ ทั้งนี้
        บริษัทจะดำเนินการที่จำเป็นเพื่อคุ้มครองข้อมูลส่วนบุคคลของท่านตามที่
        พ.ร.บ.ฯ กำหนด
      </Typography>
      <Typography component="p" variant="subtitle2" sx={{ mb: 3 }}>
        <strong>การเก็บรักษาข้อมูลส่วนบุคคล</strong>:
        บริษัทเก็บรักษาข้อมูลส่วนบุคคลของท่านไว้ตลอดระยะเวลาที่ท่านยังมีนิติสัมพันธ์
        หรือ ติดต่อกับบริษัท และเมื่อสิ้นสุดความสัมพันธ์
        บริษัทจะเก็บรักษาข้อมูลส่วนบุคคลของท่านไว้ต่อไปอีกไม่เกิน 10 ปี
        เว้นแต่มีกฎหมายกำหนดไว้เป็นอย่างอื่น
      </Typography>

      <Typography component="p" variant="subtitle2" sx={{ mb: 3 }}>
        <strong>สิทธิของเจ้าของข้อมูลส่วนบุคคล</strong>: ท่านมีสิทธิ (1)
        ขอให้ดำเนินการลบ ทำลาย หรือทำให้ข้อมูลส่วนบุคคลของท่าน
        ไม่สามารถระบุตัวบุคคลได้ (2) ขอให้โอน
        หรือขอรับข้อมูลส่วนบุคคลของท่านในรูปแบบที่สามารถอ่านหรือใช้งานได้ทั่วไปด้วยเครื่องมือหรืออุปกรณ์ที่ทำงานโดยอัตโนมัติ
        (3) คัดค้านการประมวลผลข้อมูลส่วนบุคคลของท่าน หรือขอให้
        ระงับการใช้ข้อมูลส่วนบุคคลนั้น (4) ขอเข้าถึง
        และขอรับสำเนาข้อมูลส่วนบุคคลของท่าน (5)
        ขอให้ดำเนินการให้ข้อมูลส่วนบุคคลของท่านถูกต้อง สมบูรณ์ และเป็นปัจจุบัน
        (6) ร้องเรียนต่อคณะกรรมการคุ้มครองข้อมูลส่วนบุคคลเกี่ยวกับ
        การประมวลผลข้อมูลส่วนบุคคลของท่าน และ (7)
        ขอเพิกถอนความยินยอมในการประมวลผลข้อมูลส่วนบุคคล ทั้งนี้
        ตามขอบเขตที่กฎหมายกำหนด
        โดยบริษัทจะดำเนินการหรืออาจปฏิเสธคำขอของท่านได้เฉพาะตามที่กฎหมายอนุญาต
      </Typography>

      <Typography component="p" variant="subtitle2" sx={{ mb: 3 }}>
        สำหรับการประมวลผลข้อมูลส่วนบุคคลของท่านที่บริษัทได้รับยกเว้นไม่ต้องขอความยินยอมตาม
        พ.ร.บ.ฯ เช่น หน้าที่ตามกฎหมาย การทำสัญญาหรือปฎิบัติหน้าที่ตามสัญญา
        เป็นต้น หากท่านคัดค้าน ไม่ยินยอม
        หรือไม่ประสงค์ให้บริษัทประมวลผลข้อมูลส่วนบุคคล
        อาจส่งผลกระทบต่อการให้บริการ
        หรือการดำเนินการที่เกี่ยวข้องและต้องใช้ข้อมูลส่วนบุคคลดังกล่าว{" "}
      </Typography>

      <Typography component="p" variant="subtitle2" sx={{ mb: 3 }}>
        <strong>ข้อมูลส่วนบุคคลของผู้เยาว์</strong>: บริษัทไม่มีเจตนา
        และไม่อาจทราบถึงอายุของผู้เยี่ยมชมเว็บไซต์หรือแอปพลิเคชันของบริษัทได้
        หากท่านเป็นบิดามารดาหรือผู้ปกครองของผู้เยาว์ที่ให้ข้อมูลส่วนบุคคลแก่บริษัทโดยที่ท่านไม่ทราบและไม่ได้ให้ความยินยอม
        ท่านอาจติดต่อบริษัทและขอให้บริษัทลบข้อมูลส่วนบุคคลนั้นได้
      </Typography>

      <Typography component="p" variant="subtitle2" sx={{ mb: 3 }}>
        <strong>ติดต่อบริษัท</strong>: หากท่านมีข้อสงสัยเกี่ยวกับนโยบายฉบับนี้
        หรือประสงค์ที่จะใช้สิทธิเกี่ยวกับการประมวลผลข้อมูล ส่วนบุคคลของท่าน
        ท่านสามารถติดต่อสอบถามได้ที่ E-mail : contact@kasetinno.com Call Center
        : 065-965-7530
      </Typography>

      <Typography component="p" variant="subtitle2">
        <strong>คำรับรอง</strong>
      </Typography>
      <Typography ref={ref} component="p" variant="subtitle2" sx={{ mb: 3 }}>
        ท่านได้อ่านทำความเข้าใจนโยบายฉบับนี้ และยินยอมให้บริษัทเก็บรวบรวม ใช้
        และเปิดเผยข้อมูลส่วนบุคคลของท่าน
        (รวมทั้งข้อมูลส่วนบุคคลที่มีความอ่อนไหวกรณีที่ท่านได้ให้ไว้กับบริษัท)
        พร้อมทั้งทราบดีถึงผลกระทบที่อาจเกิดขึ้น
        หากปฏิเสธการให้ข้อมูลส่วนบุคคลตามที่ระบุในนโยบายฉบับนี้
      </Typography>

      <Typography component="p" variant="subtitle2">
        <strong>การให้ความยินยอมเพื่อวัตถุประสงค์ทางการตลาด</strong>
      </Typography>
      <Typography ref={ref} component="p" variant="subtitle2">
        ท่านให้ความยินยอมแก่บริษัทในการประมวลผลข้อมูลส่วนบุคคล
        <u>เพื่อการดำเนินกิจกรรมทางการตลาด</u>
        รวมถึง เพื่อเสนอสินค้า บริการ โปรโมชั่นพิเศษ
        และการทำการตลาดแบบตรงแก่ท่าน
        โดยบริษัทอาจเปิดเผยข้อมูลส่วนบุคคลดังกล่าวให้แก่บริษัทในเครือ
        ผู้แทนจำหน่ายของบริษัทในเครือ ผู้ให้บริการ หรือ บุคคลใด ๆ
        เฉพาะที่เกี่ยวข้องเพื่อวัตถุประสงค์ ทางการตลาดในลักษณะเดียวกันนี้
        ทั้งนี้ ตามรายละเอียดที่ระบุไว้ในนโยบายฉบับนี้
      </Typography>
    </Fragment>
  );
});

export default ContentPDPA;

import { Fragment } from "react";
import { useHistory } from "react-router-dom";

import { CssBaseline, Container, Button } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import {
  components,
  breakpoints,
  palette,
  typography,
} from "styles/themes/RenterTheme";

import {
  // Navbar,
  NavCard,
  Stepbar,
  Offset,
  // Sidebar,
} from "./components";

const renterTheme = createTheme({
  components,
  breakpoints,
  palette,
  typography,
});

const RenterLayout = ({ children, step, fullWidth }) => {
  const history = useHistory();

  const navigateToPage = (page) => () => {
    history.push(page);
  };

  const childrenElement = fullWidth ? (
    <Fragment>{children}</Fragment>
  ) : (
    <Container maxWidth="sm">{children}</Container>
  );

  return (
    <ThemeProvider theme={renterTheme}>
      <CssBaseline />
      <NavCard
        navActions={
          <Button color="primary" onClick={navigateToPage("/booking-history")}>
            ประวัติการจอง
          </Button>
        }
      />
      <Offset gap={{ xs: 22, sm: 27 }} />
      {step && <Stepbar title={step} />}
      {childrenElement}
    </ThemeProvider>
  );
};

export default RenterLayout;

// Constanst Varaibles
export const TRACTOR = {
  th: "แทรคเตอร์",
  en: "tractor",
};
export const COMBINE_HARVESTER = {
  th: "รถเกี่ยวนวดข้าว",
  en: "combine harvester",
};
export const IMPLEMENT = {
  th: "อุปกรณ์ต่อพ่วง",
  en: "implement",
};
export const MAXIMUM_IMPLEMENTS = 3;

// Booking stages
export const STAGES = {
  draft: { nameEN: "draft", nameTH: "ฉบับร่าง" },
  book: { nameEN: "book", nameTH: "อยู่ระหว่างดำเนินการเช่า" },
  paid1: { nameEN: "paid1", nameTH: "ชำระเงินแล้ว" },
  active: { nameEN: "active", nameTH: "อยู่ระหว่างเช่า" },
  paid2: { nameEN: "paid2", nameTH: "อยู่ระหว่างเช่า" },
  completed: { nameEN: "completed", nameTH: "ครบกำหนดสัญญา" },
  return: { nameEN: "return", nameTH: "ครบกำหนดสัญญา" },
  cancel: { nameEN: "cancel", nameTH: "ยกเลิกสัญญา" },
};

// Local Storage KEY
export const FILTER = "KRENT_FILTER";
export const SELECTED_PRODUCTS = "KRENT_SELECTED_PRODUCTS";

// Session Storage KEY
export const USER_DATA = "KRENT_USER_DATA";
export const CONFIRMED = "KRENT_CONFIRMED_RENTING_DATA";

// Line OA Urls
export const LINE_OA_URL =
  process.env.REACT_APP_LINE_OA_URL ||
  "https://liff.line.me/1645278921-kWRPP32q/?accountId=839uafuc";

// Backend APIs
export const endpoints = {
  /* Provinces and products that have vehicles to service */
  filter: "/filter/",
  /* Provinces, Districts, Sub-districts */
  geo(province = "all") {
    return `/geo/${province}`;
  },
  /* Products */
  queryVIN(vin) {
    if (!vin) return "";
    return `/products/queryvin/${vin}`;
  },
  /* Product -> Types */
  products: "/products/",
  queryVehicles({
    province_name,
    product_name,
    series_name = "all",
    model_name = "all",
    diffDays,
  }) {
    if (!product_name || !province_name || !diffDays) return "";
    return `/products/queryvehicle/${product_name}?province=${province_name}&size=${series_name}&model=${model_name}&days=${diffDays}&page=0`;
  },
  queryImplements(modelName, ADName, diffDays) {
    if (!modelName || !ADName || !diffDays) return "";
    return `/products/implement_list/${modelName}?ad=${ADName}&days=${diffDays}`;
  },
  /* Bookings */
  termsAndConditions(bookingId) {
    if (!bookingId) return;
    return `/booking/terms/${bookingId}`;
  },
  acceptPDPA: "/booking/pdpa/true",
  draftBooking(bookingId) {
    if (!bookingId) return "/booking/draft";
    return `/booking/draft/${bookingId}`;
  },
  createBooking: "/booking/create",
  cancelBooking(bookingId) {
    if (!bookingId) return "";
    return `/booking/cancel/${bookingId}`;
  },
  booking(bookingId = "", params = undefined) {
    if (params) {
      const { stage = "active", limit = "1", sortBy = "desc" } = params;
      return `/booking/id${
        bookingId ? `/${bookingId}` : ""
      }?stage=${stage}&limit=${limit}&sort_by=${sortBy}`;
    }
    return `/booking/id/${bookingId}`;
  },
  contractDetail(bookingId) {
    if (!bookingId) return;
    return `/booking/contract/${bookingId}`;
  },
  /* Booking -> User */
  user: "/booking/user",
};

// Payment APIs
export const PAYMENT_API_BASE_URL =
  process.env.REACT_APP_PAYMENT_API_BASE_URL ||
  "https://kin-payment-linux-test.kaset-inno.com";
export const paymentEndpoints = {
  generateQR(bookingId) {
    if (!bookingId) return "";
    return `/kinrent/qrcode/${bookingId}`;
  },
  getQR(bookingId, payRound) {
    if (!bookingId || !payRound) return "";
    return `/kinrent/qrcode/${bookingId}/${payRound}`;
  },
  getInvoice(bookingId) {
    if (!bookingId) return "";
    return `/kinrent/invoice/${bookingId}`;
  },
  getReceipt(bookingId, payRound) {
    if (!bookingId || !payRound) return "";
    return `/kinrent/receipt/${bookingId}/${payRound}`;
  },
  verfifySlip(bookingId, payRound) {
    if (!bookingId || !payRound) return "";
    return `/kinrent/verify/${bookingId}/${payRound}`;
  },
};

import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

import { AppBar, Toolbar } from "@mui/material";

const FooterBar = styled(AppBar)(({ theme }) => ({
  top: "auto",
  bottom: -2,
  backgroundColor: theme.palette.common.white,
  "& .MuiToolbar-root": {
    justifyContent: "center",
  },
}));

const Footer = ({ children, ...props }) => {
  return (
    <FooterBar position="fixed" {...props}>
      <Toolbar>{children}</Toolbar>
    </FooterBar>
  );
};

Footer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Footer;

import {
  Fragment,
  useState,
  useEffect,
  useContext,
  useCallback,
  forwardRef,
} from "react";
import { useHistory } from "react-router-dom";
// import Swal from "sweetalert2";
import { useForm, useWatch } from "react-hook-form";
import { differenceInDays, isAfter, addDays } from "date-fns";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// TEMP
// import PromotionBanner from "assets/banner-03-10-65.jpg";
// TEMP

import {
  Container,
  Grid,
  // Box,
  Chip,
  Typography,
  // Accordion,
  // AccordionSummary as MuiAccordionSummary,
  // AccordionDetails as MuiAccordionDetails,
  Fab,
  Button,
  AppBar,
  Toolbar,
  Snackbar,
  Alert as MuiAlert,
  Skeleton,
  FormHelperText,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled, useTheme } from "@mui/material/styles";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import AuthContext from "stores/Authentication/auth-context";
import { useHttp, useDate } from "hooks";
import { endpoints, STAGES, FILTER } from "utils/Config";
import { MIN_PRE_RESERVED_DAYS, MIN_RENTING_DAYS } from "constants/DateTime";

import MoreInformationForm from "pages/Templates/MoreInformationForm";
import {
  Modal,
  // NewModal,
  ScrollToTop,
} from "base-components";
import { DatePickerInput } from "components/HookForms";
import { SelectDropdownInput, MachineTypeList } from "components";

yup.addMethod(yup.date, "notPassedDay", function (errorMessage) {
  return this.test("test-not-passed-day", errorMessage, function (value) {
    const { path, createError } = this;
    return (
      isAfter(value, addDays(new Date(), MIN_PRE_RESERVED_DAYS)) ||
      createError({ path, message: errorMessage })
    );
  });
});

yup.addMethod(yup.date, "minRentingDays", function (errorMessage) {
  return this.test("test-min-renting-days", errorMessage, function (value) {
    const { parent, path, createError } = this;
    return (
      differenceInDays(value, parent?.start_date) >= MIN_RENTING_DAYS ||
      createError({ path, message: errorMessage })
    );
  });
});

const schemaValidation = yup.object().shape({
  start_date: yup
    .date()
    .notPassedDay(
      `กรุณาเลือกวันเริ่มต้นล่วงหน้าก่อน ${MIN_PRE_RESERVED_DAYS} วัน`
    )
    .transform((value) =>
      !(value instanceof Date) || isNaN(value.valueOf()) ? undefined : value
    )
    .required("กรุณา ระบุวันเริ่มต้น"),
  end_date: yup
    .date()
    .when("start_date", (_, schema) =>
      schema
        .minRentingDays(
          `จำนวนวันเช่าสินค้าขั้นต่ำ ${MIN_RENTING_DAYS} วันขึ้นไป`
        )
        .transform((value) =>
          !(value instanceof Date) || isNaN(value.valueOf()) ? undefined : value
        )
    )
    .required("กรุณา ระบุวันที่คืน"),
});

const Form = styled("form")(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(1, 0),
  "& .MuiPickersDateRangePickerInput-root": {
    [theme.breakpoints.down("md")]: {
      flexDirection: "row",
    },
    "& .MuiFormControl-root": {
      minWidth: "50%",
      paddingRight: theme.spacing(2),
      "&:last-child": {
        paddingRight: theme.spacing(3),
      },
    },
  },
}));

const GridProducts = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(0.25, 0),
  display: "flex",
  alignItems: "center",
}));

const GridSkeleton = styled(Grid)(({ theme }) => ({
  marginBottom: theme.spacing(0.25),
  "& .MuiSkeleton-root": {
    borderRadius: "10px",
    width: theme.spacing(15),
    height: theme.spacing(15),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(20),
      height: theme.spacing(20),
    },
  },
}));

const Footer = styled(AppBar)(({ theme }) => ({
  top: "auto",
  left: 0,
  bottom: 0,
  borderTopLeftRadius: "1rem",
  borderTopRightRadius: "1rem",
  width: "100%",
  height: "8%",
}));

// const AccordionSummary = styled((props) => (
//   <MuiAccordionSummary
//     expandIcon={
//       <ArrowForwardIosIcon sx={{ fontSize: (theme) => theme.spacing(2) }} />
//     }
//     {...props}
//   />
// ))(({ theme: { palette, spacing }, expanded }) => ({
//   minHeight: spacing(4),
//   backgroundColor: expanded ? palette.common.white : palette.secondary.main,
//   color: expanded ? palette.secondary.main : palette.common.white,
//   "& .MuiAccordionSummary-content": {
//     justifyContent: "flex-end",
//     margin: spacing(0, 1, 0, 0),
//   },
//   "& .MuiAccordionSummary-expandIconWrapper": {
//     color: "currentColor",
//     "&.Mui-expanded": {
//       transform: "rotate(90deg)",
//     },
//   },
// }));

// const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
//   backgroundColor: theme.palette.common.white,
// }));

const AlertBar = forwardRef(function Alert(props, ref) {
  return <MuiAlert ref={ref} elevation={6} variant="filled" {...props} />;
});

const HomePage = (props) => {
  const history = useHistory();
  // const { lineProfile } = useContext(LineContext);
  const { user } = useContext(AuthContext);
  // const classes = useStyles();
  const [activatedBooking, setActivatedBooking] = useState({});
  const [provinces, setProvinces] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState({
    product_name: "",
    province_name: "",
  });
  const [alert, setAlert] = useState(false);
  const [showModal, setShowModal] = useState({
    moreInformation: false,
    // promotionBanner: true,
  });
  const theme = useTheme();
  const matchesUpSm = useMediaQuery(theme.breakpoints.up("sm"));
  // const [showPromotionDetail, setShowPromotionDetail] = useState(false);

  const {
    control,
    formState: { errors },
    watch,
    setValue,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schemaValidation),
  });
  const [startDate, endDate] = useWatch({
    control,
    name: ["start_date", "end_date"],
  });

  const { isLoading, sendRequest: fetchProductsData } = useHttp();
  const { sendRequest: fetchBookingData } = useHttp();
  const {
    disabledStart,
    disabledEnd,
    pickedDays,
    defaultStartMonth,
    defaultEndMonth,
  } = useDate({
    startDate,
    endDate,
  });

  const onToggleModalHandler = useCallback(
    (state) => () => {
      setShowModal((prevModal) => ({
        ...prevModal,
        [state]: !prevModal[state],
      }));
    },
    []
  );

  useEffect(() => {
    const abortCont = new AbortController();
    const { signal } = abortCont;
    const { alreadyBook: activeBookingId } = user;
    /* Fetch options */
    const setProductDetail = (productDetail) => {
      const { products, province } = productDetail;
      const productsWithActive = products.map((product) => ({
        ...product,
        isActive: true,
        selected: false,
      }));

      setProvinces(province);
      setProducts(productsWithActive);
    };
    const fetchData = (signal = {}) => {
      fetchProductsData(
        {
          endpoint: endpoints.products,
          signal,
        },
        setProductDetail
      );
    };

    /* Fetch active booking */
    const setActivedBookingData = (bookingData) => {
      setActivatedBooking(bookingData);
    };
    const fetchBooking = (signal) => {
      fetchBookingData(
        {
          endpoint: endpoints.booking(activeBookingId),
          signal,
        },
        setActivedBookingData
      );
    };

    fetchData(signal);
    if (activeBookingId) {
      fetchBooking(signal);
    }

    return () => {
      abortCont.abort();
    };
  }, [fetchProductsData, fetchBookingData, user]);

  useEffect(() => {
    const subscription = watch((_, { name, type }) => {
      if (name === "start_date" && type === "change") setValue("end_date", "");
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  const handleCloseAlert = () => {
    setAlert(false);
  };

  // const toggleAccordion = () => {
  //   setShowPromotionDetail((prevShowPromo) => !prevShowPromo);
  // };

  const selectProvinceHanlder = (stateName, value) => {
    setSelectedFilter((prevSelected) => ({
      ...prevSelected,
      [stateName]: value,
    }));
  };

  const selectProductHanlder = (productName) => {
    if (!productName) return;

    setSelectedFilter((prevSelected) => {
      setProducts((prevProds) => {
        const changedProductsActive = prevProds.map((product) =>
          product.nameTH === productName
            ? {
                ...product,
                isActive: true,
                selected: true,
              }
            : { ...product, isActive: false, selected: false }
        );
        return changedProductsActive;
      });
      return { ...prevSelected, product_name: productName };
    });
  };

  // const addFriendBeforeRent = async () => {
  //   const response = await Swal.fire({
  //     titleText: "เพิ่ม KIN Companion เป็นเพื่อนก่อน",
  //     text: "กรุณาคลิกปุ่มด้านล่างเพื่อเพิ่ม KIN Companion เป็นเพื่อน เพื่อใช้รับการแจ้งเตือนธุรกรรมและข่าวสารที่จำเป็นในอนาคต",
  //     icon: "info",
  //     confirmButtonText: "เพิ่ม KIN Companion เป็นเพื่อน",
  //     width: "75%",
  //     showCloseButton: true,
  //     customClass: {
  //       container: classes.popUpContainer,
  //       popup: classes.popUpContent,
  //     },
  //   });

  //   if (response.isConfirmed)
  //     liff.openWindow({
  //       url: LINE_OA_URL,
  //       external: true,
  //     });
  // };

  const submitFilterData = (data) => {
    const { product_name, province_name } = selectedFilter;

    // if (!lineProfile.friendFlag) return addFriendBeforeRent();
    if (
      !product_name ||
      !province_name ||
      !data?.start_date ||
      !data?.end_date
    ) {
      setAlert(true);
      return;
    }

    /* Set filter data to local storage for using next page */
    localStorage.setItem(
      FILTER,
      JSON.stringify({
        start_date: data.start_date,
        end_date: data.end_date,
        province_name,
        product_name,
      })
    );

    /* Redirect to Machine Selection page */
    history.push("/machine-selection");
  };

  const onErrorSubmitForm = (data) => {
    if (data?.end_date?.message === "จำนวนวันเช่าสินค้าขั้นต่ำ 3 วันขึ้นไป")
      return;
    setAlert(true);
  };

  const goRentingDetailPage = (bookingId) => () => {
    history.push(`/renting/contract-detail/${bookingId}`);
  };

  /* =================================== */
  let productTypeLists = [...Array(3)].map((_item, idx) => (
    <GridSkeleton key={idx} item xs={6} sm={4}>
      <Skeleton
        animation="wave"
        variant="rectangular"
        sx={{ m: (theme) => theme.spacing(0, "auto") }}
      />
    </GridSkeleton>
  ));
  if (!isLoading && products.length > 0) {
    productTypeLists = products.map((product, idx) => {
      return (
        <Grid key={product.id} item xs={idx === 0 ? 12 : 6} sm={4}>
          <MachineTypeList
            nameTH={product.nameTH}
            imgUrl={product.imgUrl}
            isActive={product.isActive}
            selected={product.selected}
            onSelectProduct={selectProductHanlder}
            {...(idx === 0 && !matchesUpSm ? { imgWidth: "50%" } : false)}
          />
        </Grid>
      );
    });
  }

  const statusWording =
    STAGES[activatedBooking.stage ? activatedBooking.stage : "book"].nameTH;
  /* =================================== */

  return (
    <Fragment>
      <ScrollToTop />
      <Form onSubmit={handleSubmit(submitFilterData, onErrorSubmitForm)}>
        <Grid container>
          <Grid item xs={12} mb={1}>
            <Chip color="primary" label="สถานที่ใช้งาน" />
          </Grid>
          <Grid item xs={6} pr={2} mb={2}>
            <SelectDropdownInput
              label="เลือก จังหวัด"
              subheaderName="เลือก จังหวัด"
              menuItems={provinces}
              name="province_name"
              selected={selectedFilter.province_name}
              onSelect={selectProvinceHanlder}
            />
          </Grid>
          <Grid item xs={12} mb={1}>
            <Chip color="primary" label="วันที่ใช้งาน" />
          </Grid>
          <Grid item xs={6} mb={2} pr={2}>
            <DatePickerInput
              name="start_date"
              control={control}
              defaultValue=""
              label="วันที่เริ่มต้น"
              toolbarTitle="เลือก วันที่เริ่มต้นใช้งาน"
              inline={false}
              shouldDisableDate={disabledStart}
              defaultMonth={defaultStartMonth()}
              renderDay={pickedDays}
              size="medium"
            />
          </Grid>
          <Grid item xs={6} mb={2} pl={2}>
            <DatePickerInput
              name="end_date"
              control={control}
              defaultValue=""
              label="วันที่คืน"
              toolbarTitle="เลือก วันที่คืนเครื่องจักร"
              inline={false}
              shouldDisableDate={disabledEnd}
              defaultMonth={defaultEndMonth(MIN_RENTING_DAYS)}
              disabled={!startDate}
              renderDay={pickedDays}
              size="medium"
            />
          </Grid>
          {errors?.start_date?.type === "test-not-passed-day" ||
          errors?.end_date?.type === "test-min-renting-days" ? (
            <Grid container item justifyContent="center" xs={12} mb={2}>
              <FormHelperText error>
                {errors?.start_date?.message
                  ? errors.start_date.message
                  : errors.end_date.message}
              </FormHelperText>
            </Grid>
          ) : null}
          <Grid item xs={12} mb={2}>
            <Chip color="primary" label="สินค้าที่ให้บริการ" />
          </Grid>
          <GridProducts container justifyContent="center" alignItems="center">
            {productTypeLists}
          </GridProducts>
          <Grid container item xs={12} my={2}>
            <Fab
              size="medium"
              variant="extended"
              type="submit"
              color="secondary"
              sx={{ ml: "auto", px: 4 }}
            >
              <Typography variant="subtitle2">ค้นหา</Typography>
            </Fab>
          </Grid>
          <Grid item xs={12} mb={activatedBooking?.book_no ? 9 : 0}>
            <Button
              color="primary"
              onClick={onToggleModalHandler("moreInformation")}
              sx={{ textDecoration: "underline" }}
            >
              {/* {`สนใจเช่ารถใหม่ ระยะยาว 1 ปี คลิก>>`} */}
              {`ขอข้อมูลเพิ่มเติมและให้เจ้าหน้าที่ติดต่อกลับ>>`}
            </Button>
          </Grid>
        </Grid>
      </Form>

      {activatedBooking?.book_no && (
        <Footer
          onClick={goRentingDetailPage(activatedBooking.book_no)}
          position="fixed"
          color="primary"
        >
          <Container maxWidth="sm">
            <Toolbar component="footer" sx={{ position: "relative" }}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" sx={{ pl: 1 }}>
                    สถานะการจอง
                  </Typography>
                  <ArrowDropUpIcon
                    color="secondary"
                    fontSize="large"
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: (theme) => theme.spacing(0.5),
                    }}
                  />
                </Grid>

                <Grid item xs={6} pl={1}>
                  <Typography variant="subtitle2" sx={{ textIndent: 16 }}>
                    ใบจองเลขที่ {activatedBooking.book_no}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2">{statusWording}</Typography>
                </Grid>
              </Grid>
            </Toolbar>
          </Container>
        </Footer>
      )}
      {/* 
      <NewModal
        openModal={showModal.promotionBanner}
        onCloseModal={onToggleModalHandler("promotionBanner")}
        titleWithClose
        sx={(theme) => ({
          "& .MuiDialog-paper": {
            borderRadius: theme.spacing(1),
            border: `${theme.spacing(0.25)} solid ${
              theme.palette.secondary.main
            }`,
          },
        })}
      >
        <NewModal.Header
          closeButton
          onCloseModal={onToggleModalHandler("promotionBanner")}
          sx={({ palette, spacing }) => ({
            color: palette.common.white,
            bgcolor: palette.secondary.main,
            "& .MuiIconButton-root": {
              color: "inherit",
              paddingTop: 0,
              paddingRight: 0,
              "& .MuiSvgIcon-root": {
                fontSize: spacing(3),
              },
            },
          })}
        >
          <Typography align="center" variant="body1">
            โปรโมชันพิเศษ
          </Typography>
        </NewModal.Header>
        <NewModal.Content
          // dividers
          sx={{ p: 0 }}
        >
          <Box>
            <img
              src={PromotionBanner}
              alt="promotion-banner-img"
              style={{ objectFit: "contain", width: "100%" }}
            />
          </Box>
          <Accordion
            expanded={showPromotionDetail}
            onChange={toggleAccordion}
            disableGutters
            elevation={0}
          >
            <AccordionSummary expanded={showPromotionDetail ? 1 : 0}>
              <Typography variant="subtitle2">รายละเอียดเพิ่มเติม</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item xs={12} mb={2}>
                  <Typography variant="subtitle2" color="secondary">
                    โปรโมชันพิเศษ
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ textIndent: (theme) => theme.spacing(2) }}
                  >
                    ฟรีบัตรช่วยเหลือค่าน้ำมัน 5,000 บาท
                  </Typography>
                </Grid>
                <Grid item xs={12} mb={2}>
                  <Typography variant="subtitle2" color="secondary">
                    เวลาที่ใช้ได้
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ textIndent: (theme) => theme.spacing(2) }}
                  >
                    28-09-2565 00:00 - 20-10-2565 23.59
                  </Typography>
                </Grid>
                <Grid item xs={12} mb={2}>
                  <Typography variant="subtitle2" color="secondary">
                    สินค้า
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ textIndent: (theme) => theme.spacing(2) }}
                  >
                    รถเกี่ยวนวดข้าวรุ่นใดก็ได้
                  </Typography>
                </Grid>
                <Grid item xs={12} mb={2}>
                  <Typography variant="subtitle2" color="secondary">
                    การชำระเงิน
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ textIndent: (theme) => theme.spacing(2) }}
                  >
                    ใช้ได้กับทุกช่องทางการชำระเงิน
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" color="secondary">
                    เงื่อนไข
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ textIndent: (theme) => theme.spacing(2) }}
                  >
                    เมื่อเช่ารถเกี่ยวนวดข้าวรุ่นใดก็ได้โดยต้องเช่า 3
                    สัปดาห์ขึ้นไป จำกัดเฉพาะ 10 คันแรกเท่านั้น
                  </Typography>
                  <Typography variant="subtitle2">หมดเขต 20-10-2565</Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </NewModal.Content>
      </NewModal> */}

      <Modal
        fullWidth
        maxWidth="xs"
        titleWithClose
        openModal={showModal.moreInformation}
        onCloseModal={onToggleModalHandler("moreInformation")}
      >
        <MoreInformationForm
          productFilter={{
            province: selectedFilter.province_name,
            startdate: startDate,
            enddate: endDate,
            productType: selectedFilter.product_name,
          }}
          page="home"
          description="long term (request information)"
          callbackFunc={onToggleModalHandler("moreInformation")}
        />
      </Modal>

      <Snackbar
        open={alert}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={handleCloseAlert}
      >
        <AlertBar onClose={handleCloseAlert} severity="warning">
          กรุณาเลือกตัวกรองให้ครบถ้วนและถูกต้องก่อนค้นหา
        </AlertBar>
      </Snackbar>
    </Fragment>
  );
};

export default HomePage;

import { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Grid, Link, Typography, Skeleton as MuiSkeleton } from "@mui/material";
import { styled } from "@mui/material/styles";

import { useHttp } from "hooks";

import { endpoints, STAGES } from "utils/Config";

import StageCard from "./components/StageCard";
import BookingList from "./components/BookingList";

const Skeleton = styled(MuiSkeleton)(({ theme }) => ({
  margin: theme.spacing(1),
  borderRadius: 10,
  height: "26vh",
  transformOrigin: "0 0",
}));

const BookingHistory = (props) => {
  const history = useHistory();

  const { isLoading, sendRequest: fetchBookingData } = useHttp();

  const [expanded, setExpanded] = useState({
    active: true,
    draft: false,
    completed: false,
    cancel: false,
  });
  const [activatedBookings, setActivatedBookings] = useState([]);
  const [draftBookings, setDraftBookings] = useState([]);
  const [completedBookings, setCompletedBookings] = useState([]);
  const [cancelBookings, setCancelBookings] = useState([]);

  useEffect(() => {
    const abortCont = new AbortController();
    const { signal } = abortCont;

    const setBookingsData = (bookings) => {
      const {
        draft,
        book,
        paid1,
        active,
        paid2,
        completed,
        return: returned,
        cancel,
      } = STAGES;
      /* Activated booking */
      const activatedBookings = bookings.filter(
        ({ stage }) =>
          stage === book.nameEN ||
          stage === paid1.nameEN ||
          stage === active.nameEN ||
          stage === paid2.nameEN
      );
      const descActivatedBookings =
        activatedBookings.length > 1
          ? activatedBookings.sort((a, b) => b.book_no - a.book_no)
          : activatedBookings;
      setActivatedBookings(descActivatedBookings);

      /* Drafted booking */
      const draftBookings = bookings.filter(
        ({ stage }) => stage === draft.nameEN
      );
      const descDraftBookings =
        draftBookings.length > 1
          ? draftBookings.sort((a, b) => b.book_no - a.book_no)
          : draftBookings;
      setDraftBookings(descDraftBookings);

      /* Completed booking */
      const completedBookings = bookings.filter(
        ({ stage }) => stage === completed.nameEN || stage === returned.nameEN
      );
      const descCompletedBookings =
        completedBookings.length > 1
          ? completedBookings.sort((a, b) => b.book_no - a.book_no)
          : completedBookings;
      setCompletedBookings(descCompletedBookings);

      /* Cancel booking */
      const cancelBookings = bookings.filter(
        ({ stage }) => stage === cancel.nameEN
      );
      const descCancelBookings =
        cancelBookings.length > 1
          ? cancelBookings.sort((a, b) => b.book_no - a.book_no)
          : cancelBookings;
      setCancelBookings(descCancelBookings);
    };

    const fetchData = (signal) => {
      fetchBookingData(
        {
          endpoint: endpoints.booking(),
          signal,
        },
        setBookingsData
      );
    };

    fetchData(signal);

    return () => {
      abortCont.abort();
    };
  }, [fetchBookingData]);

  const onClickExpandedCard = (state, expanded) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [state]: !expanded,
    }));
  };

  const onClickMoreDetailPage = (stage, bookingId) => {
    if (stage === STAGES.draft.nameEN || stage === STAGES.cancel.nameEN)
      history.push(`/renting/summary/${bookingId}`, { bookingState: stage });
    else history.push(`/renting/contract-detail/${bookingId}`);
  };

  const activatedBookingElements = activatedBookings.length > 0 && (
    <StageCard
      stageName="active"
      expanded={expanded.active}
      onClickArrow={onClickExpandedCard}
      title="อยู่ในระหว่างดำเนินการเช่า"
      countItems={activatedBookings.length}
      color={{ themeColor: "primary", intense: "light" }}
    >
      {activatedBookings.map((booking) => (
        <BookingList
          key={+booking.id}
          booking={booking}
          onClickIcon={onClickMoreDetailPage}
        />
      ))}
    </StageCard>
  );

  const draftBookingElements = draftBookings.length > 0 && (
    <StageCard
      stageName="draft"
      expanded={expanded.draft}
      onClickArrow={onClickExpandedCard}
      title="ฉบับร่าง"
      countItems={draftBookings.length}
      color={{ themeColor: "secondary", intense: "light" }}
    >
      {draftBookings.map((booking) => (
        <BookingList
          key={+booking.id}
          booking={booking}
          onClickIcon={onClickMoreDetailPage}
        />
      ))}
    </StageCard>
  );

  const completedBookingElements = completedBookings.length > 0 && (
    <StageCard
      stageName="completed"
      expanded={expanded.completed}
      onClickArrow={onClickExpandedCard}
      title="เช่าสำเร็จ"
      countItems={completedBookings.length}
      color={{ themeColor: "grey", intense: 300 }}
    >
      {completedBookings.map((booking) => (
        <BookingList
          key={+booking.id}
          booking={booking}
          onClickIcon={onClickMoreDetailPage}
        />
      ))}
    </StageCard>
  );

  const cancelBookingElements = cancelBookings.length > 0 && (
    <StageCard
      stageName="cancel"
      expanded={expanded.cancel}
      onClickArrow={onClickExpandedCard}
      title="ยกเลิก"
      countItems={cancelBookings.length}
      color={{ themeColor: "grey", intense: 500 }}
    >
      {cancelBookings.map((booking) => (
        <BookingList
          key={+booking.id}
          booking={booking}
          onClickIcon={onClickMoreDetailPage}
        />
      ))}
    </StageCard>
  );

  return isLoading ? (
    <Skeleton variant="rectangular" animation="wave" />
  ) : !activatedBookings.length &&
    !draftBookings.length &&
    !completedBookings.length &&
    !cancelBookings.length ? (
    <Grid
      container
      item
      direction="column"
      justifyContent="center"
      alignItems="center"
      xs={12}
      mt={4}
    >
      <Typography variant="h5" sx={{ mb: 2 }}>
        ไม่พบประวัติการจองของท่าน
      </Typography>
      <Link
        onClick={(e) => {
          e.preventDefault();
          history.push("/");
        }}
        variant="body1"
        sx={(theme) => ({
          color: theme.palette.secondary.main,
          textDecorationColor: theme.palette.secondary.main,
        })}
      >
        กลับหน้าหลัก
      </Link>
    </Grid>
  ) : (
    <Fragment>
      {activatedBookingElements}
      {draftBookingElements}
      {completedBookingElements}
      {cancelBookingElements}
    </Fragment>
  );

  // return (
  //   <Box sx={{ flexGrow: 1 }}>
  //       {bookings}
  //   </Box>
  // );
};

export default BookingHistory;

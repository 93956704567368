import { Fragment, useContext } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import {
  AppBar as MuiAppBar,
  Toolbar as MuiToolbar,
  Container,
  Paper,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  // IconButton,
  Typography,
  styled,
} from "@mui/material";
// import MenuIcon from "@mui/icons-material/Menu";

import LineContext from "stores/LineLogin/line-context";
import AuthContext from "stores/Authentication/auth-context";
import KINRentLogo from "assets/KIN-Rent_192.png";
// import MockAvatar from "assets/m1_avatar.jpg";

const AppBar = styled(MuiAppBar)(({ theme }) => {
  return {
    boxShadow: "none",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      height: theme.spacing(14),
      width: "100%",
      backgroundColor: theme.palette.primary.main,
    },
    "&::after": {
      content: '""',
      position: "absolute",
      top: theme.spacing(14),
      left: 0,
      zIndex: -1,
      height: theme.spacing(3.5),
      width: "100%",
      backgroundColor: theme.palette.primary.main,
      borderRadius: "50% / 100%",
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
  };
});

const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  padding: theme.spacing(2, 0),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(2, 0),
  },
}));

const NavigationCard = styled(Paper)(({ theme }) => ({
  display: "flex",
  width: "100%",
  borderRadius: theme.spacing(1.25),
}));

const ImageLogo = styled("img")({
  width: "9.5rem",
});

const NavCard = ({ navActions }) => {
  const { lineProfile } = useContext(LineContext);
  const { user } = useContext(AuthContext);

  const history = useHistory();

  const navigateToPage = (page) => () => {
    history.push(page);
  };

  return (
    <Fragment>
      <AppBar color="transparent" postition="static" sx={{ boxShadow: "none" }}>
        <Container maxWidth="sm">
          <Toolbar disableGutters>
            <NavigationCard elevation={2}>
              <Grid container>
                <Grid
                  container
                  item
                  alignItems="center"
                  pl={1}
                  xs={6}
                  sx={{ flexGrow: 1, mb: 1 }}
                >
                  <ImageLogo
                    src={KINRentLogo}
                    alt="KasetInno__logo"
                    onClick={navigateToPage("/")}
                  />
                </Grid>
                <Grid item my="auto" ml="auto" pr={2} xs="auto">
                  {navActions}
                </Grid>
                <Grid item xs={12}>
                  <List sx={{ width: "100%", bgColor: "common.white", py: 0 }}>
                    <ListItem alignItems="center" divider sx={{ pt: 0 }}>
                      <ListItemAvatar sx={{ mr: 1 }}>
                        <Avatar
                          alt="line_avatar_img"
                          src={lineProfile?.pictureUrl}
                          // src={MockAvatar}
                          sx={{
                            width: 48,
                            height: 48,
                            bgcolor: (theme) => theme.palette.primary.main,
                          }}
                        />
                      </ListItemAvatar>
                      <ListItemText>
                        <Grid container>
                          <Grid
                            container
                            item
                            direction="column"
                            xs="auto"
                            sx={{ flexGrow: 1 }}
                          >
                            <Typography
                              component="h2"
                              variant="h6"
                              sx={{ my: "auto" }}
                            >
                              {user?.name && user?.surname
                                ? `${user.name} ${user.surname}`
                                : lineProfile?.displayName}
                              {/* เอก เรืองพจนารักษ์ */}
                            </Typography>
                          </Grid>
                          {/* <Grid container item xs>
                            <IconButton sx={{ ml: "auto", my: "auto" }}>
                              <MenuIcon fontSize="large" />
                            </IconButton>
                          </Grid> */}
                        </Grid>
                      </ListItemText>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </NavigationCard>
          </Toolbar>
        </Container>
      </AppBar>
    </Fragment>
  );
};

NavCard.propTypes = {
  navActions: PropTypes.node,
};

export default NavCard;

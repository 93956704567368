import React from "react";

import { Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const StepContent = styled(Paper)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  color: theme.palette.secondary.main,
  backgroundColor: theme.palette.grey[300],
  paddingLeft: theme.spacing(3.5),
  minHeight: theme.spacing(6),
}));

const Stepbar = ({ title }) => {
  return (
    <StepContent elevation={0} square>
      <Typography variant="body1">{title}</Typography>
    </StepContent>
  );
};

export default Stepbar;

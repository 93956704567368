export const ALL_WORK_HOURS = 9;
export const MINIMUM_DAYS_TO_INSTALLMENTS = 15;
export const MIN_PRE_RESERVED_DAYS = 3;
// export const MAX_PRE_RESERVED_DAYS = 18;
export const MAX_PRE_RESERVED_DAYS = 90;
export const MIN_RENTING_DAYS = 7;
export const SHORTERM_RENTING = 90;
export const GAPDAYS_TO_PAY = 2;
export const DATE_FORMAT = "dd/MM/yyyy";

export const hourChoices = (startTime = 8, workHours = ALL_WORK_HOURS) => {
  const choices = [];
  for (let i = 0; i <= workHours * 2; i++) {
    if (i % 2 === 0) {
      startTime += i / 2;
      choices.push(
        startTime.toString().length === 1
          ? `0${startTime}:00`
          : `${startTime}:00`
      );
    }
    if (i % 2 !== 0) {
      startTime += (i - 1) / 2;
      choices.push(
        startTime.toString().length === 1
          ? `0${startTime}:30`
          : `${startTime}:30`
      );
    }
  }
  return choices;
};

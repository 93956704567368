import PropTypes from "prop-types";
import MuiChip from "@mui/material/Chip";
import { styled } from "@mui/material/styles";

const OutlinedChip = styled(MuiChip, {
  shouldForwardProp: (prop) => prop !== "color" && prop !== "align",
})(({ theme, align, color }) => ({
  display: "flex",
  justifyContent: align,
  backgroundColor: "#fff",
  color: theme.palette[color].main,
  border: `1px solid ${theme.palette[color].main}`,
  boxShadow: theme.shadows[4],
}));

OutlinedChip.propTypes = {
  align: PropTypes.oneOf([
    "flex-start",
    "center",
    "flex-end",
    "space-between",
    "space-around",
    "space-evenly",
  ]),
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "error",
  ]),
};

OutlinedChip.defaultProps = {
  align: "center",
  color: "primary",
};

export default OutlinedChip;

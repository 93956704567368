import React from "react";
import PropTypes from "prop-types";

import { Typography } from "@mui/material";

const ProductsCountText = ({ align, productsCount, sx }) => (
  <Typography align={align} color="primary" variant="caption" sx={sx}>
    แสดงรถทั้งหมด {productsCount} รายการ
  </Typography>
);

ProductsCountText.propTypes = {
  align: PropTypes.oneOf(["center", "inherit", "justify", "left", "right"]),
};

ProductsCountText.defaultProps = {
  align: "center",
};

export default ProductsCountText;

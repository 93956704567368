import { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import {
  addDays,
  differenceInDays,
  differenceInCalendarDays,
  parseISO,
  isBefore,
} from "date-fns";

import {
  Box as MuiBox,
  Grid,
  Divider,
  Typography,
  Link,
  Skeleton,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { useHttp, useDate } from "hooks";
import { endpoints, IMPLEMENT, FILTER } from "utils/Config";
import { MIN_PRE_RESERVED_DAYS, MIN_RENTING_DAYS } from "constants/DateTime";
import MoreInformationForm from "pages/Templates/MoreInformationForm";
import { DatePickerInput, SelectDropdownInput } from "components/HookForms";
import { ErrorMessage, Modal, OutlinedChip } from "base-components";
import { Spinner, ProductsCountText, MachineList } from "components";

const Box = styled(MuiBox)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(2, 1, 0),
  "& .MuiCardContent": {
    "&-root": {
      padding: theme.spacing(1),
    },
  },
  "& .MuiPickersDateRangePickerInput-root": {
    [theme.breakpoints.up("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
    },
    "& .MuiFormControl-root": {
      minWidth: "100%",
      paddingBottom: theme.spacing(1),
    },
  },
  "& .MuiInputBase-root": {
    "& .MuiInputBase-input": {
      color: theme.palette.secondary.main,
    },
    "& .MuiSelect-icon": {
      color: theme.palette.secondary.main,
    },
  },
}));

const SkeletonLoading = styled(Skeleton, {
  shouldForwardProp: (prop) => prop !== "height" && prop !== "sx",
})(({ theme, height }) => ({
  borderRadius: theme.spacing(2),
  height: theme.spacing(height),
  width: "100%",
}));

const MachineFilterPage = (props) => {
  const [openInformModal, setOpenInformModal] = useState(false);
  const [provinces, setProvinces] = useState([]);
  const [productChoices, setProductChoices] = useState([]);
  const [seriesChoices, setSeriesChoices] = useState([]);
  const [modelChoices, setModelChoices] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [formState, setFormState] = useState({
    completed: true,
    errors: {
      start_date: "",
      end_date: "",
    },
  });

  const history = useHistory();
  const { control, getValues, setValue, watch } = useForm();
  const [startDate, endDate, productName] = useWatch({
    control,
    name: ["start_date", "end_date", "product_name"],
  });
  const [provinceName, seriesName, modelName] = getValues([
    "province_name",
    "series_name",
    "model_name",
  ]);

  const { isLoading: pageLoading, sendRequest: fetchHttpData } = useHttp();
  const { isLoading: isFiltering, sendRequest: fetchFilteredData } = useHttp();
  const {
    disabledStart,
    disabledEnd,
    pickedDays,
    defaultStartMonth,
    defaultEndMonth,
  } = useDate({
    startDate,
    endDate,
  });

  useEffect(() => {
    const abortCont = new AbortController();
    const { signal } = abortCont;

    const {
      product_name: localProdName,
      province_name: localProvName,
      start_date: localStartDate,
      end_date: localEndDate,
    } = JSON.parse(localStorage.getItem(FILTER));

    const setProductQueryForm = (productDetail) => {
      const { product: products, province: provinces } = productDetail;

      setProductChoices(products);
      setProvinces(provinces);
      /* Set others state for using in form and list */
      setValue("province_name", localProvName);
      setValue("start_date", parseISO(localStartDate));
      setValue("end_date", parseISO(localEndDate));
      setValue("product_name", localProdName);
      setValue("series_name", "all");
      setValue("model_name", "all");
    };

    const setFilteredResult = (filteredData) => {
      const { product_name: productName, vehicle: vehicles } = filteredData;

      const vehiclesWithName = vehicles.map((vehicle) => ({
        ...vehicle,
        name: productName,
      }));
      setFilteredProducts(vehiclesWithName);
    };

    const fetchData = async (signal = {}) => {
      /* fetch Product types and provinces for pre filled in form */
      fetchHttpData(
        {
          endpoint: endpoints.filter,
          signal,
        },
        setProductQueryForm
      );

      /* fetch for query vehicles with params */
      const params = {
        province_name: localProvName,
        product_name: localProdName,
        diffDays: differenceInCalendarDays(
          parseISO(localEndDate),
          parseISO(localStartDate)
        ),
      };
      fetchFilteredData(
        {
          endpoint: endpoints.queryVehicles(params),
          signal,
        },
        setFilteredResult
      );
    };

    fetchData(signal);

    return () => abortCont.abort();
  }, [fetchHttpData, fetchFilteredData, setValue]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      const filteredProduct = productChoices.find(
        ({ product }) => product === value.product_name
      );

      /* Change choices in series and model when change product dropdown */
      if (value.product_name && filteredProduct?.series?.length) {
        setSeriesChoices(filteredProduct.series);
        const filteredSeries = filteredProduct.series.find(
          (series) => series.size === value.series_name
        );
        filteredSeries?.model?.length
          ? setModelChoices(filteredSeries.model)
          : setModelChoices([]);
      } else {
        setSeriesChoices([]);
        setModelChoices([]);
      }

      if (type === "change") {
        /* Select new product type will clear series and model */
        if (
          name === "product_name" &&
          (value.series_name !== "all" || value.model_name !== "all")
        ) {
          setValue("series_name", "all");
          setValue("model_name", "all");
        }

        /* Select new series name will clear model */
        if (name === "series_name" && value.model_name !== "all")
          setValue("model_name", "all");

        let startDate = null;
        if (
          isBefore(value.start_date, addDays(new Date(), MIN_PRE_RESERVED_DAYS))
        )
          startDate = {
            type: "test-not-passed-day",
            message: `กรุณาเลือกวันเริ่มต้นล่วงหน้าก่อน ${MIN_PRE_RESERVED_DAYS} วัน`,
          };

        let endDate = null;
        if (!value.end_date)
          endDate = {
            type: "required",
            message:
              "กรุณาเลือก วันที่คืน ใหม่อีกครั้ง ระบบจึงจะสามารถค้นหาสินค้าให้ท่านได้",
          };
        if (
          value.end_date &&
          differenceInDays(value.end_date, value.start_date) < MIN_RENTING_DAYS
        )
          endDate = {
            type: "test-min-renting-days",
            message: `จำนวนวันเช่าสินค้าขั้นต่ำ ${MIN_RENTING_DAYS} วันขึ้นไป`,
          };
        /* Select new start_date will remove end_date value */
        if (name === "start_date" || name === "end_date") {
          name === "start_date" && setValue("end_date", "");

          setFormState((prevState) => ({
            ...prevState,
            errors: {
              start_date: startDate,
              end_date: endDate,
            },
            completed: !startDate && !endDate,
          }));
        }

        /* Filter products when change some inputs in page */
        if (
          value.province_name &&
          value.product_name &&
          value.series_name &&
          value.model_name &&
          value.start_date &&
          value.end_date &&
          name !== "start_date" &&
          !startDate &&
          !endDate
        )
          fetchFilteredData(
            {
              endpoint: endpoints.queryVehicles({
                province_name: value.province_name,
                product_name: value.product_name,
                series_name: value.series_name,
                model_name: value.model_name,
                diffDays: differenceInCalendarDays(
                  value.end_date,
                  value.start_date
                ),
              }),
            },
            (productData) => {
              const { product_name: productName, vehicle: vehicles } =
                productData;
              const filteredData = {
                province_name: value.province_name,
                start_date: value.start_date,
                end_date: value.end_date,
                product_name: value.product_name,
                series_name: value.series_name,
                model_name: value.model_name,
              };
              localStorage.setItem(FILTER, JSON.stringify(filteredData));
              setFormState((prevState) => ({ ...prevState, completed: true }));
              setFilteredProducts(() => {
                if (vehicles.length > 0)
                  return vehicles.map((vehicle) => ({
                    ...vehicle,
                    name: productName,
                  }));
                return [];
              });
            }
          );
        else setFilteredProducts([]);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue, formState, productChoices, fetchFilteredData]);

  const clickSeeMoreHandler = async (productId, productPrice) => {
    if (!productId || !productPrice) return;
    /* Set price in local storage */
    const filterData = JSON.parse(localStorage.getItem(FILTER));
    const newFilterData = { ...filterData, detailPrice: productPrice };
    localStorage.setItem(FILTER, JSON.stringify(newFilterData));

    /* Go next page */
    history.push(`/machine-selection/${productId}`);
  };

  const clickOpenAskInformationModal = (e) => {
    e.preventDefault();
    setOpenInformModal(true);
  };

  const closeInformModal = () => {
    setOpenInformModal(false);
  };

  /* =================================== */
  /* Label of series */
  let sizeLabel = "ขนาด";
  if (productName === IMPLEMENT.th) {
    sizeLabel = "ประเภท";
  }
  /* First Menu Item (Filter Products) */
  let firstSizeMenu;
  if (productName === IMPLEMENT.th) {
    firstSizeMenu = "เลือก ประเภท";
  }
  if (productName !== IMPLEMENT.th) {
    firstSizeMenu = "เลือก ขนาด";
  }

  /* Conditions render JSX */
  let productLists;
  // Fetching data
  if (isFiltering) productLists = <Spinner />;
  // Finished fetch, have data, and rendering product lists
  if (!isFiltering && filteredProducts.length > 0 && formState.completed)
    productLists = (
      <Fragment>
        <Grid container item xs={12} justifyContent="center" mb={1}>
          <ProductsCountText productsCount={filteredProducts.length} />
        </Grid>
        {filteredProducts.map((product, idx) => (
          <Grid key={idx} item xs={12} pb={2}>
            <MachineList
              moreDetail
              excludeShipping
              vin={product.VIN}
              name={product.name}
              model={product.model}
              price={product.price}
              mainImgUrl={product.mainimage}
              onClickLink={clickSeeMoreHandler}
              onClickList={clickSeeMoreHandler}
            />
          </Grid>
        ))}
      </Fragment>
    );

  // Finished fetch, Don't have data
  if (!isFiltering && !filteredProducts.length && formState.completed)
    productLists = (
      <Fragment>
        <Grid item xs={12} mt={1} mb={3}>
          <Typography textAlign="center" variant="body1">
            ระบบกำลังค้นหาสินค้าที่ท่านต้องการ
          </Typography>
        </Grid>

        <Typography variant="subtitle2">
          หลังจากพบสินค้าแล้วเจ้าหน้าที่จะดำเนินการติดต่อกลับ
        </Typography>
        <Link
          href="#"
          onClick={clickOpenAskInformationModal}
          sx={{ mb: 2 }}
        >{`กรอกข้อมูลเพื่อให้เจ้าหน้าที่ติดต่อกลับ >>`}</Link>
        <Grid item xs={12}>
          <Divider sx={{ bgcolor: (theme) => theme.palette.common.black }} />
        </Grid>
      </Fragment>
    );

  // Not match criteria of start date and end date
  if (
    !isFiltering &&
    !filteredProducts.length &&
    !formState.completed &&
    formState.errors.start_date?.type === "test-not-passed-day"
  )
    productLists = (
      <ErrorMessage
        title="ข้อมูลไม่ถูกต้อง"
        detail={formState.errors.start_date.message}
      />
    );

  if (
    !isFiltering &&
    !filteredProducts.length &&
    !formState.completed &&
    formState.errors.end_date?.type === "test-min-renting-days"
  )
    productLists = (
      <ErrorMessage
        title="ข้อมูลไม่ถูกต้อง"
        detail={formState.errors.end_date.message}
      />
    );

  // Required start and end date
  if (
    !isFiltering &&
    !filteredProducts.length &&
    !formState.completed &&
    !formState.errors.start_date &&
    formState.errors.end_date?.type === "required"
  )
    productLists = (
      <ErrorMessage
        title="ข้อมูลไม่ครบถ้วน"
        detail={formState.errors.end_date.message}
      />
    );

  /* =================================== */

  return (
    <Fragment>
      <Box>
        <Grid container>
          {/* ***Provinces*** Select Input */}
          {pageLoading ? (
            <SkeletonLoading
              animation="wave"
              variant="rectangular"
              height={5}
              sx={{ mb: 1 }}
            />
          ) : (
            <Fragment>
              <Grid item xs={5} pr={3} pb={1}>
                <OutlinedChip label="สถานที่ใช้งาน" sx={{ minWidth: "100%" }} />
              </Grid>
              <Grid item xs={7}>
                <SelectDropdownInput
                  control={control}
                  defaultValue=""
                  label="จังหวัด"
                  name="province_name"
                  subheaderName="เลือก จังหวัด"
                  menuItems={provinces}
                  // size="small"
                  width={{ label: "30%", input: "70%" }}
                />
              </Grid>
            </Fragment>
          )}

          {pageLoading ? (
            <SkeletonLoading
              animation="wave"
              variant="rectangular"
              height={5}
              sx={{ mb: 1 }}
            />
          ) : (
            <Fragment>
              <Grid item xs={5} pr={3}>
                <OutlinedChip label="วันที่ใช้งาน" sx={{ minWidth: "100%" }} />
              </Grid>
              <Grid container item direction="column" xs={7} mb={1}>
                <Grid item mb={1}>
                  <DatePickerInput
                    name="start_date"
                    control={control}
                    defaultValue=""
                    label="วันที่เริ่มต้น"
                    toolbarTitle="เลือก วันที่เริ่มต้นใช้งาน"
                    shouldDisableDate={disabledStart}
                    defaultMonth={defaultStartMonth()}
                    renderDay={pickedDays}
                    width={{ label: "30%", input: "70%" }}
                  />
                </Grid>
                <Grid item>
                  <DatePickerInput
                    name="end_date"
                    control={control}
                    defaultValue=""
                    label="วันที่คืน"
                    toolbarTitle="เลือก วันที่คืนเครื่องจักร"
                    shouldDisableDate={disabledEnd}
                    defaultMonth={defaultEndMonth(MIN_RENTING_DAYS)}
                    renderDay={pickedDays}
                    width={{ label: "30%", input: "70%" }}
                  />
                </Grid>
              </Grid>
            </Fragment>
          )}

          {pageLoading ? (
            <SkeletonLoading
              animation="wave"
              variant="rectangular"
              height={19}
              sx={{ mb: 2 }}
            />
          ) : (
            <Fragment>
              <Grid item xs={5} pr={3}>
                <OutlinedChip
                  label="สินค้าที่ให้บริการ"
                  sx={{ minWidth: "100%" }}
                />
              </Grid>
              <Grid container item direction="column" xs={7} mb={4}>
                <SelectDropdownInput
                  control={control}
                  defaultValue=""
                  label="สินค้า"
                  name="product_name"
                  subheaderName="เลือก สินค้า"
                  valueProp="product"
                  menuProp="product"
                  menuItems={productChoices}
                  inline={false}
                  sx={{ mb: 1 }}
                />
                <SelectDropdownInput
                  control={control}
                  defaultValue=""
                  label={sizeLabel}
                  name="series_name"
                  firstMenuItem={{ label: "ทั้งหมด", value: "all" }}
                  subheaderName={firstSizeMenu}
                  valueProp="size"
                  menuProp="size"
                  menuItems={seriesChoices}
                  inline={false}
                  sx={{ mb: 1 }}
                />
                <SelectDropdownInput
                  control={control}
                  defaultValue=""
                  label="รุ่น"
                  name="model_name"
                  firstMenuItem={{ label: "ทั้งหมด", value: "all" }}
                  subheaderName="เลือก รุ่น"
                  menuItems={modelChoices}
                  inline={false}
                />
              </Grid>
            </Fragment>
          )}

          {/* <Grid container justifyContent="flex-end" item xs={12}>
          <Typography variant="caption">เรียงตาม</Typography>
          <ArrowDropDownIcon fontSize="small" />
        </Grid> */}
          {productLists}
        </Grid>
      </Box>

      <Modal
        fullWidth
        maxWidth="xs"
        titleWithClose
        openModal={openInformModal}
        onCloseModal={closeInformModal}
      >
        <MoreInformationForm
          productFilter={{
            province: provinceName,
            startdate: startDate,
            enddate: endDate,
            productType: productName,
            size: seriesName,
            model: modelName,
          }}
          page="filter"
          description="not found"
          callbackFunc={closeInformModal}
        />
      </Modal>
    </Fragment>
  );
};

export default MachineFilterPage;

import React from "react";

import { Grid, Card, CardContent, Paper, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Add as AddIcon, Cancel as CancelIcon } from "@mui/icons-material";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "&:not(:last-child)": {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
          marginRight: theme.spacing(6),
        },
        [theme.breakpoints.up("md")]: {
          marginRight: theme.spacing(12),
        },
      },
      "& .text-implement": {
        textAlign: "center",
        fontSize: ".75rem",
        [theme.breakpoints.up("sm")]: {
          fontSize: "1rem",
        },
        marginBottom: theme.spacing(1.5),
      },
      "& .card-implement": {
        display: "flex",
        alignItems: "center",
        height: "50%",
        "&.MuiCardContent-root": {
          height: "100%",
          borderRadius: "10px",
          padding: theme.spacing(1.5),
        },
      },
    },
    "card__add-icon": {
      alignSelf: "center",
      margin: theme.spacing(0, "auto"),
      color: theme.palette.secondary.main,
      fontSize: "2.5rem",
      [theme.breakpoints.up("nxs")]: {
        fontSize: "3rem",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: "4rem",
      },
    },
    "selected-implements": {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      alignItems: "center",
      // rowGap: theme.spacing(1),
      paddingTop: theme.spacing(1.5),
      "& .selected-implements": {
        "&__close": {
          position: "absolute",
          fontSize: "1.15rem",
          top: theme.spacing(-1.25),
          right: theme.spacing(-1.25),
        },
        "&__img": {
          width: "100%",
          objectFit: "contain",
          objectPosition: "center",
          borderRadius: theme.shape.borderRadius,
        },
        "&__model": {
          marginBottom: theme.spacing(0.5),
        },
        "&__price": {
          display: "flex",
          "&-value": {
            padding: theme.spacing(0, 0.5),
            backgroundColor: theme.palette.common.white,
          },
          "&-unit": {
            marginLeft: theme.spacing(0.5),
          },
        },
      },
    },
  };
});

const SelectImplementCard = ({
  id,
  model,
  price,
  mainImgUrl,
  adding,
  implementCount,
  onClick,
  ...commonProps
}) => {
  const classes = useStyles();

  const handleAddItem = () => {
    onClick();
  };

  const handleDeleteItem = (id) => () => {
    onClick(id);
  };

  let content = (
    <AddIcon onClick={handleAddItem} className={classes["card__add-icon"]} />
  );
  if (!adding) {
    content = (
      <div className={classes["selected-implements"]}>
        <CancelIcon
          className="selected-implements__close"
          onClick={handleDeleteItem(id)}
          color="error"
        />
        <img
          className="mb-1 selected-implements__img"
          src={mainImgUrl}
          alt={`${implementCount}_select_implements_img`}
        />
        <Typography
          className="selected-implements__model"
          component="span"
          variant="caption"
        >
          {model.length > 6 ? model.substring(0, 7) : model}
        </Typography>
        <div className="selected-implements__price">
          <Paper elevation={0} className="selected-implements__price-value">
            <Typography component="span" variant="caption" color="secondary">
              {price.toLocaleString()}
            </Typography>
          </Paper>
          <Typography
            component="span"
            variant="caption"
            className="selected-implements__price-unit"
          >
            บาท
          </Typography>
        </div>
      </div>
    );
  }

  return (
    <Grid
      className={`${classes.root} ${commonProps.className ?? ""}`}
      item
      xs={4}
    >
      <Typography className="text-implement">{`อุปกรณ์ต่อพ่วง ${
        adding ? implementCount + 1 : implementCount
      }`}</Typography>
      <Card>
        <CardContent className="card-implement">{content}</CardContent>
      </Card>
    </Grid>
  );
};

export default SelectImplementCard;

import React, { forwardRef } from "react";
import PropTypes from "prop-types";

import { FormControl, InputLabel, FilledInput } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      columnGap: 5,
      "& .MuiFormLabel": {
        "&-root": {
          fontSize: "small",
        },
      },
      "& .MuiInputLabel": {
        "&-formControl": {
          display: "flex",
          justifyContent: "flex-start",
          alignSelf: "flex-start",
          position: "static",
          transform: "translate(0,0)",
          paddingTop: theme.spacing(1.5),
          paddingLeft: theme.spacing(2),
          flexBasis: "50%",
          [theme.breakpoints.up("sm")]: {
            flexBasis: "25%",
          },
        },
      },
      "& .MuiInputBase": {
        "&-root": {
          flexGrow: 1,
          width: "100%",
          marginLeft: "auto",
          marginTop: 0,
          flexBasis: "50%",
          [theme.breakpoints.up("sm")]: {
            flexBasis: "75%",
          },
          "&.MuiFilledInput": {
            "&-root": {
              "& input.MuiFilledInput-input": {
                padding: theme.spacing(1.25, 1.5),
              },
              "&.MuiFilledInput-multiline": {
                padding: theme.spacing(1.25, 1.5),
              },
            },
          },
        },
        "&-input": {
          fontSize: "small",
        },
      },
    },
  };
});

const InlineInput = forwardRef(
  ({ multiline, rows, onChange, ...props }, ref) => {
    const classes = useStyles();

    return (
      <FormControl
        className={`${classes.root} ${props.className ?? ""}`}
        sx={props.sx}
      >
        <InputLabel htmlFor={`${props.name}-inline-input`}>
          {props.label}
        </InputLabel>
        <FilledInput
          inputRef={ref}
          id={`${props.name}-inline-input`}
          multiline={multiline}
          type={props.type}
          rows={rows}
          value={props.value}
          onChange={onChange}
        />
      </FormControl>
    );
  }
);

InlineInput.propTypes = {
  label: PropTypes.node.isRequired,
  variant: PropTypes.string,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  type: PropTypes.string.isRequired,
  name: PropTypes.string,
};

InlineInput.defaultProps = {
  type: "text",
  variant: "standard",
  multiline: false,
};

export default InlineInput;

import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Fab,
} from "@mui/material";

import { CONFIRMED, endpoints, STAGES } from "utils/Config";
import { useHttp } from "hooks";
import TermsAndConditions from "pages/Templates/TermsAndConditions";
import { OutlinedChip, ScrollToTop, Footer } from "base-components";

const RentingConditionPage = (props) => {
  const history = useHistory();

  const lastListRef = useRef(null);
  const [termsAndConditions, setTermsAndConditions] = useState({});
  const [disabledCheckbox, setDisabledCheckbox] = useState(true);
  const [checkAlreadyRead, setCheckAlreadyRead] = useState(false);

  const { sendRequest: fetchHttpTerms } = useHttp();

  useEffect(() => {
    const abortCont = new AbortController();
    const { signal } = abortCont;

    const { bookNo } = JSON.parse(sessionStorage.getItem(CONFIRMED));

    const fetchData = (signal) => {
      fetchHttpTerms(
        { endpoint: endpoints.termsAndConditions(bookNo), signal },
        (termsResponse) => {
          setTermsAndConditions(termsResponse);
        }
      );
    };

    fetchData(signal);

    window.addEventListener("storage", fetchData);

    return () => {
      window.removeEventListener("storage", fetchData);
      abortCont.abort();
    };
  }, [fetchHttpTerms]);

  const confirmRentingConditionHandler = () => {
    const { bookNo } = JSON.parse(sessionStorage.getItem(CONFIRMED));
    history.push(`/renting/summary/${bookNo}`, {
      bookingState: STAGES.draft.nameEN,
    });
  };

  useEffect(() => {
    const contentObserver = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) setDisabledCheckbox(false);
        else setDisabledCheckbox(true);
      },
      {
        root: null,
        threshold: 0,
        rootMargin: "-120px",
      }
    );

    const toggleConditionAction = () => {
      contentObserver.observe(lastListRef.current);
    };

    window.addEventListener("scroll", toggleConditionAction);

    return () => {
      contentObserver.disconnect();
      window.removeEventListener("scroll", toggleConditionAction);
    };
  }, []);

  return (
    <Grid container p={2} sx={{ flexGrow: 1 }}>
      <ScrollToTop />
      <Grid item xs={12} mb={3}>
        <OutlinedChip
          color="primary"
          label="สรุปเงื่อนไขและข้อตกลง"
          sx={{ width: "50%" }}
        />
      </Grid>
      <Grid item xs={12} mb={13}>
        <TermsAndConditions
          ref={lastListRef}
          chargePerHour={termsAndConditions.chargePerHour}
        />
      </Grid>

      <Footer>
        <Grid
          container
          item
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} px={0.5} mb={1}>
            <FormControlLabel
              label={
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: (theme) =>
                      disabledCheckbox
                        ? theme.palette.grey[500]
                        : theme.palette.common.black,
                  }}
                >
                  ท่านได้อ่านรายละเอียดครบถ้วนเรียบร้อยแล้ว
                </Typography>
              }
              control={
                <Checkbox
                  disabled={disabledCheckbox}
                  checked={checkAlreadyRead}
                  onChange={(e) => {
                    setCheckAlreadyRead(e.target.checked);
                  }}
                  name="checkSameRenterData"
                  color="primary"
                />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Fab
              disabled={!checkAlreadyRead || disabledCheckbox}
              color="secondary"
              onClick={confirmRentingConditionHandler}
              variant="extended"
              sx={(theme) => ({
                width: theme.spacing(14),
                height: theme.spacing(4),
                margin: theme.spacing(0, "auto", 2),
                "&.Mui-disabled": {
                  color: "rgba(0,0,0,0.26)",
                  backgroundColor: "rgba(0,0,0,0.12)",
                },
              })}
            >
              ยืนยัน
            </Fab>
          </Grid>
        </Grid>
      </Footer>
    </Grid>
  );
};

export default RentingConditionPage;

import PropTypes from "prop-types";
import MuiFab from "@mui/material/Fab";
import { styled } from "@mui/material/styles";

const OutlinedFab = styled(MuiFab, {
  shouldForwardProp: (prop) => prop !== "color",
})(({ theme, color }) => ({
  backgroundColor: "#fff",
  color: theme.palette[color].main,
  border: `2px solid ${theme.palette[color].main}`,
  height: theme.spacing(4),
  boxShadow: "none",
  "@media (hover:none)": {
    "&.MuiFab-primary:hover": {
      color: "#fff",
    },
    "&.MuiFab-secondary:hover": {
      color: "#fff",
    },
  },
  "&[disabled]": {
    border: "none",
  },
}));

OutlinedFab.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "success",
    "info",
    "warning",
    "error",
  ]),
};

OutlinedFab.defaultProps = {
  color: "primary",
};

export default OutlinedFab;

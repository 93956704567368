import { Fragment } from "react";

const Compose = ({ components = [], children }) => {
  return (
    <Fragment>
      {components.reduceRight((acc, Comp) => {
        return <Comp>{acc}</Comp>;
      }, children)}
    </Fragment>
  );
};

export default Compose;

import { Fragment, useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import { io } from "socket.io-client";
import { parse, parseISO, format, isToday, addDays } from "date-fns";
import Swal from "sweetalert2";

import {
  Grid,
  Paper,
  Typography,
  Link,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
} from "@mui/material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import DownloadIcon from "@mui/icons-material/Download";

import Input from "components/HookForms/Input";
import {
  Modal,
  OutlinedChip,
  OutlinedFab,
  Alert,
  ScrollToTop,
} from "base-components";
import { DateTimeDescriptionCard, UploadImage } from "components";

import AuthContext from "stores/Authentication/auth-context";
import { isUrl } from "helpers/Utilities";
import { useHttp } from "hooks";
import {
  endpoints,
  PAYMENT_API_BASE_URL,
  paymentEndpoints,
  STAGES,
  CONFIRMED,
} from "utils/Config";
import { DATE_FORMAT, GAPDAYS_TO_PAY } from "constants/DateTime";
import PaymentStepContent from "./components/PaymentStepContent";

const { liff } = window;

const ImgProduct = styled("img")({
  objectFit: "contain",
  width: "100%",
});

const ImgQR = styled("img")({
  width: "70%",
});

const ReceiptContent = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.grey[300],
  display: "flex",
  flexGrow: "1",
  width: "100%",
  padding: theme.spacing(1, 1.5),
}));

const CustomizedConnector = styled(StepConnector)(({ theme }) => ({
  marginLeft: "11px",
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      width: "2px",
      backgroundColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      width: "2px",
      backgroundColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    width: 2,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.grey[800]
        : theme.palette.grey[300],
    borderRadius: 1,
  },
}));

const useStyles = makeStyles((theme) => {
  const defaultButton = {
    fontFamily: "Prompt",
    fontSize: "0.875rem",
    fontWeigth: 400,
    lineHeight: 1.43,
    borderRadius: "1rem",
    padding: theme.spacing(1, 2),
  };
  return {
    swal2Container: {
      zIndex: theme.zIndex.appBar + 1,
    },
    swal2OutlinedConfirmButton: {
      "&.swal2-confirm": {
        color: theme.palette.common.black,
        backgroundColor: "transparent",
        border: `1px solid ${theme.palette.grey[500]}`,
        ...defaultButton,
      },
    },
    swal2CancelButton: {
      "&.swal2-cancel": {
        backgroundColor: theme.palette.grey[500],
        marginRight: theme.spacing(2),
        ...defaultButton,
      },
    },
  };
});

const RentingDetailPage = (props) => {
  const history = useHistory();
  const { bookingId } = useParams();
  const { control, setValue } = useForm();
  const note = useWatch({ control, name: "note" });
  const { setUser } = useContext(AuthContext);
  const { sendRequest: fetchHttpData } = useHttp();
  const { sendRequest: fetchDocument } = useHttp();
  const { sendRequest: submitVerifySlip } = useHttp();
  const { sendRequest: cancelBooking } = useHttp();

  const [rentingDetail, setRentingDetail] = useState({
    book_time: "1900-01-01 01:00:0000",
  });
  const [payRound, setPayRound] = useState("01");
  const [documents, setDocuments] = useState({
    QR30: "",
    contractDetail: "",
    invoice: "",
    reciept: "",
  });
  const [activeStep, setActiveStep] = useState(0);
  const [selectedFile, setSelectedFile] = useState({
    data: "",
    name: "",
  });
  const [isOpen, setIsOpen] = useState({
    qrModal: false,
    informTransferModal: false,
  });
  const [showVerifyStatus, setShowVerifyStatus] = useState({
    isOpen: false,
    message: "",
  });

  const classes = useStyles();

  useEffect(() => {
    const abortCont = new AbortController();
    const { signal } = abortCont;

    const checkPayRound = (stage = STAGES.book.nameEN, installment = 1) => {
      if (
        +installment === 2 &&
        (stage === STAGES.paid1.nameEN || stage === STAGES.active.nameEN)
      ) {
        setPayRound("02");
        return "02";
      }
      return "01";
    };

    const setBookingData = (booking) => {
      const { amount, totalAmount } = booking;
      const scopedPayRound = checkPayRound(booking.stage, booking.installments);
      setRentingDetail(() => {
        const totalPrice = booking?.promoDiscount?.promotions?.length
          ? totalAmount
          : amount;
        return {
          totalPrice,
          ...(booking.installments === 2 && {
            halfPrice: +(totalPrice / 2).toFixed(2),
          }),
          ...booking,
        };
      });
      fetchHttpData(
        {
          url: `${PAYMENT_API_BASE_URL}${paymentEndpoints.getQR(
            bookingId,
            scopedPayRound
          )}`,
        },
        ({ blob_url }) => {
          setDocuments((prevDocs) => ({
            ...prevDocs,
            QR30: blob_url,
          }));
        }
      );
    };

    const fetchData = (signal) => {
      fetchHttpData(
        { endpoint: endpoints.booking(bookingId), signal },
        setBookingData
      );
    };

    fetchData(signal);

    return () => {
      abortCont.abort();
    };
  }, [fetchHttpData, bookingId]);

  useEffect(() => {
    const socket = io(PAYMENT_API_BASE_URL);

    const recieveData = (data) => {
      if (data.action === "notify") {
        setIsOpen((prevOpen) => ({
          ...prevOpen,
          qrModal: false,
        }));
        if (rentingDetail.installments === 1)
          setRentingDetail((prevDetail) => ({
            ...prevDetail,
            stage: STAGES.paid1.nameEN,
          }));

        if (rentingDetail.installments === 2) {
          setRentingDetail((prevDetail) => ({
            ...prevDetail,
            stage:
              payRound === "01" ? STAGES.paid1.nameEN : STAGES.paid2.nameEN,
          }));
        }
      }
    };

    socket.on(`payment/${bookingId}`, recieveData);

    return () => {
      socket.off(`payment/${bookingId}`, recieveData);
    };
  }, [bookingId, rentingDetail.installments, payRound]);

  useEffect(() => {
    let mounted = true;

    const setBookingStage = (stage = STAGES.book.nameEN, installment = 1) => {
      if (stage === STAGES.book.nameEN) setActiveStep(0);
      if (stage === STAGES.paid1.nameEN) setActiveStep(1);
      if (stage === STAGES.active.nameEN) setActiveStep(2);
      if (installment === 1) {
        if (stage === STAGES.completed.nameEN) setActiveStep(3);
      }
      if (installment === 2) {
        if (stage === STAGES.paid2.nameEN) setActiveStep(3);
        if (stage === STAGES.completed.nameEN) setActiveStep(4);
      }
    };

    if (mounted) {
      setBookingStage(rentingDetail.stage, rentingDetail.installments);
    }

    return () => {
      mounted = false;
    };
  }, [rentingDetail.installments, rentingDetail.stage]);

  const clickDownloadContract = () => {
    if (documents.contractDetail)
      liff.openWindow({
        url: documents.contractDetail,
        external: true,
      });
    else
      fetchDocument(
        { endpoint: endpoints.contractDetail(bookingId) },
        ({ url }) => {
          setDocuments((prevDocs) => ({
            ...prevDocs,
            contractDetail: url,
          }));
          liff.openWindow({
            url,
            external: true,
          });
        }
      );
  };

  const clickDownloadInvoice = () => {
    if (documents.invoice)
      liff.openWindow({
        url: documents.invoice,
        external: true,
      });
    else
      fetchDocument(
        {
          url: `${PAYMENT_API_BASE_URL}${paymentEndpoints.getInvoice(
            bookingId
          )}`,
        },
        ({ blob_url }) => {
          setDocuments((prevDocs) => ({
            ...prevDocs,
            invoice: blob_url,
          }));
          liff.openWindow({
            url: blob_url,
            external: true,
          });
        }
      );
  };

  const clickDownloadReciept = (payRound) => () => {
    if (documents.reciept)
      liff.openWindow({
        url: documents.reciept,
        external: true,
      });
    fetchDocument(
      {
        url: `${PAYMENT_API_BASE_URL}${paymentEndpoints.getReceipt(
          bookingId,
          payRound
        )}`,
      },
      ({ blob_url }) => {
        console.log(blob_url);
        setDocuments((prevDocs) => ({
          ...prevDocs,
          reciept: blob_url,
        }));
        liff.openWindow({
          url: blob_url,
          external: true,
        });
      }
    );
  };

  const clickOpenModal = (state) => () => {
    setIsOpen((prevIsOpen) => {
      if (state === "informTransferModal") {
        fetchDocument(
          {
            url: `${PAYMENT_API_BASE_URL}${paymentEndpoints.verfifySlip(
              bookingId,
              payRound
            )}`,
          },
          (slip) => {
            setValue("note", slip.verifyNote || "");
            console.log(slip.verfifySlip);
            const { pathname = "" } = new URL(slip.verifySlip);
            const paths = pathname.split("/");
            const slipFileName = paths.find((name) => name.includes("KINRENT"));
            setSelectedFile(() => ({
              data: slip.verifySlip,
              name: slipFileName,
            }));
          }
        );
      }
      return { ...prevIsOpen, [state]: true };
    });
  };

  const clickCloseModal = (state) => () => {
    setIsOpen((prevIsOpen) => {
      if (state === "informTransferModal") {
        setSelectedFile(() => ({ data: "", name: "" }));
        setValue("note", "");
      }
      return { ...prevIsOpen, [state]: false };
    });
  };

  const clickDownloadQR = () => {
    liff.openWindow({
      url: documents.QR30,
      external: true,
    });
    clickCloseModal("qrModal")();
  };

  const clickUploadSlip = ({ data, name }) => {
    setSelectedFile(() => ({ data, name }));
  };

  const clickDeleteSlip = () => {
    setSelectedFile(() => ({ data: "", name: "" }));
  };

  const clickVerifySlip = (payRound) => () => {
    let slipImage;
    if (isUrl(selectedFile.data)) slipImage = selectedFile.data;
    else {
      const [, base64] = selectedFile.data.split(",");
      slipImage = base64;
    }
    submitVerifySlip(
      {
        url: `${PAYMENT_API_BASE_URL}${paymentEndpoints.verfifySlip(
          bookingId,
          payRound
        )}`,
        method: "POST",
        body: { qr_image: slipImage, note },
      },
      (data) => {
        // waiting AD to verify slip
        if (data === "waiting verify") {
          setShowVerifyStatus(() => ({
            isOpen: true,
            message:
              "ได้รับหลักฐานการชำระเงินของท่านแล้ว เจ้าหน้าที่จะทำการตรวจสอบและอัพเดตสถานะให้โดยเร็วที่สุด",
          }));
          clickCloseModal("informTransferModal")();
        }
      }
    );
  };

  const closeAlert = () => {
    setShowVerifyStatus((prevStatus) => ({ ...prevStatus, isOpen: false }));
  };

  const cancelReservationHandler = async () => {
    const response = await Swal.fire({
      titleText: "ยกเลิกการจอง",
      text: "ท่านยืนยันที่จะยกเลิกการจองสินค้าที่เลือกมาทั้งหมดหรือไม่",
      icon: "question",
      cancelButtonText: "กลับหน้าเดิม",
      confirmButtonText: "ยืนยัน",
      width: "75%",
      showCloseButton: true,
      showCancelButton: true,
      focusCancel: true,
      reverseButtons: true,
      customClass: {
        container: classes.swal2Container,
        confirmButton: classes.swal2OutlinedConfirmButton,
        cancelButton: classes.swal2CancelButton,
      },
    });
    if (response.isConfirmed)
      cancelBooking({ endpoint: endpoints.cancelBooking(bookingId) }, () => {
        sessionStorage.removeItem(CONFIRMED);
        setUser((prevUserData) => ({ ...prevUserData, alreadyBook: "" }));
        history.push("/");
      });
  };

  const calcDueDate = (datetime, existedFormat) => {
    if (!datetime || !existedFormat) return;
    const dateObj = parse(datetime, existedFormat, new Date());
    const payingDueDate = addDays(dateObj, GAPDAYS_TO_PAY);
    return format(payingDueDate, DATE_FORMAT);
  };

  const checkIsToday = (date) => {
    if (!date) return;
    const dateObj = parse(date, DATE_FORMAT, new Date());
    return isToday(dateObj);
  };

  const calcHalfPayDueDate = (startDate) => {
    if (!startDate) return;
    const startDateObj = parse(startDate, DATE_FORMAT, new Date());
    const halfPayDueDate = addDays(startDateObj, rentingDetail.rentDays / 2);
    return format(parseISO(halfPayDueDate.toISOString()), DATE_FORMAT);
  };

  const installmentRounds = {
    1: [
      {
        id: 1,
        label:
          rentingDetail.stage === STAGES.book.nameEN ? (
            <Grid container alignItems="center" sx={{ width: "80%" }}>
              <Typography variant="subtitle2">ที่ต้องชำระ</Typography>
              <Button
                onClick={cancelReservationHandler}
                sx={(theme) => ({
                  ml: "auto",
                  p: theme.spacing(0, 1, 0, 0),
                  color: theme.palette.grey[700],
                  textDecoration: "underline",
                })}
              >
                <small>ยกเลิกการจอง</small>
              </Button>
            </Grid>
          ) : (
            "ที่ต้องชำระ"
          ),
        content: (
          <PaymentStepContent
            ableCancel
            dueDate={calcDueDate(
              rentingDetail.book_time,
              "yyyy-MM-dd kk:mm:SSSS"
            )}
            onClickButton={clickOpenModal("qrModal")}
            onClickLink={clickOpenModal("informTransferModal")}
          />
        ),
      },
      {
        id: 2,
        label: "ชำระค่าเช่าเรียบร้อย",
        content: (
          <ReceiptContent elevation={0}>
            <Typography component="span" variant="subtitle2">
              ใบเสร็จรับเงิน
              <OutlinedFab
                color="secondary"
                variant="extended"
                onClick={clickDownloadReciept("01")}
                sx={{ ml: 1 }}
              >
                ดาวน์โหลด
                <DownloadIcon color="secondary" />
              </OutlinedFab>
            </Typography>
          </ReceiptContent>
        ),
      },
      { id: 3, label: "อยู่ระหว่างการเช่าใช้งาน", content: null },
      { id: 4, label: "สิ้นสุดสัญญาเช่า", content: null },
    ],
    2: [
      {
        id: 1,
        label:
          rentingDetail.stage === STAGES.book.nameEN ? (
            <Grid container alignItems="center" sx={{ width: "80%" }}>
              <Typography variant="subtitle2">ที่ต้องชำระ</Typography>
              <Button
                onClick={cancelReservationHandler}
                sx={(theme) => ({
                  ml: "auto",
                  p: theme.spacing(0, 1, 0, 0),
                  color: theme.palette.grey[700],
                  textDecoration: "underline",
                })}
              >
                <small>ยกเลิกการจอง</small>
              </Button>
            </Grid>
          ) : (
            "ที่ต้องชำระ"
          ),
        content: (
          <PaymentStepContent
            ableCancel
            dueDate={calcDueDate(
              rentingDetail.book_time,
              "yyyy-MM-dd kk:mm:SSSS"
            )}
            onClickButton={clickOpenModal("qrModal")}
            onClickLink={clickOpenModal("informTransferModal")}
          />
        ),
      },
      {
        id: 2,
        label: "ชำระค่าเช่าเรียบร้อย",
        content: (
          <ReceiptContent elevation={0}>
            <Typography component="span" variant="subtitle2">
              ใบเสร็จงวดที่ 1/2
              <OutlinedFab
                color="secondary"
                variant="extended"
                onClick={clickDownloadReciept("01")}
                sx={{ ml: 1 }}
              >
                ดาวน์โหลด
                <DownloadIcon color="secondary" />
              </OutlinedFab>
            </Typography>
          </ReceiptContent>
        ),
      },
      {
        id: 3,
        label: "อยู่ระหว่างการเช่าใช้งาน",
        content: (
          <PaymentStepContent
            paymentRound="รอบที่ 2"
            dueDate={
              checkIsToday(calcHalfPayDueDate(rentingDetail.startdate))
                ? "ภายในวันนี้"
                : calcHalfPayDueDate(rentingDetail.startdate) || ""
            }
            onClickButton={clickOpenModal("qrModal")}
            onClickLink={clickOpenModal("informTransferModal")}
          />
        ),
      },
      {
        id: 4,
        label: "ชำระค่าเช่าเรียบร้อย รอบที่ 2",
        content: (
          <ReceiptContent elevation={0}>
            <Typography component="span" variant="subtitle2">
              ใบเสร็จงวดที่ 2/2
              <OutlinedFab
                color="secondary"
                variant="extended"
                onClick={clickDownloadReciept("02")}
                sx={{ ml: 1 }}
              >
                ดาวน์โหลด
                <DownloadIcon color="secondary" />
              </OutlinedFab>
            </Typography>
          </ReceiptContent>
        ),
      },
      { id: 5, label: "สิ้นสุดสัญญาเช่า", content: null },
    ],
  };

  const QRPrice =
    rentingDetail.installments === 2
      ? rentingDetail.halfPrice?.toLocaleString()
      : rentingDetail.totalPrice?.toLocaleString();

  return (
    <Fragment>
      <ScrollToTop />
      <Grid container p={2} sx={{ flexGrow: 1 }}>
        <Grid item xs={12} mb={2}>
          <OutlinedChip
            align="flex-start"
            color="primary"
            label={
              <Typography textAlign="left" variant="subtitle2">
                ใบจองสินค้า หมายเลขที่ {bookingId}
              </Typography>
            }
            sx={{ width: "100%", flex: 1 }}
          ></OutlinedChip>
        </Grid>
        <Grid container item xs={12} mb={2}>
          {rentingDetail?.VIN?.length > 0 &&
            rentingDetail.VIN.map((product, _, VINs) => {
              const onlyMainProduct = VINs.length === 1;
              return (
                <Grid
                  key={product.VIN}
                  container
                  item
                  direction="column"
                  xs={onlyMainProduct ? 12 : 6}
                  sm={onlyMainProduct ? 12 : 3}
                  mb={2}
                >
                  <Grid container item sx={{ flexGrow: 1 }}>
                    <ImgProduct
                      src={product.image}
                      alt={`${product.VIN}_img`}
                      sx={{
                        p: 1,
                        m: "auto",
                        borderRadius: 3,
                      }}
                    />
                  </Grid>
                  <Grid container item sx={{ flexGrow: 1 }}>
                    <Typography
                      textAlign="center"
                      variant="subtitle2"
                      sx={{ mt: "auto", mx: "auto" }}
                    >
                      {product.nameTH} รุ่น {product?.model}
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
        </Grid>
        <Grid item xs={12} mb={2}>
          <DateTimeDescriptionCard
            bgColor="dark"
            color="secondary"
            startText="วันที่รับ"
            endText="วันที่คืน"
            startValue={rentingDetail.startdate || ""}
            endValue={rentingDetail.enddate || ""}
          />
        </Grid>
        <Grid container item xs={12} mb={2}>
          <Typography variant="subtitle2">รวมค่าใช้จ่าย</Typography>
          <Typography
            variant="subtitle2"
            sx={(theme) => ({
              color: theme.palette.secondary.main,
              padding: theme.spacing(0, 2),
              borderBottom: `1px solid ${theme.palette.grey[300]}`,
            })}
          >
            {rentingDetail.totalPrice?.toLocaleString()}
          </Typography>
          <Typography variant="subtitle2">บาท</Typography>
        </Grid>
        <Grid container item alignItems="center" xs={12} mb={2}>
          <Typography variant="subtitle2" mr={1}>
            รายละเอียดสัญญา >>
          </Typography>
          <OutlinedFab
            color="secondary"
            variant="extended"
            onClick={clickDownloadContract}
          >
            <Typography variant="subtitle2">ดาวน์โหลด</Typography>
            <DownloadIcon color="secondary" />
          </OutlinedFab>
        </Grid>
        <Grid container item alignItems="center" xs={12} mb={3}>
          <Typography variant="subtitle2" mr={1}>
            ใบแจ้งหนี้ >>
          </Typography>
          <OutlinedFab
            color="secondary"
            variant="extended"
            onClick={clickDownloadInvoice}
          >
            <Typography variant="subtitle2">ดาวน์โหลด</Typography>
            <DownloadIcon color="secondary" />
          </OutlinedFab>
        </Grid>
        <Grid item xs={12} mb={2}>
          <Typography variant="body1" mb={1}>
            <strong>สถานะสัญญา</strong>
          </Typography>
          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            connector={<CustomizedConnector />}
            sx={{
              p: 0,
              "& .MuiStepContent-root": {
                ml: "11px",
                borderLeft: (theme) => `2px solid ${theme.palette.grey[300]}`,
              },
            }}
          >
            {installmentRounds[rentingDetail.installments || 1].map((step) => (
              <Step key={step.id}>
                <StepLabel>{step.label}</StepLabel>
                <StepContent>{step.content}</StepContent>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid container item xs={12}>
          <Link
            component="button"
            onClick={() => {
              history.push("/");
            }}
            sx={(theme) => ({
              color: theme.palette.grey[500],
              textDecorationColor: theme.palette.grey[300],
              ml: "auto",
            })}
          >
            <Typography variant="subtitle2">{`< ย้อนกลับหน้าหลัก`}</Typography>
          </Link>
        </Grid>
      </Grid>

      <Modal
        maxWidth="xs"
        openModal={isOpen.qrModal}
        onCloseModal={clickCloseModal("qrModal")}
        titleWithClose
        dialogTitle={
          <Typography variant="body1" align="center">
            QR CODE
          </Typography>
        }
      >
        <Grid container item direction="column" xs>
          <ImgQR
            src={documents.QR30}
            alt="QR_30_image"
            sx={{ mt: 1, mb: 3, mx: "auto" }}
          />
          <Grid
            container
            item
            justifyContent="space-around"
            alignItems="center"
            xs
            mb={2}
          >
            <Typography variant="subtitle2">จำนวนเงิน</Typography>
            <Paper elevation={0} sx={{ p: 0.5, bgcolor: "primary.light" }}>
              <Typography variant="subtitle2">
                <strong>{QRPrice}</strong>
              </Typography>
            </Paper>
            <Typography variant="subtitle2">บาท</Typography>
          </Grid>
          <Typography align="left" variant="subtitle2">
            หมายเหตุ
          </Typography>
          <Typography
            align="left"
            variant="subtitle2"
            sx={{ mb: 2, textIndent: 12 }}
          >
            สถานะการชำระเงินจะสำเร็จ หลังการโอนเงิน 12 ชั่วโมง
            หากสถานะไม่เปลี่ยนแปลง กรุณาแจ้งโอนเงินเพื่อยืนยันสถานะอีกครั้ง
          </Typography>
          <OutlinedFab
            className="mb-2 modal-qr__action-button"
            color="secondary"
            variant="extended"
            onClick={clickDownloadQR}
            sx={{ mx: "auto", width: "50%" }}
          >
            <Typography variant="body1">บันทึก</Typography>
          </OutlinedFab>
        </Grid>
      </Modal>

      <Modal
        openModal={isOpen.informTransferModal}
        onCloseModal={clickCloseModal("informTransferModal")}
        titleWithClose
        dialogTitle={
          <Typography variant="body1" align="left">
            แจ้งโอนเงิน
          </Typography>
        }
      >
        <Grid
          container
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          py={1}
          px={1.5}
        >
          <UploadImage
            name="transfer-slip"
            informText={
              <Fragment>
                <Typography color="primary">กดเพื่ออัพโหลด</Typography>
              </Fragment>
            }
            color="primary"
            previewImage={selectedFile}
            onChangeHandler={clickUploadSlip}
            onClickDeleteHandler={clickDeleteSlip}
          />
          <Typography variant="caption" sx={{ mt: 1 }}>
            อัพโหลดได้เฉพาะรูป (JPG หรือ PNG)
          </Typography>
          <Typography variant="caption" sx={{ mb: 2 }}>
            ขนาดไม่เกิน 3 MB เท่านั้น
          </Typography>
          <Input
            control={control}
            defaultValue=""
            variant="filled"
            name="note"
            label="หมายเหตุ"
            type="text"
            multiline={true}
            rows={2}
            width={{ label: "25%", input: "75%" }}
            sx={{ mb: 3 }}
          />
          <OutlinedFab
            color="secondary"
            variant="extended"
            onClick={clickVerifySlip(payRound)}
            disabled={!selectedFile.data || !selectedFile.name}
            sx={{ mb: 1 }}
          >
            <Typography variant="subtitle2">ตกลง</Typography>
          </OutlinedFab>
        </Grid>
      </Modal>

      <Alert
        position={{ vertical: "bottom", horizontal: "center" }}
        open={showVerifyStatus.isOpen}
        message={showVerifyStatus.message}
        hideDuration={5000}
        status="info"
        onClose={closeAlert}
      />
    </Fragment>
  );
};

export default RentingDetailPage;

import React from "react";

import {
  SwipeableDrawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

import { History as HistoryIcon } from "@mui/icons-material";

const useStyles = makeStyles((theme) => {
  return {
    root: {},
    list: {
      width: 200,
    },
  };
});

const Sidebar = ({ open, onClose, onOpen }) => {
  const classes = useStyles();

  return (
    <SwipeableDrawer
      className={classes.root}
      anchor="left"
      open={open}
      onClose={onClose(false)}
      onOpen={onOpen(true)}
    >
      <List className={classes.list}>
        <ListItem button>
          <ListItemIcon>
            <HistoryIcon />
          </ListItemIcon>
          <ListItemText primary="ประวัติการเช่า" />
        </ListItem>
      </List>
    </SwipeableDrawer>
  );
};

export default Sidebar;

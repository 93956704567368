import { useEffect } from "react";
import ReactGA from "react-ga";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Compose from "stores/Compose";
import RentingProvider from "stores/RentingInformation/RentingProvider";
import AuthProvider from "stores/Authentication/AuthProvider";
import LineLoginProvider from "stores/LineLogin/LineLoginProvider";

/* ===== Layouts ===== */
import MainLayout from "layouts/MainLayout";

/* ===== Pages ===== */
import HomePage from "pages/Home/HomePage";
/* ===== Menu ===== */
import BookingHistoryPage from "pages/Menu/BookingHistoryPage";
/* Machine Selection */
import MachineFilterPage from "pages/MachineSelection/MachineFilterPage";
import MachineDetailPage from "pages/MachineSelection/MachineDetailPage";
import MachineConfirmPage from "pages/MachineSelection/MachineConfirmPage";
/* Contract */
import ContractFormPage from "pages/Contract/ContractFormPage";
import RentingConditionPage from "pages/Contract/RentingConditionPage";
import RentingSummary from "pages/Contract/RentingSummary";
/* Renting Detail */
import RentingDetailPage from "pages/RentingDetail/RentingDetailPage";

const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID || null;
if (TRACKING_ID)
  ReactGA.initialize([
    {
      tackingId: TRACKING_ID,
      gaOptions: { name: "[KIN] Google Analytics Measurement" },
    },
    {
      trackingId: "AW-11097609747",
      gaOptions: { name: "[OverlayMkt] Google Ads Conversion" },
    },
  ]);

// console.log("facebook pixel [fbq]: ", window.fbq);

const Routes = () => {
  useEffect(() => {
    if (TRACKING_ID)
      ReactGA.pageview(window.location.pathname + window.location.search);
    window.fbq("track", window.location.pathname + window.location.search);
  }, []);

  return (
    <Compose
      components={[Router, LineLoginProvider, AuthProvider, RentingProvider]}
    >
      <Switch>
        <Route path="/renting/contract-detail/:bookingId">
          <MainLayout step="รายละเอียดการจองสินค้า">
            <RentingDetailPage />
          </MainLayout>
        </Route>

        <Route path="/renting/summary/:bookingId">
          <MainLayout step="ขั้นตอนที่ 4 สรุปรายละเอียดสินค้าที่เช่า">
            <RentingSummary />
          </MainLayout>
        </Route>

        <Route path="/renting/condition/">
          <MainLayout step="ขั้นตอนที่ 3 สรุปรายละเอียดสินค้าที่เช่า">
            <RentingConditionPage />
          </MainLayout>
        </Route>

        <Route path="/renting/">
          <MainLayout step="ขั้นตอนที่ 3 สรุปรายละเอียดสินค้าที่เช่า">
            <ContractFormPage />
          </MainLayout>
        </Route>

        <Route path="/machine-selection/:productId/confirm">
          <MainLayout step="ขั้นตอนที่ 2 เลือกสินค้าที่ให้บริการ">
            <MachineConfirmPage />
          </MainLayout>
        </Route>

        <Route path="/machine-selection/:productId">
          <MainLayout step="ขั้นตอนที่ 2 เลือกสินค้าที่ให้บริการ">
            <MachineDetailPage />
          </MainLayout>
        </Route>

        <Route path="/machine-selection">
          <MainLayout step="ขั้นตอนที่ 2 เลือกสินค้าที่ให้บริการ">
            <MachineFilterPage />
          </MainLayout>
        </Route>

        <Route path="/booking-history">
          <MainLayout fullWidth step="ประวัติการจอง">
            <BookingHistoryPage />
          </MainLayout>
        </Route>

        <Route path="/">
          <MainLayout navBorder>
            <HomePage />
          </MainLayout>
        </Route>
      </Switch>
    </Compose>
  );
};

export default Routes;

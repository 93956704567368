import PropTypes from "prop-types";

import { Paper, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => {
  return {
    card: {
      display: "flex",
      justifyContent: "space-around",
      backgroundColor: ({ bgColor }) =>
        bgColor === "dark" ? "#E5E5E5" : "#F9F9F9",
      padding: theme.spacing(2, 0),
      borderRadius: "10px",
      "& .datetime": {
        display: "flex",
        flexDirection: "column",
        "&__label": {
          paddingBottom: theme.spacing(1),
        },
        "&__value": {
          color: ({ color }) => (color ? theme.palette[color].main : ""),
          width: "5rem",
          [theme.breakpoints.up("nxs")]: {
            width: "7rem",
          },
          [theme.breakpoints.up("sm")]: {
            width: "8rem",
          },
          borderBottom: "1px solid #C4C4C4",
          textAlign: "center",
        },
      },
    },
  };
});

const DateTimeDescriptionCard = ({
  bgColor,
  color,
  startText,
  endText,
  startValue,
  endValue,
}) => {
  const classes = useStyles({ bgColor, color });

  return (
    <Paper className={classes.card} elevation={0}>
      <div className="datetime">
        <Typography className="datetime__label" variant="subtitle2">
          {startText}
        </Typography>
        <span className="datetime__value">{startValue}</span>
      </div>
      <div className="datetime">
        <Typography className="datetime__label" variant="subtitle2">
          {endText}
        </Typography>
        <span className="datetime__value">{endValue}</span>
      </div>
    </Paper>
  );
};

DateTimeDescriptionCard.propTypes = {
  // color: PropTypes.oneOf(["primary", "secondary"]),
  startText: PropTypes.string.isRequired,
  endText: PropTypes.string.isRequired,
  startValue: PropTypes.string.isRequired,
  endValue: PropTypes.string.isRequired,
};

DateTimeDescriptionCard.defaultProps = {
  bgColor: "",
};

export default DateTimeDescriptionCard;

import { forwardRef, Fragment } from "react";
import PropTypes from "prop-types";

import { Paper as MuiPaper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const Paper = styled(MuiPaper)(({ theme }) => ({
  display: "flex",
  flexFlow: "column wrap",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#F9F9F9",
  paddingRight: theme.spacing(2),
  "& > *:not(:first-of-type)": {
    paddingRight: theme.spacing(3),
    color: theme.palette.common.black,
  },
  "& > .MuiTypography-root": {
    paddingTop: theme.spacing(2),
  },
  "& ul": {
    paddingInlineStart: theme.spacing(2),
    "& > li": {
      listStyle: "disc",
      fontSize: ".65rem",
    },
  },
}));

export const termAndConditionLists = (props) => [
  {
    id: 1,
    mainContent:
      "สามารถจองทรัพย์สินที่จะเช่าได้ล่วงหน้าอย่างน้อยที่สุด 3 วัน แต่ไม่เกิน 90 วัน ก่อนวันรับทรัพย์สินที่เช่า",
  },
  {
    id: 2,
    mainContent:
      "ค่าเช่า หลักประกันตามสัญญา และค่าใช้จ่ายอื่นๆ เป็นไปตามรายละเอียดในสัญญาที่ตกลงกัน",
  },
  {
    id: 3,
    mainContent:
      "หลังจากทำการจองในระบบแล้ว ผู้เช่าช่วงจะชำระค่าเช่าให้แก่ผู้ให้เช่าช่วง ภายใน 3 (สาม) วันทำการ หลังวันส่งมอบทรัพย์สินที่เช่าตามวันเริ่มต้นในสัญญา ทั้งนี้ หากไม่ทำการชำระเงินระบบจะทำการยกเลิกการจองอัตโนมัติ",
  },
  {
    id: 4,
    mainContent:
      "หากผู้เช่าช่วงไม่ชำระค่าเช่าตามระยะเวลาที่กำหนด ผู้ให้เช่าช่วงมีสิทธิบอกเลิกสัญญาได้ทันที โดยไม่ต้องแจ้งให้ผู้เช่าช่วงทราบล่วงหน้าและร้องขอให้ส่งคืนทรัพย์สินที่เช่ากลับในทันที ในกรณีดังกล่าวผู้ให้เช่าช่วงจะไม่คืนเงินค่าเช่าและหลักประกันตามสัญญาที่ได้รับให้กับผู้เช่าช่วง นอกจากนี้ ผู้เช่าช่วงจะต้องรับผิดชอบค่าเสียหายให้แก่ผู้ให้เช่าช่วงในอัตราตามที่กำหนดในสัญญาด้วย",
  },
  {
    id: 5,
    mainContent:
      "ผู้เช่าช่วงจะต้องใช้งานทรัพย์สินที่เช่าให้เป็นไปตามรายละเอียดในสัญญาที่ตกลงกัน เช่น ผู้เช่าช่วงจะไม่นำทรัพย์สินที่เช่าไปใช้นอกราชอาณาจักร เว้นแต่จะได้รับความยินยอมจากผู้ให้เช่าช่วงเป็นลายลักษณ์อักษร ผู้เช่าช่วงจะต้องไม่โอนสิทธิการเช่าให้ผู้อื่นหรือเอาทรัพย์สินที่เช่าทั้งหมดหรือบางส่วนออกให้ผู้อื่นใช้หรือเช่าช่วง เป็นต้น",
    // subContentLists: [
    //   { id: "5.1", content: "ระบุพื้นที่ในการใช้งาน" },
    //   { id: "5.2", content: "ใช้งานด้วยความระมัดระวัง" },
    //   {
    //     id: "5.3",
    //     content: "รับผิดชอบความสูญหาย หรือเสียหายที่เกิด",
    //   },
    //   {
    //     id: "5.4",
    //     content: `ห้ามสับเปลี่ยนชิ้นส่วน หรือดัดแปลง และต้องใช้อะไหล่แท้ในการซ่อมแซมเท่านั้น`,
    //   },
    //   {
    //     id: "5.5",
    //     content: `กรณีใช้งานเกินกว่าที่กำหนด ต้องจ่ายค่าบริการเพิ่มในอัตรา ${props.chargePerHour} บาท/ชั่วโมง(คำนวนจากค่าเช่ารายชั่วโมงของตัวรถ)`,
    //   },
    //   {
    //     id: "5.6",
    //     content: "ผู้เช่ารับผิดชอบจ่ายค่าน้ำมันเอง",
    //   },
    //   {
    //     id: "5.7",
    //     content: "ดูแลทรัพย์สิน 24 ชั่วโมง และห้ามนำออกไปใช้งานนอกราชอาณาจักร",
    //   },
    //   { id: "5.8", content: "ไม่สามารถโอนย้ายสิทธิในการเช่าได้" },
    // ],
  },
  {
    id: 6,
    mainContent:
      "ผู้เช่าช่วงสามารถเปลี่ยนวันเวลารับทรัพย์สินที่เช่าได้ภายใน 15 วัน นับแต่วันที่ได้มีการตกลงนัดหมายส่งมอบทรัพย์สินที่เช่ากัน และต้องทำการแจ้งผู้ให้เช่าช่วงทราบล่วงหน้าไม่น้อยกว่า 7 วัน ก่อนวันนัดหมายส่งมอบทรัพย์สินที่เช่าเดิม",
  },
  {
    id: 7,
    mainContent:
      "ผู้ให้เช่าช่วงสามารถบอกเลิกสัญญาได้ทันที หากผู้เช่าช่วงผิดสัญญาข้อใดข้อหนึ่งตามรายละเอียดในสัญญาที่ตกลงกัน เช่น ผิดนัดไม่ชำระค่าเช่าภายในระยะเวลาที่กำหนด ผู้เช่าช่วงโอนสิทธิการเช่าช่วงให้ผู้อื่นหรือเอาทรัพย์สินที่เช่าออกให้เช่าช่วง โดยไม่ได้รับความยินยอมเป็นหนังสือจากผู้ให้เช่าช่วง ทรัพย์สินที่เช่าถูกใช้เป็นพาหนะในการกระทำความผิด เป็นต้น",
    // subContentLists: [
    //   { id: "7.1", content: "ค้างจ่ายค่าเช่าตามสัญญากำหนดมากกว่า 3 วัน" },
    //   { id: "7.2", content: "บอกข้อมูลไม่ถูกต้อง" },
    //   {
    //     id: "7.3",
    //     content:
    //       "ผู้เช่า จำนอง จำนำ หรือพยายามจะจำนอง จำนำ ทรัพย์สินที่เช่า หรือมีการกระทำที่ส่อเจตนาทุจริต เช่นนำไปประกาศขายต่อ",
    //   },
    //   { id: "7.4", content: "ผู้เช่าถูกดำเนินคดี" },
    // ],
  },
  {
    id: 8,
    mainContent:
      "หากทรัพย์สินที่เช่ามีการชำรุดเสียหาย เกิดอุบัติเหตุ หรือถูกโจรกรรมขึ้นในช่วงระยะเวลาในการใช้งานตามสัญญา ผู้เช่าช่วงจะหยุดการใช้งานทรัพย์สินที่เช่าในทันทีและดำเนินการตามมาตรการซึ่งระบุไว้ในสัญญาที่ตกลงกัน เช่น รายงานรายละเอียดของอุบัติเหตุให้ผู้ให้เช่าช่วงทราบทันที ให้ความร่วมมือกับผู้ให้เช่าช่วงและบริษัทประกันภัยในการตรวจสอบอุบัติเหตุ เป็นต้น",
    // {
    //   firstParagraph: "ใช้สิทธิในการซื้อทรัพย์สิน",
    //   secondParagraph:
    //     "หากผู้เช่าต้องการซื้อทรัพย์สิน สามารถซื้อทรัพย์สินที่เช่าได้โดยทำการติดต่อแจ้งความจำนงค์แก่เจ้าหน้าที่",
    // },
  },
  {
    id: 9,
    mainContent:
      "กรณีที่มีการติดตั้งระบบ GPS ในทรัพย์สินที่เช่า ผู้เช่าช่วงยินยอมให้ทำการบันทึกข้อมูลตำแหน่งปัจจุบัน เส้นทางที่สัญจร ฯลฯ ของทรัพย์สินที่เช่าลงในระบบที่กำหนดโดยผู้ให้เช่าช่วง และยินยอมให้ผู้ให้เช่าช่วงเก็บรวบรวม ใช้ และเปิดเผยข้อมูลที่ได้ทำการบันทึกดังกล่าวนั้นเพื่อวัตถุประสงค์ต่างๆ ตามที่ระบุไว้ในสัญญาที่ตกลงกัน เช่น เพื่อตรวจสอบว่าได้ส่งคืนทรัพย์สินที่เช่าไว้ที่สถานที่ที่กำหนดแล้วในตอนที่สัญญาเช่าช่วงสิ้นสุดลง เพื่อตรวจสอบตำแหน่งปัจจุบันของทรัพย์สินที่เช่าที่เห็นว่ามีความจำเป็นต่อการจัดการทรัพย์สินที่เช่าหรือเพื่อให้มีการปฏิบัติตามสัญญา เป็นต้น",
    // {
    //   firstParagraph:
    //     "หน้าที่ของผู้เช่ากรณีที่มีการชำรุดเสียหาย หรือเกิดอุบัติเหตุ หรือถูกโจรกรรม",
    //   secondParagraph:
    //     "ผู้เช่าต้องมีการแจ้งผู้ให้ทันทีที่ทราบเหตุ หากเป็นการโจรกรรมสามารถทำการแจ้งความเบื้องต้นไว้ก่อนได้เพื่อดำเนินการทางกฏหมาย",
    // },
  },
  {
    id: 10,
    mainContent:
      "เช่าช่วงให้การรับรองว่าได้อ่านทำความเข้าใจข้อกำหนดและเงื่อนไขที่สำคัญ และสัญญาโดยละเอียดแล้ว ก่อนยอมรับข้อกำหนดและเงื่อนไขและเข้าทำสัญญากับผู้ให้เช่าช่วง",
  },
];

const TermsAndConditions = forwardRef(({ sx, ...props }, ref) => {
  return (
    <Paper sx={sx}>
      <Fragment>
        <Typography variant="subtitle1">เงื่อนไขการเช่า</Typography>
        <ol>
          {termAndConditionLists(props).map((list, idx, listArray) => (
            <li
              key={list.id}
              ref={termAndConditionLists(props).length - 1 === idx ? ref : null}
            >
              <Typography variant="subtitle2">
                {list?.mainContent?.firstParagraph || list.mainContent}
              </Typography>
              {list.mainContent.secondParagraph ? (
                <Typography variant="subtitle2">
                  {list.mainContent.secondParagraph}
                </Typography>
              ) : null}
              <ul>
                {list?.subContentLists?.length > 0 &&
                  list.subContentLists.map((subList) => (
                    <li key={subList.id}>
                      <Typography variant="caption">
                        {subList.content}
                      </Typography>
                    </li>
                  ))}
              </ul>
            </li>
          ))}
        </ol>
      </Fragment>
    </Paper>
  );
});

TermsAndConditions.propTypes = {
  chargePerHour: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

TermsAndConditions.defaultProps = {
  chargePerHour: 0,
};

export default TermsAndConditions;

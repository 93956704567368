import { createContext } from "react";

const RentingContext = createContext({
  userData: {},
  products: {},
  saveUserData: (data) => {},
  addProducts: (data) => {},
});

export default RentingContext;

// mainProduct: {},
//   implements: [],
//   startDate: "",
//   endDate: "",
//   startTime: "",
//   endTime: "",
//   renter: {
//     firstName: "",
//     lastName: "",
//     Cid: "",
//     Tel: "",
//     address: "",
//     province: "",
//     district: "",
//     subDistrict: "",
//     zipcode: "",
//   },
//   delivery: {
//     address: "",
//     province: "",
//     district: "",
//     subDistrict: "",
//     zipcode: "",
//   },

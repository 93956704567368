import PropTypes from "prop-types";
import { useController } from "react-hook-form";
import th from "date-fns/locale/th";

import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  FormControl as MuiFormControl,
  InputLabel,
  Input,
  OutlinedInput,
  FilledInput,
  FormHelperText,
  styled,
} from "@mui/material";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";

const FormControl = styled(MuiFormControl, {
  shouldForwardProp: (prop) => prop !== "size",
})(({ theme, size }) => {
  const isMedium = size === "medium";
  return {
    "& .MuiFormLabel-root": {
      fontSize: isMedium ? "1rem" : ".75rem",
    },
    "& .MuiInputBase-root": {
      fontSize: isMedium ? "1rem" : ".75rem",
      "& .MuiFilledInput-input": {
        paddingTop: theme.spacing(isMedium ? 1.5 : 0.5),
      },
      "& .MuiOutlinedInput-input": {
        paddingTop: theme.spacing(isMedium ? 1.5 : 0.5),
        paddingLeft: theme.spacing(isMedium ? 1.5 : 0.5),
      },
    },
  };
});

const InlineFormControl = styled(MuiFormControl, {
  shouldForwardProp: (prop) => prop !== "size" && prop !== "width",
})(({ theme, size, width }) => {
  const isMedium = size === "medium";
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiFormLabel-root": {
      fontSize: isMedium ? "1rem" : ".75rem",
    },
    "& .MuiInputLabel-formControl": {
      display: "flex",
      justifyContent: "flex-start",
      alignSelf: "flex-start",
      position: "static",
      transform: "translate(0,0)",
      flex: `1 0 ${width.label}`,
      paddingTop: theme.spacing(isMedium ? 1.5 : 0.5),
      paddingLeft: 0,
      [theme.breakpoints.up("md")]: {
        justifyContent: "center",
        paddingLeft: theme.spacing(2),
      },
    },
    "& .MuiInputBase-root": {
      flexBasis: width.input,
      fontSize: isMedium ? "1rem" : ".75rem",
      "& .MuiFilledInput-input": {
        paddingTop: theme.spacing(isMedium ? 1.5 : 0.5),
      },
      "& .MuiOutlinedInput-input": {
        paddingTop: theme.spacing(isMedium ? 1.5 : 0.5),
        paddingLeft: theme.spacing(isMedium ? 1.5 : 0.5),
      },
    },
  };
});

const InputWithHelperText = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flexBasis: "100%",
  "& .MuiFormHelperText-contained": {
    margin: theme.spacing(0.5, 0),
  },
}));

const DatePickerInput = ({
  control,
  defaultValue,
  onChangeInputDate,
  shouldDisableDate,
  renderDay,
  defaultMonth,
  ...props
}) => {
  const {
    field: { name, value, onChange },
    fieldState: { error },
  } = useController({ control, defaultValue, name: props.name });

  const onChangeDateTime = (callback) => (newValue) => {
    if (onChangeInputDate) return onChangeInputDate(name, newValue, callback);
    callback(newValue);
  };

  const inputComponent = (variant, childrenProps) => {
    const styles = {
      standard: <Input {...childrenProps} />,
      outlined: <OutlinedInput {...childrenProps} />,
      filled: <FilledInput {...childrenProps} />,
    };
    return styles[variant];
  };

  const datePickerFormLayout = (style = "default", parentProps, children) => {
    const components = {
      default: (
        <FormControl {...parentProps} error={!!parentProps.error}>
          <InputLabel error={!!parentProps.error} htmlFor={parentProps.htmlFor}>
            {parentProps.label}
          </InputLabel>
          {children}
        </FormControl>
      ),
      inline: (
        <InlineFormControl {...parentProps} error={!!parentProps.error}>
          <InputLabel error={!!parentProps.error} htmlFor={parentProps.htmlFor}>
            {parentProps.label}
          </InputLabel>
          {children}
        </InlineFormControl>
      ),
    };

    return components[style];
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={th}>
      <MobileDatePicker
        okText={props.okText}
        cancelText={props.cancelText}
        toolbarTitle={props.toolbarTitle}
        clearText={props.clearText}
        clearable={props.clearable}
        disabled={props.disabled}
        defaultCalendarMonth={defaultMonth}
        value={value}
        onChange={onChangeDateTime(onChange)}
        shouldDisableDate={shouldDisableDate}
        renderDay={renderDay}
        renderInput={({
          InputProps: {
            endAdornment = props.showIcon ? (
              <InsertInvitationIcon
                sx={{
                  color: (theme) =>
                    error ? theme.palette.error.main : theme.palette.grey[600],
                }}
              />
            ) : null,
          },
          ...renderProps
        }) => {
          const errorText = error?.message ? (
            <FormHelperText error={!!error}>{error.message}</FormHelperText>
          ) : null;

          return datePickerFormLayout(
            props.inline ? "inline" : "default",
            {
              htmlFor: `${name}${props.inline ? "-inline" : ""}__date-picker`,
              variant: props.variant,
              disabled: props.disabled,
              size: props.size,
              width: props.width,
              label: props.label,
              sx: props.sx,
              error,
            },
            props.inline ? (
              <InputWithHelperText>
                {inputComponent(props.variant, {
                  ...renderProps,
                  id: `${name}-inline__date-picker`,
                  name,
                  error: props.showErrorText && !!error,
                  size: props.size,
                  endAdornment,
                  inputProps: {
                    ...renderProps.inputProps,
                    placeholder: "",
                  },
                })}
                {props.showErrorText ? errorText : null}
              </InputWithHelperText>
            ) : (
              inputComponent(props.variant, {
                ...renderProps,
                id: `${name}__date-picker`,
                name,
                error: props.showErrorText && !!error,
                size: props.size,
                endAdornment,
                inputProps: {
                  ...renderProps.inputProps,
                  placeholder: "",
                },
              })
            )
          );
        }}
      />
    </LocalizationProvider>
  );
};

DatePickerInput.propTypes = {
  control: PropTypes.object.isRequired,
  label: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  onChangeInputDate: PropTypes.func,
  shouldDisableDate: PropTypes.func,
  renderDay: PropTypes.func,
  defaultMonth: PropTypes.instanceOf(Date),
  showErrorText: PropTypes.bool,
  showIcon: PropTypes.bool,
  inline: PropTypes.bool,
  size: PropTypes.oneOf(["medium", "small"]),
  width: PropTypes.shape({
    label: PropTypes.string,
    input: PropTypes.string,
  }),
  variant: PropTypes.oneOf(["standard", "outlined", "filled"]),
  clearable: PropTypes.bool,
};

DatePickerInput.defaultProps = {
  showIcon: false,
  showErrorText: false,
  inline: true,
  size: "small",
  width: {
    label: "25%",
    input: "75%",
  },
  okText: "ตกลง",
  cancelText: "ยกเลิก",
  clearText: "ล้างค่า",
  variant: "standard",
  clearable: true,
};

export default DatePickerInput;

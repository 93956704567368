import React from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

import {
  FormControl as MuiFormControl,
  InputLabel,
  Select,
  ListSubheader,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const FormControl = styled(MuiFormControl)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  columnGap: 5,
  "& .MuiFormLabel": {
    "&-root": {
      fontSize: "small",
    },
  },
  "& .MuiInputLabel": {
    "&-formControl": {
      display: "flex",
      justifyContent: "flex-start",
      alignSelf: "flex-start",
      position: "static",
      transform: "translate(0,0)",
      paddingTop: theme.spacing(1.5),
      paddingLeft: theme.spacing(2),
      flexBasis: "50%",
      [theme.breakpoints.up("sm")]: {
        flexBasis: "25%",
      },
    },
  },
  "& .MuiInputBase": {
    "&-root": {
      flexGrow: 1,
      width: "100%",
      marginLeft: "auto",
      marginTop: 0,
      // flexBasis: "50%",
      // [theme.breakpoints.up("sm")]: {
      flexBasis: "100%",
      // },
      "&.MuiFilledInput": {
        "&-root": {
          "& .MuiFilledInput-input": {
            padding: theme.spacing(1.25, 1.5),
          },
        },
      },
    },
    "&-input": {
      fontSize: "medium",
    },
  },
}));

const InputWithHelperText = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flexBasis: "100%",
  "& .MuiFormHelperText-contained": {
    margin: theme.spacing(0.5, 0),
  },
}));

const InlineSelectDropdownInput = ({
  control,
  defaultValue,
  rules,
  subheaderName,
  firstMenuItem,
  menuItems,
  valueProp,
  menuProp,
  ...commonProps
}) => {
  return (
    <Controller
      name={commonProps.name}
      defaultValue={defaultValue}
      control={control}
      rules={rules}
      render={({ field: { name, value, onChange }, fieldState: { error } }) => {
        const subheaderElement = subheaderName && (
          <ListSubheader>{subheaderName}</ListSubheader>
        );
        const firstMenuElement = firstMenuItem && (
          <MenuItem value={firstMenuItem.value}>{firstMenuItem.label}</MenuItem>
        );
        const menuItemsElements =
          menuItems.length > 0 &&
          menuItems.map((item, idx) => (
            <MenuItem
              key={item.id ? item.id : idx}
              value={valueProp ? item[valueProp] : item}
            >
              {menuProp ? item[menuProp] : item}
            </MenuItem>
          ));

        const errorText = error?.message ? (
          <FormHelperText error={!!error}>{error.message}</FormHelperText>
        ) : null;

        return (
          <FormControl
            variant="filled"
            disabled={commonProps.disabled}
            sx={commonProps.sx}
          >
            <InputLabel id={`${name}-select-inline-input`} error={!!error}>
              {commonProps.label}
            </InputLabel>
            <InputWithHelperText>
              <Select
                labelId={`${name}-select-inline-input`}
                name={name}
                value={value}
                onChange={onChange}
                sx={
                  !!error
                    ? {
                        "&.MuiFilledInput-underline:before": {
                          transform: "scaleX(1)",
                          borderBottom: "2px solid #f44336",
                        },
                      }
                    : null
                }
              >
                {subheaderElement}
                {firstMenuElement}
                {menuItemsElements}
              </Select>
              {errorText}
            </InputWithHelperText>
          </FormControl>
        );
      }}
    />
  );
};

InlineSelectDropdownInput.propTypes = {
  control: PropTypes.object.isRequired,
  rules: PropTypes.object,
  label: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  subheaderName: PropTypes.string,
  firstMenuItem: PropTypes.exact({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }),
  menuItems: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.arrayOf(PropTypes.object),
  ]).isRequired,
};

InlineSelectDropdownInput.defaultProps = {
  disabled: false,
  menuItems: [],
};

export default InlineSelectDropdownInput;

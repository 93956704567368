import React from "react";

import {
  Grid,
  Card,
  CardContent,
  Paper,
  Button,
  Typography,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const ImageProduct = styled("img")(({ theme }) => ({
  objectFit: "contain",
  width: "100%",
  borderRadius: theme.spacing(0.5),
}));

const FullHeightDivider = styled(Divider)(({ theme }) => ({
  display: "inline",
  margin: theme.spacing(0, 2, 0, 3),
  backgroundColor: theme.palette.common.black,
  height: "100%",
}));

const PriceTag = styled(Paper)(({ theme }) => ({
  display: "inline-block",
  color: theme.palette.secondary.main,
  backgroundColor: theme.palette.grey[100],
}));

const MachineDetailCard = ({
  vin,
  name,
  model,
  year,
  hour,
  price,
  approvedDate,
  mainImage,
  detailImgs = [],
  onClickLink,
  onClickImage,
}) => {
  const handleClickLink = (e) => {
    e.preventDefault();
    onClickLink();
  };

  const handleClickImage = (idx) => () => {
    onClickImage(idx);
  };

  return (
    <Card>
      <CardContent>
        <Grid container>
          <Grid container item direction="column" xs={3}>
            <ImageProduct src={mainImage} alt={`${vin}__${model}-img`} />
            <Button
              size="small"
              onClick={handleClickLink}
              sx={{
                mt: "auto",
                mr: "auto",
                color: (theme) => theme.palette.grey[500],
              }}
            >
              {`< ย้อนกลับ`}
            </Button>
          </Grid>
          <Grid container item xs={9} sx={{ flexGrow: 1 }}>
            <FullHeightDivider orientation="vertical" />
            <Grid
              container
              item
              direction="column"
              alignItems="flex-start"
              xs={9}
              sm={10}
              sx={{ flexGrow: 1 }}
            >
              <Grid item mb={2}>
                <Typography variant="caption">
                  {name} รุ่น {model} ปี {year} ชั่วโมงการทำงาน {hour} ชั่วโมง
                  สภาพตัวรถ ณ วันที่ {approvedDate}
                </Typography>
              </Grid>

              <Grid container item>
                {detailImgs.map((img, idx) => (
                  <Grid key={idx} item xs={6} sm={4} p={1} px={0.5}>
                    <ImageProduct
                      onClick={handleClickImage(idx)}
                      src={img.image}
                      alt={`detail__img-${idx}`}
                      sx={{ my: "auto" }}
                    />
                  </Grid>
                ))}
              </Grid>
              <Grid item>
                <Typography variant="caption">
                  ราคา
                  <PriceTag elevation={0} sx={{ py: 0.25, px: 0.5, mx: 1 }}>
                    {price ? price.toLocaleString() : 0}
                  </PriceTag>
                  บาท
                </Typography>
                <Typography
                  component="p"
                  variant="caption"
                  sx={{ mt: 0.5, pl: 5 }}
                >
                  (ไม่รวมค่าส่ง)
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default MachineDetailCard;

import React, { Fragment, useRef } from "react";
import PropTypes from "prop-types";

import { Paper, Typography, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  Add as AddIcon,
  HighlightOff as HighlightOffIcon,
} from "@mui/icons-material";

const useStyles = makeStyles((theme) => {
  return {
    input: {
      display: "none",
    },
    form: {
      display: ({ previewImage }) =>
        previewImage.name && previewImage.data && "none",
      "& .form": {
        "&__label": {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          rowGap: 30,
          padding: theme.spacing(6),
          marginLeft: theme.spacing(3),
          marginRight: theme.spacing(3),
          borderRadius: 10,
          backgroundColor: ({ color }) => theme.palette[color].light,
          "&-icon": {
            fontSize: "2.5rem",
          },
        },
      },
    },
    preview: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      position: "relative",
      columnGap: 10,
      flexGrow: 1,
      padding: theme.spacing(2),
      backgroundColor: ({ color }) => theme.palette[color].light,
      "& .preview": {
        "&__cancel": {
          position: "absolute",
          top: 15,
          right: 15,
          transform: "translate(50%,-50%)",
        },
        "&__image": {
          width: theme.spacing(10),
          flexBasis: "50%",
          objectFit: "contain",
          overflow: "hidden",
        },
        "&__image-name": {
          width: "50%",
          flexBasis: "50%",
          wordWrap: "break-word",
        },
      },
    },
  };
});

const UploadImage = ({
  informText,
  color,
  previewImage,
  onChangeHandler,
  onClickDeleteHandler,
  ...props
}) => {
  const classes = useStyles({ color, previewImage });

  const inputRef = useRef(null);

  const onUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = (onloadendEvent) => {
      const fileObj = {
        name: file.name,
        data: reader.result,
      };
      onChangeHandler(fileObj);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const onDelete = () => {
    inputRef.current.value = "";
    onClickDeleteHandler();
  };

  const previewElement = previewImage.data && previewImage.name && (
    <Paper
      className={`${classes.preview} ${props.className ?? ""}`}
      elevation={0}
      sx={props.sx}
    >
      <IconButton
        onClick={onDelete}
        className="preview__cancel"
        aria-label="delete-image"
        size="large"
      >
        <HighlightOffIcon color="error" />
      </IconButton>

      <img
        className="preview__image"
        src={previewImage.data}
        alt="transfer-slip-preview"
      />
      <Typography
        component="span"
        className="preview__image-name"
        color={color}
        variant="caption"
      >
        {previewImage.name}
      </Typography>
    </Paper>
  );

  return (
    <Fragment>
      <input
        ref={inputRef}
        id={`input-${props.name}-file`}
        className={classes.input}
        accept="image/png, image/jpeg"
        type="file"
        onChange={onUpload}
      />
      <label className={classes.form} htmlFor={`input-${props.name}-file`}>
        <Paper className={`form__label ${props.className ?? ""}`} elevation={0}>
          <AddIcon className="form__label-icon" color={color} />
          {informText}
        </Paper>
      </label>
      {previewElement}
    </Fragment>
  );
};

UploadImage.propTypes = {
  name: PropTypes.string,
  informText: PropTypes.node,
  color: PropTypes.string.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  onClickDeleteHandler: PropTypes.func,
  previewImage: PropTypes.exact({
    name: PropTypes.string.isRequired,
    data: PropTypes.string.isRequired,
  }),
};

UploadImage.defaultProps = {
  color: "primary",
  previewImage: {
    name: "",
    data: "",
  },
};

export default UploadImage;

import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useMemo,
} from "react";
import { useParams, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

import {
  Grid,
  Paper,
  Typography,
  FormControlLabel,
  Checkbox,
  Fab,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";

import { endpoints, STAGES, LINE_OA_URL, CONFIRMED } from "utils/Config";
import { MINIMUM_PRICE_TO_INSTALLMENTS } from "constants/Price";
import { MINIMUM_DAYS_TO_INSTALLMENTS } from "constants/DateTime";

import LineContext from "stores/LineLogin/line-context";
import AuthContext from "stores/Authentication/auth-context";
import { useHttp } from "hooks";
import { ActionLoading, OutlinedChip, ScrollToTop } from "base-components";
import SelectDropdownInput from "components/HookForms/SelectDropdownInput";
import { DateTimeDescriptionCard, HighlightPromotionCard } from "components";

const { liff } = window;

const ImgProduct = styled("img")(({ theme }) => ({
  objectFit: "contain",
  width: "100%",
  borderRadius: theme.spacing(0.5),
}));

const useStyles = makeStyles((theme) => {
  const defaultButton = {
    fontFamily: "Prompt",
    fontSize: "0.875rem",
    fontWeigth: 400,
    lineHeight: 1.43,
    borderRadius: "1rem",
    padding: theme.spacing(1, 2),
  };
  return {
    swal2Container: {
      zIndex: theme.zIndex.appBar + 1,
    },
    swal2ConfirmButton: {
      "&.swal2-confirm": {
        width: "100%",
        color: theme.palette.common.white,
        backgroundColor: theme.palette.secondary.main,
        border: `1px solid ${theme.palette.grey[500]}`,
        ...defaultButton,
      },
    },
    swal2OutlinedConfirmButton: {
      "&.swal2-confirm": {
        color: theme.palette.common.black,
        backgroundColor: "transparent",
        border: `1px solid ${theme.palette.grey[500]}`,
        ...defaultButton,
      },
    },
    swal2CancelButton: {
      "&.swal2-cancel": {
        backgroundColor: theme.palette.grey[500],
        marginRight: theme.spacing(2),
        ...defaultButton,
      },
    },
  };
});

const installmentsMenus = [
  {
    id: 0,
    value: 0,
    label: "",
    // label: "เลือก วิธีแบ่งชำระ",
  },
  {
    id: 1,
    value: 1,
    label: "ชำระเต็มจำนวน",
  },
  {
    id: 2,
    value: 2,
    label: "ชำระเงิน 2 งวด",
  },
];

const RentingSummary = () => {
  const classes = useStyles();

  const history = useHistory();
  const { bookingState: locationBookingState } = history.location.state;
  const { bookingId } = useParams();

  const { control, watch, setValue } = useForm();
  const installments = watch("installments", 0);

  const { lineProfile } = useContext(LineContext);
  const { user, setUser } = useContext(AuthContext);
  const { sendRequest: fetchHttpData } = useHttp();
  const { isLoading: isSubmitting, sendRequest: createBooking } = useHttp();
  const { sendRequest: cancelBooking } = useHttp();

  const [contract, setContract] = useState({});
  const [promoDiscount, setPromoDiscount] = useState({});
  const [pricing, setPricing] = useState({
    totalProductsPrice: 0,
    totalDiscount: 0,
    shippingPrice: 0,
    intrinsicPrice: 0,
    totalPriceExcludeVat: 0,
    totalVat: 0,
    totalPrice: 0,
  });
  const [ableToInstallments, setAbleToInstallments] = useState(false);
  const [acceptRentingDetail, setAcceptRentingDetail] = useState(false);

  /* Confirm renting */
  const ableToConfirmRenting = acceptRentingDetail && installments;

  useEffect(() => {
    const abortCont = new AbortController();
    const { signal } = abortCont;

    const setDraftBookingData = (booking) => {
      const checkCanInstallments =
        booking.installments1?.price >= MINIMUM_PRICE_TO_INSTALLMENTS &&
        booking.rentDays >= MINIMUM_DAYS_TO_INSTALLMENTS;
      setAbleToInstallments(checkCanInstallments);
      if (checkCanInstallments) {
        setValue("installments", 0);
        setContract(booking);
        setPromoDiscount(() => {
          if (booking.promoDiscount?.error) return null;
          return booking.promoDiscount;
        });
      } else {
        setValue("installments", 1);
        const { VIN: VINs } = booking;
        const newVINPricing = VINs.map((machine) => {
          return {
            VIN: machine.VIN,
            discount: machine.discount,
            image: machine.image,
            model: machine.model,
            nameTH: machine.nameTH,
            price: machine.price,
            size: machine.size,
            serviceFeePaid2: machine.serviceFeePaid2,
            vat: machine.vat1,
          };
        });
        setContract(() => {
          return {
            ...booking,
            newVINs: newVINPricing,
            totalAmountExcludeVat: booking.installments1.amount,
            totalAmount: booking.installments1.amountIncludeVat,
          };
        });
        setPricing({
          totalProductsPrice: booking.installments1.price,
          totalDiscount: booking.installments1.discount,
          shippingPrice: booking.installments1.shippingPrice,
          intrinsicPrice: booking.installments1.amountExcludeDiscount,
          totalPriceExcludeVat: booking.installments1.amount,
          totalVat: booking.installments1.vat,
          totalPrice: booking.installments1.amountIncludeVat,
        });
        setPromoDiscount(() => {
          if (booking.promoDiscount?.error) return null;
          return booking.promoDiscount;
        });
      }
    };

    const setBookingData = (booking) => {
      setValue("installments", 1);
      const { VIN: VINs } = booking;
      const totalProductsPrice =
        VINs.reduce((total, currValue) => total + +currValue.price, 0) *
        booking.rentDays;
      const totalVat =
        VINs.reduce((total, currValue) => total + currValue.vat, 0) +
        booking.shippingVat;
      const totalDiscount = VINs.reduce(
        (total, currValue) => total + +currValue.discount,
        0
      );
      const intrinsicPrice =
        totalProductsPrice +
        booking.shippingPrice +
        booking.shippingVat +
        totalDiscount;
      const totalPrice =
        totalProductsPrice + booking.shippingPrice + totalVat - totalDiscount;
      setContract(booking);
      setPricing({
        totalProductsPrice,
        totalDiscount,
        shippingPrice: booking.shippingPrice,
        intrinsicPrice,
        totalVat,
        totalPrice,
      });
      setPromoDiscount(() => {
        if (booking.promoDiscount?.error) return null;
        return booking.promoDiscount;
      });
    };

    const fetchDraftData = (signal) => {
      fetchHttpData(
        {
          endpoint: endpoints.draftBooking(bookingId),
          signal,
        },
        setDraftBookingData
      );
    };

    const fetchBookingData = (signal) => {
      fetchHttpData(
        { endpoint: endpoints.booking(bookingId), signal },
        setBookingData
      );
    };

    if (locationBookingState === STAGES.draft.nameEN) fetchDraftData(signal);
    if (locationBookingState === STAGES.cancel.nameEN) fetchBookingData(signal);
    else fetchDraftData(signal);

    return () => {
      abortCont.abort();
    };
  }, [fetchHttpData, bookingId, locationBookingState, setValue]);

  useEffect(() => {
    const subscription = watch((value, { type }) => {
      const { VIN: VINs = [] } = contract;
      if (value.installments && type === "change") {
        const installmentProp =
          value.installments === 1 ? "installments1" : "installments2";

        // totalProductsPrice: booking.installments1.price,
        //   totalDiscount: booking.installments1.discount,
        //   shippingPrice: booking.installments1.shippingPrice,
        //   intrinsicPrice: booking.installments1.amountExcludeDiscount,
        //   totalPriceExcludeVat: booking.installments1.amount,
        //   totalVat: booking.installments1.vat,
        //   totalPrice: booking.installments1.amountIncludeVat,

        const newVINPricing = VINs.map((machine) => {
          const vatProp = value.installments === 1 ? "vat1" : "vat2";
          return {
            VIN: machine.VIN,
            discount: machine.discount,
            image: machine.image,
            model: machine.model,
            nameTH: machine.nameTH,
            price: machine.price,
            size: machine.size,
            serviceFeePaid2: machine.serviceFeePaid2,
            vat: machine[vatProp],
          };
        });
        setContract((prevContract) => ({
          ...prevContract,
          newVINs: newVINPricing,
          totalAmountExcludeVat: contract[installmentProp]?.amount,
          totalAmount: contract[installmentProp]?.amountIncludeVat,
        }));
        setPricing({
          totalProductsPrice: contract[installmentProp]?.price,
          totalDiscount: contract[installmentProp]?.discount,
          shippingPrice: contract[installmentProp]?.shippingPrice,
          intrinsicPrice: contract[installmentProp]?.amountExcludeDiscount,
          totalPriceExcludeVat: contract[installmentProp]?.amount,
          totalVat: contract[installmentProp]?.vat,
          totalPrice: contract[installmentProp]?.amountIncludeVat,
        });
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, contract, setPricing]);

  const acceptDetailHandler = () => {
    setAcceptRentingDetail((prevAccept) => !prevAccept);
  };

  const addFriendBeforeRent = async () => {
    const response = await Swal.fire({
      titleText: "เพิ่ม KIN Companion เป็นเพื่อนก่อน",
      text: "กรุณาคลิกปุ่มเพิ่ม KIN Companion เป็นเพื่อน เพื่อใช้รับการแจ้งเตือนธุรกรรมและข่าวสารที่จำเป็นในอนาคต",
      icon: "info",
      confirmButtonText: "เพิ่ม KIN Companion เป็นเพื่อน",
      width: "75%",
      showCloseButton: true,
      customClass: {
        container: classes.swal2Container,
        confirmButton: classes.swal2ConfirmButton,
      },
    });
    // const isLiff = liff.isInClient();
    if (response.isConfirmed)
      liff.openWindow({
        url: LINE_OA_URL,
        external: true,
      });
  };

  const hasExisitingBooking = async () => {
    const response = await Swal.fire({
      titleText: "ไม่สามารถจองได้",
      text: "ท่านไม่สามารถจองสินค้าเพิ่มได้ เนื่องจากมีสัญญาการจองเดิมซึ่งยังไม่เสร็จสมบูรณ์ที่ดำเนินการค้างไว้อยู่",
      icon: "error",
      confirmButtonText: "ตรวจสอบประวัติการจอง",
      width: "75%",
      showCloseButton: true,
      customClass: {
        container: classes.swal2Container,
        confirmButton: classes.swal2ConfirmButton,
      },
    });
    if (response.isConfirmed) history.push("/booking-history");
  };

  const confirmReservationHandler = () => {
    const { alreadyBook } = user;

    if (!lineProfile.friendFlag) return addFriendBeforeRent();

    if (alreadyBook) return hasExisitingBooking();

    const {
      newVINs,
      installments1,
      installments2,
      promoDiscount,
      ...contractData
    } = contract;

    const body = {
      ...contractData,
      VIN: newVINs,
      installments,
      ...(promoDiscount ? { promoDiscount } : null),
    };

    createBooking(
      {
        endpoint: endpoints.createBooking,
        method: "POST",
        body,
      },
      () => history.push(`/renting/contract-detail/${bookingId}`)
    );
  };

  const cancelReservationHandler = async () => {
    const response = await Swal.fire({
      titleText: "ยกเลิกการจอง",
      text: "ท่านยืนยันที่จะยกเลิกการจองสินค้าที่เลือกมาทั้งหมดหรือไม่",
      icon: "question",
      cancelButtonText: "กลับหน้าเดิม",
      confirmButtonText: "ยืนยัน",
      width: "75%",
      showCloseButton: true,
      showCancelButton: true,
      focusCancel: true,
      reverseButtons: true,
      customClass: {
        container: classes.swal2Container,
        confirmButton: classes.swal2OutlinedConfirmButton,
        cancelButton: classes.swal2CancelButton,
      },
    });
    if (response.isConfirmed)
      cancelBooking({ endpoint: endpoints.cancelBooking(bookingId) }, () => {
        sessionStorage.removeItem(CONFIRMED);
        setUser((prevUserData) => ({ ...prevUserData, alreadyBook: "" }));
        history.push("/");
      });
  };

  const rawPriceLists = useMemo(
    () => [
      {
        id: "totalProductsPrice",
        label: "ค่าเช่าสินค้า",
        value: (
          <Typography
            align={installments ? "right" : "center"}
            sx={{ flex: "1 0 auto", mr: installments ? 4 : 0 }}
          >
            {installments ? (
              pricing?.totalProductsPrice?.toLocaleString()
            ) : (
              <small>เลือกการชำระเงิน</small>
            )}
          </Typography>
        ),
        unit: installments ? "บาท" : null,
      },
      {
        id: "totalDiscount",
        label: "ส่วนลดค่าเช่าสินค้า",
        value: (
          <Typography
            align={installments ? "right" : "center"}
            sx={{ flex: "1 0 auto", mr: installments ? 4 : 0 }}
          >
            {installments ? (
              `- ${pricing?.totalDiscount?.toLocaleString()}`
            ) : (
              <small>เลือกการชำระเงิน</small>
            )}
          </Typography>
        ),
        unit: installments ? "บาท" : null,
      },
      {
        id: "shippingPrice",
        label: "ค่าขนส่ง",
        value: (
          <Typography
            align={installments ? "right" : "center"}
            sx={{ flex: "1 0 auto", mr: installments ? 4 : 0 }}
          >
            {installments ? (
              pricing?.shippingPrice?.toLocaleString()
            ) : (
              <small>เลือกการชำระเงิน</small>
            )}
          </Typography>
        ),
        unit: installments ? "บาท" : null,
      },
      {
        id: "intrinsicPrice",
        label: "มูลค่ารวม",
        value: (
          <Typography
            align={installments ? "right" : "center"}
            sx={{ flex: "1 0 auto", mr: installments ? 4 : 0 }}
          >
            {installments ? (
              pricing?.intrinsicPrice?.toLocaleString()
            ) : (
              <small>เลือกการชำระเงิน</small>
            )}
          </Typography>
        ),
        unit: installments ? "บาท" : null,
      },
    ],
    [pricing, installments]
  );

  const conclusionPriceLists = useMemo(
    () => [
      ...(promoDiscount
        ? [
            {
              id: "totalPriceExcludeVat",
              label: "จำนวนเงินหลังหักส่วนลด",
              value: (
                <Typography
                  align={installments ? "right" : "center"}
                  sx={{ flex: "1 0 auto", mr: installments ? 4 : 0 }}
                >
                  {installments ? (
                    pricing?.totalPriceExcludeVat?.toLocaleString()
                  ) : (
                    <small>เลือกการชำระเงิน</small>
                  )}
                </Typography>
              ),
              unit: installments ? "บาท" : null,
            },
          ]
        : []),
      {
        id: "totalVat",
        label: "ภาษีมูลค่าเพิ่ม 7%",
        value: (
          <Typography
            align={installments ? "right" : "center"}
            sx={{ flex: "1 0 auto", mr: installments ? 4 : 0 }}
          >
            {installments ? (
              pricing?.totalVat?.toLocaleString()
            ) : (
              <small>เลือกการชำระเงิน</small>
            )}
          </Typography>
        ),
        unit: installments ? "บาท" : null,
      },
      {
        id: "totalPrice",
        label: "ยอดที่ต้องชำระรวม",
        value: (
          <Paper
            elevation={0}
            sx={(theme) => ({
              mr: installments ? 2 : 0,
              ml: 1,
              pr: installments ? 2 : 0,
              flex: "1 0 auto",
              bgcolor: theme.palette.primary.light,
              borderRadius: 1,
            })}
          >
            <Typography
              align={installments ? "right" : "center"}
              variant="subtitle1"
            >
              <strong>
                {installments ? (
                  pricing?.totalPrice?.toLocaleString()
                ) : (
                  <small>เลือกการชำระเงิน</small>
                )}
              </strong>
            </Typography>
          </Paper>
        ),
        unit: installments ? "บาท" : null,
      },
    ],
    [pricing, installments, promoDiscount]
  );

  const showNextActionsElements = contract.stage !== STAGES.cancel.nameEN && (
    <Fragment>
      <Grid item xs={12} mb={installments === 2 ? 0 : 2} px={3}>
        <SelectDropdownInput
          control={control}
          defaultValue=""
          label="การชำระเงิน"
          name="installments"
          disabled={!ableToInstallments}
          valueProp="value"
          menuProp="label"
          menuItems={installmentsMenus}
          helperText={installments === 2 && "(รอบแรก 50% รอบที่ 2 50%)"}
          size="medium"
          width={{ label: "40%", input: "60%" }}
        />
      </Grid>
      {installments === 2 && (
        <Grid item xs={12} mb={2} px={3}>
          <Typography color="error" variant="subtitle2">
            <small>
              กรณีแบ่งชำระเป็น 2 งวด มีค่าบริการเพิ่มเติม 3%
              จากการชำระแบบเต็มจำนวน
            </small>
          </Typography>
        </Grid>
      )}
      <Grid
        container
        item
        justifyContent="center"
        xs={12}
        mb={2}
        sx={{ color: (theme) => theme.palette.grey[500] }}
      >
        <FormControlLabel
          label="รายละเอียดการเช่าถูกต้อง"
          control={
            <Checkbox
              checked={acceptRentingDetail}
              onChange={acceptDetailHandler}
              color="primary"
            />
          }
        />
      </Grid>
      <Grid container item justifyContent="space-evenly" xs={12}>
        <Fab
          onClick={cancelReservationHandler}
          variant="extended"
          sx={(theme) => ({
            height: theme.spacing(4),
            color: theme.palette.common.white,
            bgcolor: theme.palette.grey[500],
            boxShadow: theme.shadows[0],
          })}
        >
          ยกเลิกการจอง
        </Fab>
        <Fab
          onClick={confirmReservationHandler}
          disabled={!ableToConfirmRenting}
          variant="extended"
          color="secondary"
          sx={(theme) => ({
            height: theme.spacing(4),
            "&.Mui-disabled": {
              color: theme.palette.grey[500],
              bgcolor: theme.palette.grey[300],
            },
          })}
        >
          ยืนยันการจอง
        </Fab>
      </Grid>
    </Fragment>
  );

  return (
    <Fragment>
      <ScrollToTop />
      <Grid container p={2} sx={{ flexGrow: 1 }}>
        <Grid item xs={12}>
          <OutlinedChip
            color="primary"
            label="สรุปรายละเอียดสินค้าที่เช่า"
            sx={{ width: "fit-content" }}
          />
        </Grid>
        <Grid container item xs={12} py={2}>
          <Typography
            variant="subtitle2"
            sx={{ flexBasis: "auto", mt: 1, mr: 1, ml: 3 }}
          >
            ผู้เช่า
          </Typography>
          <Paper
            elevation={0}
            sx={(theme) => ({
              flex: "1 0 auto",
              bgcolor: theme.palette.grey[100],
              borderRadius: theme.spacing(0.5),
              py: 1,
              px: 4,
            })}
          >
            คุณ {contract.name} {contract.surname}
          </Paper>
        </Grid>
        <Grid container direction="column" item xs={12}>
          {contract?.VIN?.length > 0 &&
            contract.VIN.map((product) => (
              <Grid
                key={product.VIN}
                container
                item
                alignItems="center"
                sx={(theme) => ({
                  mb: 1,
                  "&:not(:last-child)": {
                    borderBottom: `1px solid ${theme.palette.grey[300]}`,
                    mb: 2,
                  },
                })}
              >
                <Grid item xs={5} pr={2}>
                  <ImgProduct
                    src={product.image}
                    alt="selected-product__img"
                    sx={{ mb: 1, ml: 2 }}
                  />
                </Grid>
                <Grid item xs={7} pl={2}>
                  <Typography color="primary" variant="subtitle2">
                    {product.nameTH} รุ่น {product.model}
                  </Typography>
                </Grid>
              </Grid>
            ))}
        </Grid>
        <Grid item xs={12} mb={2}>
          <DateTimeDescriptionCard
            startText="วันที่รับ"
            endText="วันที่คืน"
            startValue={contract.startdate || ""}
            endValue={contract.enddate || ""}
          />
        </Grid>
        {rawPriceLists.map((price, idx, array) => (
          <Grid
            key={price.id}
            container
            item
            alignItems="center"
            xs={12}
            mx={3}
            mb={1}
          >
            <Typography variant="subtitle2" sx={{ flexBasis: "50%" }}>
              {price.label}
            </Typography>
            {price.value}
            {price.unit && (
              <Typography variant="subtitle2">{price.unit}</Typography>
            )}
          </Grid>
        ))}
        {promoDiscount && (
          <Grid item xs={12} mb={1}>
            <HighlightPromotionCard
              title="ส่วนลดของ KIN-RENT"
              discountLists={promoDiscount?.promotions}
              totalDiscount={promoDiscount?.total}
            />
          </Grid>
        )}
        {conclusionPriceLists.map((price, idx, array) => (
          <Grid
            key={price.id}
            container
            item
            alignItems="center"
            xs={12}
            mx={3}
            mb={array.length - 1 === idx ? 3 : 1}
          >
            <Typography variant="subtitle2" sx={{ flexBasis: "50%" }}>
              {price.label}
            </Typography>
            {price.value}
            {price.unit && (
              <Typography variant="subtitle2">{price.unit}</Typography>
            )}
          </Grid>
        ))}
        {showNextActionsElements}
      </Grid>

      <ActionLoading open={isSubmitting}>
        <Typography
          textAlign="center"
          variant="h5"
          sx={{
            color: (theme) => theme.palette.primary.light,
          }}
        >
          กำลังบันทึกข้อมูล
        </Typography>
      </ActionLoading>
    </Fragment>
  );
};

export default RentingSummary;

import React from "react";
import PropTypes from "prop-types";

import { Paper, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import OutlinedFab from "base-components/OutlinedFab";

const Content = styled(Paper)(({ theme }) => ({
  background: theme.palette.grey[300],
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(1, 1.5),
  flex: "1 0 100%",
  width: "80%",
}));

const PaymentStepContent = ({
  dueDate,
  paymentRound,
  onClickButton,
  onClickLink,
}) => {
  const paymentRoundText = paymentRound || "";

  return (
    <Content elevation={0}>
      ชำระค่าเช่า{paymentRoundText}
      <Typography
        color="secondary"
        variant="subtitle2"
        sx={{ textIndent: 8, mb: 1 }}
      >
        จ่ายผ่านบัญชี
        <Typography
          component="span"
          variant="subtitle2"
          sx={{ mx: 1, color: (theme) => theme.palette.common.black }}
        >
          บริษัท เกษตรอินโน จำกัด ธ.ไทยพาณิชย์ เลขที่บัญชี 314-303-1413 หรือ
        </Typography>
        <OutlinedFab
          onClick={onClickButton}
          color="secondary"
          variant="extended"
        >
          <Typography variant="subtitle2">สแกนจ่าย</Typography>
        </OutlinedFab>
      </Typography>
      <Typography variant="subtitle2" sx={{ mb: 2 }}>
        กรุณาชำระก่อนวันที่ {dueDate}
      </Typography>
      <Typography
        component="small"
        variant="subtitle2"
        sx={{ mb: 1, fontSize: ".75rem" }}
      >
        หมายเหตุ: กรุณาตรวจสอบความถูกต้องของรายละเอียดการเช่า
        หากยกเลิกจะมีค่าดำเนินการ 10% ของราคาค่าเช่า
      </Typography>
      <Button color="secondary" onClick={onClickLink} sx={{ ml: "auto" }}>
        <Typography variant="subtitle2">{`แจ้งการโอนเงิน >>`}</Typography>
      </Button>
    </Content>
  );
};

PaymentStepContent.propTypes = {
  dueDate: PropTypes.node.isRequired,
  paymentRound: PropTypes.string,
  onClickCancel: PropTypes.func,
};

export default PaymentStepContent;

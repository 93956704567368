import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";
import { useHistory } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { format, parseISO } from "date-fns";
import Swal from "sweetalert2";

import {
  Grid,
  FormControlLabel,
  Checkbox,
  Fab,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useHttp, useDate } from "hooks";
import AuthContext from "stores/Authentication/auth-context";
import LineContext from "stores/LineLogin/line-context";
import {
  endpoints,
  LINE_OA_URL,
  FILTER,
  SELECTED_PRODUCTS,
  CONFIRMED,
} from "utils/Config";
import { DATE_FORMAT, MIN_RENTING_DAYS } from "constants/DateTime";
import ContentPDPA from "pages/Templates/ContentPDPA";
import {
  Modal,
  OutlinedChip,
  ActionLoading,
  ScrollToTop,
} from "base-components";
import {
  Input,
  DatePickerInput,
  SelectDropdownInput,
} from "components/HookForms";

const { liff } = window;

const useStyles = makeStyles((theme) => ({
  swal2Container: {
    zIndex: theme.zIndex.appBar + 1,
  },
  swal2ConfirmButton: {
    "&.swal2-confirm": {
      width: "100%",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.main,
      border: `1px solid ${theme.palette.grey[500]}`,
      fontFamily: "Prompt",
      fontSize: "0.875rem",
      fontWeigth: 400,
      lineHeight: 1.43,
      borderRadius: "1rem",
    },
  },
}));

yup.addMethod(yup.string, "citizenIdFormat", function (errorMessage) {
  return this.test("test-citizen-id-format", errorMessage, function (value) {
    const { path, createError } = this;
    let sum = 0;
    for (let i = 0; i < 12; i++) sum += +value?.charAt(i) * (13 - i);
    return (
      (11 - (sum % 11)) % 10 === +value?.charAt(12) ||
      createError({ path, message: errorMessage })
    );
  });
});

const validationSchema = yup.object().shape({
  startdate: yup.date().typeError("กรุณาเลือก วันที่เริ่มใช้งาน"),
  enddate: yup.date().typeError("กรุณาเลือก วันที่คืนเครื่องจักร"),
  firstName: yup.string().required("กรุณากรอก ชื่อ"),
  lastName: yup.string().required("กรุณากรอก นามสกุล"),
  cid: yup
    .string()
    .required("กรุณากรอก เลขบัตรประจำตัวประชาชน")
    .matches(
      /* eslint-disable-next-line no-useless-escape */
      /^-?(0|INF|(0[1-7][0-7]*)|(0x[0-9a-fA-F]+)|((0|[1-9][0-9]*|(?=[\.,]))([\.,][0-9]+)?([eE]-?\d+)?))$/,
      "กรุณากรอกเฉพาะตัวเลข"
    )
    .length(13, "จำนวนหลักทั้งหมด 13 หลักเท่านั้น")
    .citizenIdFormat("เลขบัตรประจำตัวประชาชนไม่ถูกต้อง"),
  tel: yup
    .string()
    .length(10, "จำนวนหลักเท่ากับ 10 หลักเท่านั้น")
    .matches(
      /* eslint-disable-next-line no-useless-escape */
      /((\+66|0)(\d{1,2}\-?\d{3}\-?\d{3,4}))|((\+๖๖|๐)([๐-๙]{1,2}\-?[๐-๙]{3}\-?[๐-๙]{3,4}))/gm,
      "เบอร์โทรศัพท์ไม่ถูกต้อง"
    )
    .required("กรุณากรอก เบอร์โทรศัพท์"),
  address_del: yup.string().required("กรุณากรอก ที่อยู่ที่ต้องการให้จัดส่ง"),
  province_del: yup.string().required("กรุณากรอก จังหวัดที่ต้องการให้จัดส่ง"),
  district_del: yup.string().required("กรุณากรอก อำเภอที่ต้องการให้จัดส่ง"),
  subdistrict_del: yup.string().required("กรุณากรอก ตำบลที่ต้องการให้จัดส่ง"),
  zipcode_del: yup
    .string()
    .required("กรุณากรอก รหัสไปรษณีย์ที่ต้องการให้จัดส่ง"),
  address: yup.string().required("กรุณากรอก ที่อยู่"),
  province: yup.string().required("กรุณากรอก จังหวัด"),
  district: yup.string().required("กรุณากรอก อำเภอ"),
  subdistrict: yup.string().required("กรุณากรอก ตำบล"),
  zipcode: yup.string().required("กรุณากรอก รหัสไปรษณีย์"),
});

const ContractFormPage = (props) => {
  const classes = useStyles();

  const history = useHistory();

  const { lineProfile } = useContext(LineContext);
  const { user, setUser } = useContext(AuthContext);
  const lastParagraphRef = useRef(null);

  const { mainProduct: localMainProduct, implements: localImplements } =
    JSON.parse(localStorage.getItem(SELECTED_PRODUCTS));

  const { control, watch, setValue, clearErrors, handleSubmit } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const [startDate, endDate, ...delivFields] = useWatch({
    control,
    name: [
      "startdate",
      "enddate",
      "address_del",
      "province_del",
      "district_del",
      "subdistrict_del",
      "zipcode_del",
    ],
  });
  const { sendRequest: fetchHttpData } = useHttp();
  const { isLoading: isSubmitting, sendRequest: submitHttpFormData } =
    useHttp();
  const { isLoading: isAccepting, sendRequest: submitHttpAcceptPDPA } =
    useHttp();
  const {
    disabledStart,
    disabledEnd,
    pickedDays,
    defaultStartMonth,
    defaultEndMonth,
  } = useDate({
    startDate,
    endDate,
  });

  const [provinceChoices, setProvinceChoices] = useState([]);
  const [districtChoices, setDistrictChoices] = useState([]);
  const [subDistrictChoices, setSubDistrictChoices] = useState([]);
  const [deliDistrictChoices, setDeliDistrictChoices] = useState([]);
  const [deliSubDistrictChoices, setDeliSubDistrictChoices] = useState([]);

  const [sameDeliveryData, setSameDeliveryData] = useState({
    status: false,
    isDirty: false,
  });

  const [openPDPAModal, setOpenPDPAModal] = useState(false);
  const [disabledAcceptPDPA, setDisabledAcceptPDPA] = useState(true);

  useEffect(() => {
    const abortCont = new AbortController();
    const { signal } = abortCont;

    const { start_date, end_date, province_name } = JSON.parse(
      localStorage.getItem(FILTER)
    );
    setValue("startdate", parseISO(start_date));
    setValue("enddate", parseISO(end_date));

    const setFormData = ({ province: provinces }) => {
      setProvinceChoices(provinces);
      setValue("province_del", province_name);
    };

    const fetchData = (signal) => {
      fetchHttpData({ endpoint: endpoints.geo(), signal }, setFormData);
    };

    fetchData(signal);

    window.addEventListener("storage", fetchData);

    return () => {
      window.removeEventListener("storage", fetchData);
      abortCont.abort();
    };
  }, [fetchHttpData, setValue]);

  useEffect(() => {
    if (!sameDeliveryData.isDirty && !sameDeliveryData.status) {
      setValue("firstName", user.name);
      setValue("lastName", user.surname);
      setValue("cid", user.cid);
      setValue("tel", user.tel);
      setValue("address", user.address);
      if (provinceChoices.length > 0 && user.province) {
        setValue("province", user.province);
        const selectedProvince = provinceChoices.find(
          (provinceChoice) => provinceChoice.Province === user.province
        );
        setDistrictChoices(selectedProvince.District);
        setValue("district", user.district);
        if (selectedProvince.District.length > 0 && user.subdistrict) {
          const selectedDistrict = selectedProvince.District.find(
            (districtChoice) => districtChoice.District === user.district
          );
          setSubDistrictChoices(selectedDistrict.SubDistrict);
          setValue("subdistrict", user.subdistrict);
        }
      }
      setValue("zipcode", user.zipcode);
    }
  }, [setValue, user, sameDeliveryData, provinceChoices]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      /* [Delivery location] set district from selected province */
      const selectedDeliProvince = provinceChoices.find(
        (province) => province.Province === value.province_del
      );
      setDeliDistrictChoices(selectedDeliProvince?.District || []);

      /* [Delivery location] district is changed */
      if (name === "district_del" && type === "change") {
        setValue("subdistrict_del", "");
        if (value.province_del && value.district_del) {
          const selectedDeliDistrict = deliDistrictChoices.find(
            (district) => district.District === value.district_del
          );
          setDeliSubDistrictChoices(selectedDeliDistrict.SubDistrict);
          setValue("zipcode_del", selectedDeliDistrict.SubDistrict[0].zip);
          clearErrors("zipcode_del");
        } else {
          setDeliSubDistrictChoices([]);
          setValue("zipcode_del", "");
        }
      }

      if (!sameDeliveryData.status && type === "change") {
        /* [Invoice address] province is changed */
        if (name === "province") {
          setValue("district", "");
          setValue("subdistrict", "");
          setValue("zipcode", "");
          if (value.province) {
            const selectedProvince = provinceChoices.find(
              (province) => province.Province === value.province
            );
            setDistrictChoices(selectedProvince.District);
          } else {
            setDistrictChoices([]);
            setSubDistrictChoices([]);
          }
        }

        /* [Invoice address] district is changed */
        if (name === "district") {
          setValue("subdistrict", "");
          if (value.province && value.district) {
            const selectedDistrict = districtChoices.find(
              (district) => district.District === value.district
            );
            setSubDistrictChoices(selectedDistrict.SubDistrict);
            setValue("zipcode", selectedDistrict.SubDistrict[0].zip);
            clearErrors("zipcode");
          } else {
            setSubDistrictChoices([]);
            setValue("zipcode", "");
          }
        }
      }

      /* Same Delivery & Renter location */
      if (sameDeliveryData.status && type === "change") {
        const nameNoDel = name.substring(0, name.indexOf("_"));
        setValue(nameNoDel, value[name]);
        clearErrors(nameNoDel);
        if (name === "district_del" && value.district_del) {
          setValue("subdistrict", "");
          const selectedDeliDistrict = deliDistrictChoices.find(
            (district) => district.District === value.district_del
          );
          setSubDistrictChoices(selectedDeliDistrict.SubDistrict);
          setValue("zipcode", selectedDeliDistrict.SubDistrict[0].zip);
          clearErrors("zipcode");
        }
        if (name === "district_del" && !value.district_del) {
          setValue("subdistrict", "");
          setSubDistrictChoices([]);
          setValue("zipcode", "");
        }
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [
    watch,
    setValue,
    clearErrors,
    provinceChoices,
    districtChoices,
    deliDistrictChoices,
    sameDeliveryData,
  ]);

  const toggleSameDelivery = (e) => {
    const fields = [
      "address",
      "province",
      "district",
      "subdistrict",
      "zipcode",
    ];
    setSameDeliveryData((prevSameDeliveryData) => {
      if (prevSameDeliveryData.status) {
        setDistrictChoices([]);
        setSubDistrictChoices([]);
        for (const field of fields) setValue(field, "");
      } else {
        setDistrictChoices(deliDistrictChoices);
        setSubDistrictChoices(deliSubDistrictChoices);
        for (const [i, delivField] of delivFields.entries()) {
          if (delivField) {
            setValue(fields[i], delivField);
            clearErrors(fields[i]);
          } else setValue(fields[i], "");
        }
      }
      return { status: e.target.checked, isDirty: true };
    });
  };

  const addFriendBeforeRent = async () => {
    const response = await Swal.fire({
      titleText: "เพิ่ม KIN Companion เป็นเพื่อนก่อน",
      text: "กรุณาคลิกปุ่มเพิ่ม KIN Companion เป็นเพื่อน เพื่อใช้รับการแจ้งเตือนธุรกรรมและข่าวสารที่จำเป็นในอนาคต",
      icon: "info",
      confirmButtonText: "เพิ่ม KIN Companion เป็นเพื่อน",
      width: "75%",
      showCloseButton: true,
      customClass: {
        container: classes.swal2Container,
        confirmButton: classes.swal2ConfirmButton,
      },
    });
    // const isLiff = liff.isInClient();
    if (response.isConfirmed)
      liff.openWindow({
        url: LINE_OA_URL,
        external: true,
      });
  };

  const submitFormContract = async (data) => {
    const startdate = format(startDate, DATE_FORMAT);
    const enddate = format(endDate, DATE_FORMAT);
    let VIN = [{ VIN: localMainProduct.VIN }];
    if (localImplements.length > 0) {
      for (const implement of localImplements) {
        VIN.push({ VIN: implement.VIN });
      }
    }
    const localFilter = JSON.parse(localStorage.getItem(FILTER));
    const body = {
      name: data.firstName,
      surname: data.lastName,
      cid: data.cid,
      tel: data.tel,
      address: data.address,
      province: data.province,
      district: data.district,
      subdistrict: data.subdistrict,
      zipcode: data.zipcode,
      ad_name: localMainProduct.ad_name,
      address_del: data.address_del,
      province_del: data.province_del,
      district_del: data.district_del,
      subdistrict_del: data.subdistrict_del,
      zipcode_del: data.zipcode_del,
      startdate: startdate,
      enddate: enddate,
      VIN,
    };

    submitHttpFormData(
      {
        endpoint: endpoints.draftBooking(undefined),
        method: "POST",
        body,
      },
      (responseData) => {
        localStorage.setItem(
          FILTER,
          JSON.stringify({
            ...localFilter,
            start_date: data.startdate,
            end_date: data.enddate,
            province: data.province,
          })
        );
        sessionStorage.setItem(
          CONFIRMED,
          JSON.stringify({ bookNo: responseData.book_no })
        );

        if (!user.pdpa_accept) return setOpenPDPAModal(true);

        if (!lineProfile.friendFlag) return addFriendBeforeRent();

        return history.push("/renting/condition");
      }
    );
  };

  const submitWithError = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const closePDPAModal = () => {
    setDisabledAcceptPDPA(true);
    setOpenPDPAModal(false);
  };

  const acceptPDPAHandler = () => {
    submitHttpAcceptPDPA({ endpoint: endpoints.acceptPDPA }, () => {
      setUser((prevUser) => ({ ...prevUser, pdpa_accept: true }));
      closePDPAModal();
      if (!lineProfile.friendFlag) return addFriendBeforeRent();
      return history.push("/renting/condition");
    });
  };

  const scrollPDPAContentHandler = () => {
    const contentObserver = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setDisabledAcceptPDPA(false);
        } else setDisabledAcceptPDPA(true);
      },
      {
        root: null,
        threshold: 0,
        rootMargin: "0px",
      }
    );

    contentObserver.observe(lastParagraphRef.current);
  };

  const contractFormGroup = [
    {
      id: 1,
      name: "firstName",
      label: "ชื่อ",
      type: "text",
      variant: "filled",
      sx: { mb: 1 },
    },
    {
      id: 2,
      name: "lastName",
      label: "นามสกุล",
      type: "text",
      variant: "filled",
      sx: { mb: 1 },
    },
    {
      id: 3,
      name: "cid",
      label: "เลขบัตรประจำตัวประชาชน",
      type: "text",
      variant: "filled",
      width: {
        label: "40%",
        input: "60%",
      },
      sx: { mb: 1 },
    },
    {
      id: 4,
      name: "tel",
      label: "เบอร์โทร",
      type: "tel",
      variant: "filled",
      sx: { mb: 1 },
    },
  ];

  const deliveryDetailsFormGroup = [
    {
      id: 5,
      variant: "filled",
      name: "address_del",
      label: "ที่อยู่",
      type: "text",
      multiline: true,
      rows: 3,
      sx: { mb: 1 },
    },
    {
      id: 6,
      variant: "filled",
      name: "province_del",
      label: "จังหวัด",
      firstMenuItem: { label: "เลือก จังหวัด", value: "" },
      menuItems: provinceChoices || [],
      menuProp: "Province",
      valueProp: "Province",
      disabled: true,
      width: { label: "26.5%", input: "73.5%" },
      sx: { mb: 1 },
    },
    {
      id: 7,
      variant: "filled",
      name: "district_del",
      label: "อำเภอ",
      firstMenuItem: { label: "เลือก อำเภอ", value: "" },
      menuItems: deliDistrictChoices || [],
      menuProp: "District",
      valueProp: "District",
      width: { label: "26.5%", input: "73.5%" },
      sx: { mb: 1 },
    },
    {
      id: 8,
      variant: "filled",
      name: "subdistrict_del",
      label: "ตำบล",
      firstMenuItem: { label: "เลือก ตำบล", value: "" },
      menuItems: deliSubDistrictChoices || [],
      menuProp: "SubDistrict",
      valueProp: "SubDistrict",
      width: { label: "26.5%", input: "73.5%" },
      sx: { mb: 1 },
    },
    {
      id: 9,
      variant: "filled",
      name: "zipcode_del",
      label: "รหัสไปรษณีย์",
      type: "text",
      sx: { mb: 1 },
    },
  ];

  const vatAddressFormGroup = [
    {
      id: 10,
      variant: "filled",
      name: "address",
      label: "ที่อยู่",
      type: "text",
      multiline: true,
      rows: 3,
      disabled: sameDeliveryData.status,
      sx: { mb: 1 },
    },
    {
      id: 11,
      variant: "filled",
      name: "province",
      label: "จังหวัด",
      firstMenuItem: { label: "เลือก จังหวัด", value: "" },
      menuItems: provinceChoices || [],
      menuProp: "Province",
      valueProp: "Province",
      disabled: sameDeliveryData.status,
      width: { label: "26.5%", input: "73.5%" },
      sx: { mb: 1 },
    },
    {
      id: 12,
      variant: "filled",
      name: "district",
      label: "อำเภอ",
      firstMenuItem: { label: "เลือก อำเภอ", value: "" },
      menuItems: districtChoices || [],
      menuProp: "District",
      valueProp: "District",
      disabled: sameDeliveryData.status,
      width: { label: "26.5%", input: "73.5%" },
      sx: { mb: 1 },
    },
    {
      id: 13,
      variant: "filled",
      name: "subdistrict",
      label: "ตำบล",
      firstMenuItem: { label: "เลือก ตำบล", value: "" },
      menuItems: subDistrictChoices || [],
      menuProp: "SubDistrict",
      valueProp: "SubDistrict",
      disabled: sameDeliveryData.status,
      width: { label: "26.5%", input: "73.5%" },
      sx: { mb: 1 },
    },
    {
      id: 14,
      variant: "filled",
      name: "zipcode",
      label: "รหัสไปรษณีย์",
      type: "text",
      disabled: sameDeliveryData.status,
      sx: { mb: 1 },
    },
  ];

  return (
    <Fragment>
      <ScrollToTop />
      <form onSubmit={handleSubmit(submitFormContract, submitWithError)}>
        <Grid container py={2} px={1}>
          <Grid container item xs={12} mb={2}>
            <OutlinedChip
              color="primary"
              label="ข้อมูลการเช่าสินค้า"
              sx={{ width: "50%" }}
            />
          </Grid>
          <Grid container item direction="column" xs={12} pr={1} mb={3}>
            <DatePickerInput
              name="startdate"
              control={control}
              defaultValue=""
              label="วันที่เริ่มต้น"
              toolbarTitle="เลือก วันที่เริ่มต้นใช้งาน"
              variant="filled"
              shouldDisableDate={disabledStart}
              defaultMonth={defaultStartMonth()}
              renderDay={pickedDays}
              showIcon
              showErrorText
              width={{ label: "26.5%", input: "73.5%" }}
              sx={{ mb: 1.5 }}
            />
            <DatePickerInput
              name="enddate"
              control={control}
              defaultValue=""
              label="วันที่คืน"
              toolbarTitle="เลือก วันที่คืนเครื่องจักร"
              variant="filled"
              shouldDisableDate={disabledEnd}
              defaultMonth={defaultEndMonth(MIN_RENTING_DAYS)}
              disabled={!startDate}
              renderDay={pickedDays}
              showIcon
              showErrorText
              width={{ label: "26.5%", input: "73.5%" }}
              sx={{ mb: 1 }}
            />
          </Grid>

          <Grid container item xs={12} mb={2}>
            <OutlinedChip color="primary" label="ข้อมูลผู้เช่าสินค้า" />
          </Grid>
          <Grid item xs={12} mb={2}>
            {contractFormGroup.map((form) => (
              <Input
                key={form.id}
                control={control}
                defaultValue=""
                {...form}
              />
            ))}
          </Grid>

          <Grid container item xs={12} mb={2}>
            <Typography variant="subtitle2">ที่อยู่ในการจัดส่งรถ</Typography>
          </Grid>
          <Grid item xs={12} mb={3}>
            {deliveryDetailsFormGroup.map((form) => {
              return form.menuItems ? (
                <SelectDropdownInput
                  key={form.id}
                  control={control}
                  defaultValue=""
                  {...form}
                />
              ) : (
                <Input
                  key={form.id}
                  control={control}
                  defaultValue=""
                  {...form}
                />
              );
            })}
          </Grid>

          <Grid container item xs={12} mb={2}>
            <Grid item xs="auto" my="auto" mr={3}>
              <Typography variant="subtitle2" sx={{ width: "auto" }}>
                ที่อยู่สำหรับออกใบกำกับภาษี
              </Typography>
            </Grid>
            <Grid item xs my="auto">
              <FormControlLabel
                label={
                  <Typography variant="subtitle2" sx={{ fontSize: ".75rem" }}>
                    ใช้ที่อยู่เดียวกับที่อยู่จัดส่ง
                  </Typography>
                }
                control={
                  <Checkbox
                    checked={sameDeliveryData.status}
                    onChange={toggleSameDelivery}
                    name="checkSameRenterData"
                    color="primary"
                  />
                }
              />
            </Grid>
          </Grid>
          <Grid className="pb-3" item xs={12}>
            {vatAddressFormGroup.map((form) => {
              return form.menuItems ? (
                <SelectDropdownInput
                  key={form.id}
                  control={control}
                  defaultValue=""
                  {...form}
                />
              ) : (
                <Input
                  key={form.id}
                  control={control}
                  defaultValue=""
                  {...form}
                />
              );
            })}
          </Grid>

          <Grid className={classes["agreed-paragraph"]} item xs={12} my={3}>
            <Typography
              variant="caption"
              sx={{
                color: (theme) => theme.palette.grey[500],
                fontSize: "x-small",
                lineHeight: "10%",
              }}
            >
              ท่านได้อ่านทำความเข้าใจ นโยบายคุ้มครองข้อมูลส่วนบุคคล
              และยินยอมให้บริษัทเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลของท่าน
              (รวมทั้งข้อมูลส่วนบุคคลที่มีความอ่อนไหว
              กรณีที่ท่านได้ให้ไว้กับบริษัท) พร้อมทั้งทราบดี
              ถึงผลกระทบที่อาจเกิดขึ้นหากปฏิเสธการให้
              ข้อมูลส่วนบุคคลตามที่ระบุในนโยบายดังกล่าว
              โดยบริษัทอาจเปิดเผยข้อมูลดังกล่าวให้แก่บริษัท ในเครือ
              ผู้แทนจำหน่าย ผู้ให้บริการ หรือ บุคคลใด
              ๆเฉพาะที่เกี่ยวข้องเพื่อวัตถุประสงค์ ในลักษณะเดียวกันนี้ ทั้งนี้
              ตามรายละเอียดที่ระบุไว้ในนโยบายดังกล่าว >>
            </Typography>
          </Grid>
          <Grid container item justifyContent="center" xs={12}>
            <Fab
              color="secondary"
              variant="extended"
              type="submit"
              sx={{ height: (theme) => theme.spacing(4) }}
            >
              ยืนยันการจอง
            </Fab>
          </Grid>
        </Grid>
      </form>

      <Modal
        openModal={openPDPAModal}
        onCloseModal={closePDPAModal}
        onScrollContent={scrollPDPAContentHandler}
        titleWithClose
        dialogTitle={
          <Fragment>
            <Typography
              component="div"
              align="center"
              variant="subtitle2"
              sx={{ mr: 4, mb: 1 }}
            >
              <strong>นโยบายคุ้มครองข้อมูลส่วนบุคคลและแบบความยินยอม</strong>
            </Typography>
            <Typography
              component="div"
              align="center"
              variant="subtitle2"
              sx={{ mr: 4 }}
            >
              <strong>บริษัท เกษตรอินโน จำกัด</strong>
            </Typography>
          </Fragment>
        }
        dividers
        footerChildren={
          <Grid container item justifyContent="space-evenly">
            <Fab onClick={closePDPAModal} variant="extended">
              ปฏิเสธ
            </Fab>
            <Fab
              disabled={disabledAcceptPDPA}
              color="secondary"
              onClick={acceptPDPAHandler}
              variant="extended"
            >
              ยินยอม
            </Fab>
          </Grid>
        }
        sx={(theme) => ({
          "& .MuiTypography-root": {
            color: theme.palette.common.black,
          },
          "& .MuiFab-root": {
            display: "flex",
            width: theme.spacing(14),
            height: theme.spacing(4),
            margin: theme.spacing(1, "auto"),
          },
        })}
      >
        <ContentPDPA ref={lastParagraphRef} />
      </Modal>

      <ActionLoading open={isSubmitting || isAccepting}>
        <Typography
          textAlign="center"
          variant="h5"
          sx={{
            color: (theme) => theme.palette.primary.light,
          }}
        >
          กำลังบันทึกข้อมูล
        </Typography>
      </ActionLoading>
    </Fragment>
  );
};

export default ContractFormPage;

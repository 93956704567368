import React, { Fragment, useState } from "react";
import clsx from "clsx";

import {
  Grid,
  Card as MuiCard,
  CardContent,
  CardActions,
  Paper,
  Typography,
  Divider,
  Checkbox,
  IconButton,
  Collapse,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import {
  ExpandMore as ExpandMoreIcon,
  RadioButtonUncheckedRounded as RadioButtonUncheckedRoundedIcon,
  CheckCircleRounded as CheckCircleRoundedIcon,
} from "@mui/icons-material";

const Card = styled(MuiCard)(({ theme }) => ({
  backgroundColor: theme.palette.grey[300],
}));

const ImageProduct = styled("img", {
  shouldForwardProp: (prop) => prop !== "sx",
})(({ theme }) => ({
  objectFit: "contain",
  overflow: "hidden",
  borderRadius: theme.shape.borderRadius,
}));

const useStyles = makeStyles((theme) => {
  return {
    column__description: {
      display: "flex",
      justifyContent: "flex-start",
      "& .MuiGrid": {
        "&-root": {
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        },
      },
    },
    column__price: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-end",
      paddingTop: (selectList) => (selectList ? theme.spacing(2) : ""),
      "& :not(:last-child)": {
        marginBottom: theme.spacing(0.5),
      },
      "& .MuiPaper": {
        "&-root": {
          color: theme.palette.secondary.main,
          padding: theme.spacing(0, 0.5),
          marginRight: theme.spacing(0.5),
          boxShadow: "none",
          backgroundColor: "#F9F9F9",
          fontSize: "0.75rem",
        },
      },
      "& .checkbox": {
        position: "absolute",
        right: theme.spacing(-2.5),
        top: theme.spacing(-2.5),
        "& .selected": {
          "&__unchecked": {
            color: theme.palette.primary.main,
          },
          "&__checked": {
            color: theme.palette.primary.main,
          },
        },
      },
      "& .Mui-disabled": {
        padding: 0,
        "&.checkbox": {
          right: -11,
          top: -11,
          "& .selected": {
            "&__unchecked": {
              color: "rgba(0,0,0,.26)",
            },
          },
        },
      },
    },

    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    "layout__more-img": {
      padding: theme.spacing(1.5, 0),
      display: "flex",
      columnGap: theme.spacing(1),
      justifyContent: "center",
      alignItems: "flex-start",
    },
    "product__more-img": {
      // width: theme.spacing(9),
      [theme.breakpoints.up("md")]: {
        width: theme.spacing(40),
      },
      [theme.breakpoints.down("md")]: {
        width: theme.spacing(30),
      },
    },
  };
});

const MachineList = ({
  className,
  vin,
  name,
  model,
  price,
  mainImgUrl,
  moreDetail,
  moreImages,
  excludeShipping,
  onClickList,
  selectList = false,
  onSelectList,
  selected,
  isDisabledSelect,
  ...props
}) => {
  const classes = useStyles(selectList);

  const [expanded, setExpanded] = useState(false);

  const handleClickList = (vin, price) => () => {
    onClickList(vin, price);
  };

  const handleSelectList = (vin) => (e) => {
    onSelectList(vin, e.target.checked);
  };

  const handleToggleExpandClick = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const moreDetailLink = moreDetail && (
    <Typography
      color="secondary"
      variant="caption"
    >{`ดูเพิ่มเติม>>`}</Typography>
  );
  const checkboxSelectList = selectList && (
    <Checkbox
      className="checkbox"
      disabled={isDisabledSelect}
      icon={<RadioButtonUncheckedRoundedIcon className="selected__unchecked" />}
      checkedIcon={<CheckCircleRoundedIcon className="selected__checked" />}
      onChange={handleSelectList(vin)}
      checked={selected || false}
    />
  );
  const excludeShippingWording = excludeShipping && (
    <Typography variant="caption">(ไม่รวมค่าส่ง)</Typography>
  );
  const expandMoreImages = moreImages?.length && (
    <Fragment>
      <CardActions sx={{ p: 0, alignItems: "flex-end", height: "1rem" }}>
        <IconButton
          className={clsx(classes.expand, { [classes.expandOpen]: expanded })}
          onClick={handleToggleExpandClick}
          aria-expanded={expanded}
          aria-label="show more images"
          size="large"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent sx={{ pt: 0 }}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="caption">รูปภาพสินค้า</Typography>
            </Grid>
            {moreImages.map((image, idx) => (
              <Grid
                className={classes["layout__more-img"]}
                item
                xs={12}
                sm={6}
                key={idx}
              >
                <img
                  className={classes["product__more-img"]}
                  src={image}
                  alt={`${idx}-${vin}__${model}-detail-img`}
                />
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Collapse>
    </Fragment>
  );

  return (
    <Card
      className={`${classes.root} ${className ?? ""}`}
      onClick={handleClickList(vin, price)}
      sx={props.sx}
    >
      <CardContent>
        <Grid container>
          <Grid container item justifyContent="center" xs={3}>
            <ImageProduct
              src={`${mainImgUrl}`}
              alt={`${vin}__${model}-img`}
              sx={(theme) => ({
                width: {
                  xs: theme.spacing(9),
                  nxs: theme.spacing(12),
                  sm: theme.spacing(15),
                },
              })}
            />
          </Grid>
          <Grid className={classes.column__description} item xs={6}>
            <Divider
              orientation="vertical"
              sx={{
                mx: 2,
                width: "1px",
                backgroundColor: (theme) => theme.palette.common.black,
              }}
            />
            <Grid item xs={12}>
              <Typography variant="caption">
                {name} รุ่น {model}
              </Typography>
              {moreDetailLink}
            </Grid>
          </Grid>
          <Grid className={classes.column__price} item xs={3}>
            {checkboxSelectList}
            <div>
              <Paper component="span">
                {price ? price.toLocaleString() : 0}
              </Paper>
              <Typography variant="caption">บาท</Typography>
            </div>

            {excludeShippingWording}
          </Grid>
        </Grid>
      </CardContent>
      {expandMoreImages}
    </Card>
  );
};

export default MachineList;

import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Grid, Typography, Fab } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import useWindowDimensions from "hooks/useWindowDimensions";

import { MAXIMUM_IMPLEMENTS, SELECTED_PRODUCTS } from "utils/Config";
import OutlinedChip from "base-components/OutlinedChip";
import MachineList from "components/MachineList";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      flexGrow: 1,
      rowGap: theme.spacing(1),
      padding: theme.spacing(2),
      "& .implement-list": {
        "&:not(:last-child)": {
          paddingBottom: theme.spacing(1),
        },
      },
    },
    confirm: {
      position: ({ implementProducts }) =>
        implementProducts.length === MAXIMUM_IMPLEMENTS ? "static" : "absolute",
      textAlign: ({ implementProducts }) =>
        implementProducts.length === MAXIMUM_IMPLEMENTS && "center",
      top: ({ height }) => Math.trunc(height * 0.915),
      [theme.breakpoints.up("sm")]: {
        marginTop: theme.spacing(1),
        textAlign: "center",
      },
      "& .MuiFab": {
        "&-extended": {
          width: theme.spacing(15),
          height: theme.spacing(5),
          [theme.breakpoints.up("sm")]: {
            width: theme.spacing(30),
          },
        },
      },
    },
  };
});

const MachineConfirmPage = (props) => {
  // const { userData } = useContext(AuthContext);
  // console.log(userData);
  const { height } = useWindowDimensions();

  const history = useHistory();

  const [mainProduct, setMainProduct] = useState({});
  const [implementProducts, setImplementProducts] = useState([]);

  const classes = useStyles({ height, implementProducts });

  useEffect(() => {
    getSelectedItem();

    window.addEventListener("storage", getSelectedItem);

    return () => {
      window.removeEventListener("storage", getSelectedItem);
    };
  }, []);

  const getSelectedItem = () => {
    const {
      mainProduct: localMainProduct,
      implements: localImplementProducts,
    } = JSON.parse(localStorage.getItem(SELECTED_PRODUCTS));

    if (localMainProduct) {
      setMainProduct(localMainProduct);
    }
    if (localImplementProducts) {
      setImplementProducts(localImplementProducts);
    }
  };

  const clickSeeMoreHandler = (_, __) => {
    return;
  };

  const clickConfirmSelection = () => {
    /* Go next page */
    history.push(`/renting`);
  };

  const mainProductImage = mainProduct.images?.length
    ? mainProduct.images[0].image
    : null;

  return (
    <Grid className={classes.root} container justifyContent="center">
      <Grid className="pb-2" item xs={12}>
        <OutlinedChip
          color="primary"
          label="รายการสินค้าที่ให้บริการ"
          sx={{ width: "50%" }}
        />
      </Grid>
      <Grid className="pl-2" item xs={12}>
        <Typography color="primary" variant="caption">
          {mainProduct.name}
        </Typography>
      </Grid>
      <Grid className="pb-2" item xs={12}>
        <MachineList
          // moreDetail
          name={mainProduct.name}
          model={mainProduct.model}
          price={mainProduct.price}
          mainImgUrl={mainProductImage}
          onClickList={clickSeeMoreHandler}
        />
      </Grid>
      {implementProducts.length > 0 && (
        <Fragment>
          <Grid className="pl-2" item xs={12}>
            <Typography color="primary" variant="caption">
              อุปกรณ์ต่อพ่วง
            </Typography>
          </Grid>
          {implementProducts.map((product, idx) => {
            return (
              <Grid className="implement-list" key={idx} item xs={12}>
                <MachineList
                  // moreDetail
                  name="อุปกรณ์ต่อพ่วง"
                  model={product.model}
                  price={product.price}
                  mainImgUrl={product.mainimage}
                  onClickList={clickSeeMoreHandler}
                />
              </Grid>
            );
          })}
        </Fragment>
      )}

      <Grid className={classes.confirm} item xs={12}>
        <Fab
          color="secondary"
          onClick={clickConfirmSelection}
          variant="extended"
        >
          ยืนยัน
        </Fab>
      </Grid>
    </Grid>
  );
};

export default MachineConfirmPage;

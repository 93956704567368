import PropTypes from "prop-types";

import { Paper, Typography, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
// import makeStyles from "@mui/styles/makeStyles";
import { FindInPageOutlined as FindInPageOutlinedIcon } from "@mui/icons-material";

import { STAGES } from "utils/Config";

const List = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexGrow: 1,
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(0.5),
  paddingLeft: theme.spacing(2),
  color: theme.palette.common.black,
  backgroundColor: theme.palette.common.white,
  "&:first-of-type": {
    marginTop: theme.spacing(1),
  },
  "&:not(:last-child)": {
    marginBottom: theme.spacing(1),
  },
}));

const BookingList = ({ booking, onClickIcon }) => {
  const handleClickIcon = (stage, bookingId) => () => {
    onClickIcon(stage, bookingId);
  };

  return (
    <List elevation={0}>
      <Typography variant="subtitle2">ใบจองเลขที่ {booking.book_no}</Typography>
      <Typography variant="subtitle2" sx={{ ml: "auto", pr: 2 }}>
        {STAGES[booking.stage].nameTH}
      </Typography>
      <IconButton
        onClick={handleClickIcon(booking.stage, booking.book_no)}
        size="large"
        sx={{
          bgcolor: (theme) => theme.palette.grey[500],
          p: 0.5,
        }}
      >
        <FindInPageOutlinedIcon fontSize="small" style={{ color: "#000" }} />
      </IconButton>
    </List>
  );
};

BookingList.propsTypes = {
  booking: PropTypes.shape({
    book_no: PropTypes.string.isRequired,
    stage: PropTypes.string.isRequired,
  }),
};

// BookingList.defaultProps = {
//   booking: {
//     book_no: "000000",
//   },
// };

export default BookingList;

export const MuiDialog = {
  container: {
    "& .MuiPickersToolbar": {
      "&-root": {
        backgroundColor: "#0DB14B",
      },
    },
    "& .MuiPickersDateRangeDay": {
      "&-rangeIntervalDayHighlight": {
        backgroundColor: "rgba(13, 177, 75, .6)",
      },
    },
    "& .MuiPickersDay": {
      "&-root": {
        "&.Mui-selected": {
          backgroundColor: "#0DB14B",
          "&:hover, &:focus": {
            backgroundColor: "#068034",
          },
        },
      },
    },
  },
};

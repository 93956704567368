import { Fragment, forwardRef } from "react";
import PropTypes from "prop-types";
import { Grid, Typography, Divider, Alert as MuiAlert } from "@mui/material";

const AlertBar = forwardRef(function Alert(props, ref) {
  return <MuiAlert ref={ref} elevation={6} {...props} />;
});

const ErrorMessage = ({ title, detail }) => {
  return (
    <Fragment>
      <Grid item xs={12} my={1}>
        <Typography textAlign="center" variant="body1" color="error">
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12} mt={1} mb={2}>
        <AlertBar severity="error">{detail}</AlertBar>
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ bgcolor: (theme) => theme.palette.common.black }} />
      </Grid>
    </Fragment>
  );
};

ErrorMessage.propTypes = {
  title: PropTypes.string.isRequired,
  detail: PropTypes.string.isRequired,
};

export default ErrorMessage;

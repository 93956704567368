import { useState, useEffect, useContext } from "react";

import { useHttp } from "hooks";

import { endpoints } from "utils/Config";
import LineContext from "stores/LineLogin/line-context";
import AuthContext from "./auth-context";

const AuthProvider = (props) => {
  const lineCtx = useContext(LineContext);
  const [user, setUser] = useState({});
  const { sendRequest: fetchHttp } = useHttp();

  useEffect(() => {
    const abortCont = new AbortController();
    const { signal } = abortCont;

    const fetchUserData = (signal) => {
      fetchHttp(
        {
          endpoint: endpoints.user,
          headers: { line_token: lineCtx.lineProfile.accessToken },
          signal,
        },
        (userData) => {
          setUser(userData);
        }
      );
    };

    if (lineCtx?.lineProfile?.accessToken) fetchUserData(signal);

    return () => abortCont.abort();
  }, [fetchHttp, lineCtx]);

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

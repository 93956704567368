import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

import {
  FormControl,
  InputLabel,
  Input,
  OutlinedInput,
  FilledInput,
  FormHelperText,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const InlineFormControl = styled(FormControl, {
  shouldForwardProp: (prop) => prop !== "width" && prop !== "size",
})(({ theme, width, size }) => {
  const isMedium = size === "medium";
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    columnGap: 5,
    "& .MuiFormLabel-root": {
      fontSize: isMedium ? "1rem" : ".75rem",
    },
    "& .MuiInputLabel": {
      "&-formControl": {
        display: "flex",
        justifyContent: "flex-start",
        alignSelf: "flex-start",
        position: "static",
        transform: "translate(0,0)",
        paddingTop: theme.spacing(size === "medium" ? 1.5 : 0.5),
        paddingLeft: 0,
        flex: `1 0 ${width.label}`,
      },
    },
    "& .MuiInputBase-root": {
      flexBasis: width.input,
      fontSize: isMedium ? "1rem" : ".75rem",
      "& .MuiFilledInput": {
        "&-input": {
          paddingTop: theme.spacing(isMedium ? 1.5 : 0.5),
        },
        "&-multiline": {
          paddingTop: theme.spacing(isMedium ? 1.5 : 0.5),
        },
      },
      "& .MuiOutlinedInput": {
        "&-input": {
          paddingTop: theme.spacing(isMedium ? 1.5 : 0.5),
        },
        "&-multiline": {
          paddingTop: theme.spacing(isMedium ? 1.5 : 0.5),
        },
      },
    },
    "& .MuiInputBase-multiline": {
      paddingTop: theme.spacing(isMedium ? 1.5 : 0.5),
      paddingBottom: theme.spacing(isMedium ? 3.5 : 2.75),
    },
  };
});

const InputWithHelperText = styled("div")({
  display: "flex",
  flexDirection: "column",
  flexBasis: "100%",
});

const InlineInput = ({
  control,
  rules,
  defaultValue,
  onChangeInputText,
  ...props
}) => {
  return (
    <Controller
      name={props.name}
      defaultValue={defaultValue}
      control={control}
      rules={rules}
      render={({ field: { name, value, onChange }, fieldState: { error } }) => {
        const onChangeInput = (callback) => (newValue) => {
          if (onChangeInputText)
            return onChangeInputText(name, newValue, callback);
          callback(newValue);
        };

        const errorText = error?.message ? (
          <FormHelperText error={!!error}>{error.message}</FormHelperText>
        ) : null;

        const inputComponent = (variant, childrenProps) => {
          const styles = {
            standard: <Input {...childrenProps} />,
            outlined: <OutlinedInput {...childrenProps} />,
            filled: <FilledInput {...childrenProps} />,
          };
          return styles[variant];
        };

        const inputFormLayout = (style = "default", parentProps, children) => {
          const components = {
            default: (
              <FormControl {...parentProps} error={!!parentProps.error}>
                <InputLabel
                  error={!!parentProps.error}
                  htmlFor={parentProps.htmlFor}
                >
                  {parentProps.label}
                </InputLabel>
                {children}
              </FormControl>
            ),
            inline: (
              <InlineFormControl {...parentProps} error={!!parentProps.error}>
                <InputLabel
                  error={!!parentProps.error}
                  htmlFor={parentProps.htmlFor}
                >
                  {parentProps.label}
                </InputLabel>
                {children}
              </InlineFormControl>
            ),
          };

          return components[style];
        };

        return inputFormLayout(
          props.inline ? "inline" : "default",
          {
            htmlFor: `${name}${props.inline ? "-inline" : ""}__input`,
            variant: props.variant,
            disabled: props.disabled,
            width: props.width,
            size: props.size,
            label: props.label,
            sx: props.sx,
            error,
          },
          props.inline ? (
            <InputWithHelperText>
              {inputComponent(props.variant, {
                id: `${name}-inline__input`,
                multiline: props.multiline,
                rows: props.rows,
                value,
                onChange: onChangeInput(onChange),
                error: !!error,
              })}
              {errorText}
            </InputWithHelperText>
          ) : (
            inputComponent(props.variant, {
              id: `${name}__input`,
              multiline: props.multiline,
              rows: props.rows,
              value,
              onChange: onChangeInput(onChange),
              error: !!error,
            })
          )
        );
      }}
    />
  );
};

InlineInput.propTypes = {
  control: PropTypes.object.isRequired,
  rules: PropTypes.object,
  label: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  type: PropTypes.string.isRequired,
  name: PropTypes.string,
  inline: PropTypes.bool,
  width: PropTypes.shape({
    label: PropTypes.string,
    input: PropTypes.string,
  }),
  variant: PropTypes.oneOf(["standard", "outlined", "filled"]),
};

InlineInput.defaultProps = {
  type: "text",
  disabled: false,
  multiline: false,
  inline: true,
  width: {
    label: "25%",
    input: "75%",
  },
  variant: "standard",
};

export default InlineInput;

import { useReducer } from "react";

import RentingContext from "./renting-context";

const defaultRentingState = {
  userData: {},
  products: {},
};

const rentingReducer = (state, action) => {
  if (action.type === "SAVE_USER") {
    console.log(action.type);
    console.log(state);
    return state;
  }

  if (action.type === "ADD_PRODUCT") {
    const updatedProduct = action.product;
    console.log(state);
    console.log("provider state: ", {
      ...state.userData,
      products: updatedProduct,
    });
    return {
      ...state.userData,
      products: updatedProduct,
    };
  }

  return defaultRentingState;
};

const RentingProvider = (props) => {
  const [rentingState, dispatchRentingStateAction] = useReducer(
    rentingReducer,
    defaultRentingState
  );

  const saveUserDataHandler = (userData) => {
    dispatchRentingStateAction({ type: "SAVE_USER", user: userData });
  };

  const addProductDataHandler = (product) => {
    dispatchRentingStateAction({ type: "ADD_PRODUCT", product: product });
  };

  const rentingContext = {
    userData: rentingState.userData,
    products: rentingState.product,
    saveUserData: saveUserDataHandler,
    addProducts: addProductDataHandler,
  };

  return (
    <RentingContext.Provider value={rentingContext}>
      {props.children}
    </RentingContext.Provider>
  );
};

export default RentingProvider;

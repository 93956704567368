import React, { Fragment, useContext } from "react";
import { useHistory } from "react-router-dom";

import {
  AppBar as MuiAppBar,
  Toolbar,
  Avatar,
  Button,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import AuthContext from "stores/Authentication/auth-context";

import KINRentLogo from "assets/kaset-inno logo.png";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "showBorder",
})(({ theme, showBorder }) => ({
  borderTop: `1px solid ${theme.palette.grey[300]}`,
  backgroundColor: theme.palette.common.white,
  color: theme.palette.common.black,
  borderBottom: showBorder ? `1px solid ${theme.palette.grey[300]}` : "none",
  boxShadow: "none",
  "& .MuiTypography-root, & .MuiButton-root": {
    marginRight: theme.spacing(1.5),
    justifySelf: "flex-end",
    wordBreak: "break-word",
    marginLeft: "auto",
  },
}));

const Logo = styled("img")({
  width: "9.5rem",
});

const Navbar = (props) => {
  const history = useHistory();

  const authContext = useContext(AuthContext);

  const navigateToPage = (page) => () => {
    history.push(page);
  };

  const avatarElement = authContext?.user?.profile ? (
    <Fragment>
      <Button onClick={navigateToPage("/booking-history")}>
        ประวัติการจอง
      </Button>
      <Avatar
        className="navbar__avatar"
        alt="profile_img"
        src={authContext.user.profile.pictureUrl}
      />
    </Fragment>
  ) : (
    <Fragment>
      <Typography variant="subtitle2">KIN RENT</Typography>
      <Avatar alt="krent_profile_img">K</Avatar>
    </Fragment>
  );

  return (
    <AppBar showBorder={props.showBorder}>
      <Toolbar>
        <Logo
          onClick={navigateToPage("/")}
          src={KINRentLogo}
          alt="K-RENT_logo"
        />
        {avatarElement}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;

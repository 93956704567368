import { useContext, useCallback } from "react";
import {
  addDays,
  isWithinInterval,
  isSaturday,
  isSunday,
  isFirstDayOfMonth,
  isLastDayOfMonth,
  isBefore,
  isSameDay,
  isAfter,
} from "date-fns";
import { PickersDay } from "@mui/x-date-pickers";
import { styled } from "@mui/material/styles";

import {
  MIN_PRE_RESERVED_DAYS,
  MAX_PRE_RESERVED_DAYS,
  MIN_RENTING_DAYS,
  SHORTERM_RENTING,
} from "constants/DateTime";

import LineContext from "stores/LineLogin/line-context";

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== "dayIsBetween" &&
    prop !== "checkIsFirstDayOfMonth" &&
    prop !== "checkIsLastDayOfMonth" &&
    prop !== "checkIsSaturday" &&
    prop !== "checkIsSunday" &&
    prop !== "isFirstDay" &&
    prop !== "isLastDay",
})(
  ({
    theme,
    dayIsBetween,
    checkIsFirstDayOfMonth,
    checkIsLastDayOfMonth,
    checkIsSaturday,
    checkIsSunday,
    isFirstDay,
    isLastDay,
  }) => ({
    ...(dayIsBetween && {
      borderRadius: 0,
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    }),
    ...((isFirstDay || checkIsSunday || checkIsFirstDayOfMonth) && {
      borderTopLeftRadius: "50%",
      borderBottomLeftRadius: "50%",
    }),
    ...((isLastDay || checkIsSaturday || checkIsLastDayOfMonth) && {
      borderTopRightRadius: "50%",
      borderBottomRightRadius: "50%",
    }),
  })
);

const useDate = ({ startDate, endDate }) => {
  const { lineProfile } = useContext(LineContext);

  const disabledStartDate = useCallback(
    (date) => {
      const firstDateToPreReserved = addDays(new Date(), MIN_PRE_RESERVED_DAYS);
      const lastDateToPreReserved = addDays(new Date(), MAX_PRE_RESERVED_DAYS);

      if (lineProfile?.userId === "U1c41acb2074536d5cc3592734f42bd6f") {
        if (endDate && isBefore(endDate, lastDateToPreReserved))
          return (
            isBefore(date, addDays(new Date(), 2)) || isAfter(date, endDate)
          );
        else
          return (
            isBefore(date, addDays(new Date(), 2)) ||
            isAfter(date, lastDateToPreReserved)
          );
      } else {
        if (endDate && isBefore(endDate, lastDateToPreReserved))
          return (
            isBefore(date, firstDateToPreReserved) || isAfter(date, endDate)
          );
        return (
          isBefore(date, firstDateToPreReserved) ||
          isAfter(date, lastDateToPreReserved)
        );
      }

      // if (endDate && isBefore(endDate, lastDateToPreReserved))
      //   return isBefore(date, firstDateToPreReserved) || isAfter(date, endDate);
      // return (
      //   isBefore(date, firstDateToPreReserved) ||
      //   isAfter(date, lastDateToPreReserved)
      // );
    },
    [endDate, lineProfile]
  );

  const disabledEndDate = useCallback(
    (date) => {
      const minDateRenting = addDays(startDate, MIN_RENTING_DAYS);
      const lastDateToRentingInShortTerm = addDays(startDate, SHORTERM_RENTING);

      return (
        isBefore(date, minDateRenting) ||
        isAfter(date, lastDateToRentingInShortTerm)
      );
    },
    [startDate]
  );

  const getStartMonth = useCallback(() => {
    const firstDateToPreReserved = addDays(
      new Date(),
      MIN_PRE_RESERVED_DAYS + 1
    );
    return new Date(
      firstDateToPreReserved.getFullYear(),
      firstDateToPreReserved.getMonth()
    );
  }, []);

  const getEndMonth = useCallback(
    (reservedDays = 0) => {
      if (!startDate) return null;
      const dateToReserved = addDays(startDate, reservedDays);
      return new Date(dateToReserved.getFullYear(), dateToReserved.getMonth());
    },
    [startDate]
  );

  const renderPickingDays = useCallback(
    (date, selectedDates, pickersDayProps) => {
      if (!startDate || !endDate) return <PickersDay {...pickersDayProps} />;
      if (isBefore(endDate, startDate))
        return <PickersDay {...pickersDayProps} />;
      const dayIsBetween = isWithinInterval(date, {
        start: startDate,
        end: endDate,
      });
      // console.log("dayIsBetween: ", dayIsBetween);
      const checkIsFirstDayOfMonth = isFirstDayOfMonth(date);
      const checkIsLastDayOfMonth = isLastDayOfMonth(date);
      const checkIsSaturday = isSaturday(date);
      const checkIsSunday = isSunday(date);
      const isFirstDay = isSameDay(date, startDate);
      const isLastDay = isSameDay(date, endDate);

      return (
        <CustomPickersDay
          {...pickersDayProps}
          disableMargin
          dayIsBetween={dayIsBetween}
          checkIsFirstDayOfMonth={checkIsFirstDayOfMonth}
          checkIsLastDayOfMonth={checkIsLastDayOfMonth}
          checkIsSaturday={checkIsSaturday}
          checkIsSunday={checkIsSunday}
          isFirstDay={isFirstDay}
          isLastDay={isLastDay}
        />
      );
    },
    [startDate, endDate]
  );

  return {
    disabledStart: disabledStartDate,
    disabledEnd: disabledEndDate,
    pickedDays: renderPickingDays,
    defaultStartMonth: getStartMonth,
    defaultEndMonth: getEndMonth,
  };
};

export default useDate;

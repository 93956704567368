import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import LineContext from "./line-context";

const { liff } = window;

const AuthProvider = (props) => {
  const [lineProfile, setLineProfile] = useState("");
  const history = useHistory();
  const { search: paramsString } = useLocation();

  useEffect(() => {
    const initialLineLiff = async () => {
      let bookedId;
      if (paramsString) {
        const searchParams = new URLSearchParams(paramsString);
        bookedId = searchParams.has("bookedId")
          ? searchParams.get("bookedId")
          : null;
      }

      try {
        await liff.init({
          liffId: process.env.REACT_APP_LINE_LIFF_ID,
          withLoginOnExternalBrowser: true,
        });
        if (liff.isLoggedIn()) {
          const getProfile = await liff.getProfile();
          const { email } = await liff.getDecodedIDToken();
          const { friendFlag } = await liff.getFriendship();
          const accessToken = liff.getAccessToken();

          setLineProfile({
            ...getProfile,
            accessToken,
            email,
            friendFlag,
          });
          bookedId && history.replace(`/renting/contract-detail/${bookedId}`);
        } else
          liff.login(
            bookedId && {
              redirectUri: `${window.location.origin}/renting/contract-detail/${bookedId}`,
            }
          );
      } catch (err) {
        console.error(err);
      }
    };
    initialLineLiff();
  }, [history, paramsString]);

  return (
    <LineContext.Provider value={{ lineProfile, setLineProfile }}>
      {props.children}
    </LineContext.Provider>
  );
};

export default AuthProvider;

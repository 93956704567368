import PropTypes from "prop-types";

import { Backdrop, Grid, CircularProgress } from "@mui/material";

const ActionLoading = ({ open, color, children }) => {
  return (
    <Backdrop
      open={open}
      sx={(theme) => ({
        color: theme.palette[color].main,
        zIndex: theme.zIndex.modal + 1,
        opacity: 1,
        transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        WebkitTransition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      })}
    >
      <Grid container item direction="column" alignItems="center">
        <CircularProgress color="inherit" size={64} sx={{ mb: 6 }} />
        {children}
      </Grid>
    </Backdrop>
  );
};

ActionLoading.propTypes = {
  open: PropTypes.bool.isRequired,
  color: PropTypes.oneOf(["primary", "secondary"]),
  children: PropTypes.node,
};

ActionLoading.defaultProps = {
  open: false,
  color: "primary",
  children: null,
};

export default ActionLoading;

import React from "react";
import PropTypes from "prop-types";

import {
  FormControl,
  InputLabel,
  Select,
  ListSubheader,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      columnGap: 10,
      "& .MuiFormLabel": {
        "&-root": {
          fontSize: "small",
        },
      },
      "& .MuiInputLabel": {
        "&-formControl": {
          position: "static",
          transform: "translate(0,0)",
          width: "40%",
          [theme.breakpoints.up("nxs")]: {
            width: "25%",
          },
          [theme.breakpoints.up("sm")]: {
            width: "15%",
          },
        },
      },
      "& .MuiInputBase": {
        "&-root": {
          flexGrow: 1,
          width: "100%",
          marginLeft: "auto",
          marginTop: 0,
        },
        "&-input": {
          fontSize: "small",
        },
      },
    },
    "form__with-helper": {
      display: "flex",
      flexDirection: "column",
      flexBasis: "100%",
      "& .MuiFormHelperText-contained": {
        margin: theme.spacing(0.5, 0),
      },
    },
  };
});

const InlineSelectDropdownInput = ({
  selected,
  subheaderName,
  firstMenuItem,
  menuItems,
  valueProp,
  menuProp,
  onSelect,
  helperText,
  ...commonProps
}) => {
  const classes = useStyles();

  const handleSelect = (e) => {
    const { name, value } = e.target;
    onSelect(name, value);
  };

  const subheaderElement = subheaderName && (
    <ListSubheader>{subheaderName}</ListSubheader>
  );
  const firstMenuElement = firstMenuItem && (
    <MenuItem value={firstMenuItem.value}>{firstMenuItem.label}</MenuItem>
  );
  const menuItemsElements =
    menuItems.length > 0 &&
    menuItems.map((item, idx) => (
      <MenuItem
        key={item.id ? item.id : idx}
        value={valueProp ? item[valueProp] : item}
      >
        {menuProp ? item[menuProp] : item}
      </MenuItem>
    ));
  const helperElement = helperText && (
    <FormHelperText>{helperText}</FormHelperText>
  );

  return (
    <FormControl
      className={`${classes.root} ${commonProps.className ?? ""}`}
      disabled={commonProps.disabled}
    >
      <InputLabel id={`${commonProps.name}-select-inline-input`}>
        {commonProps.label}
      </InputLabel>
      <div className={`${classes["form__with-helper"]} select-inline-input`}>
        <Select
          labelId={`${commonProps.name}-select-inline-input`}
          name={commonProps.name}
          value={selected}
          onChange={handleSelect}
        >
          {subheaderElement}
          {firstMenuElement}
          {menuItemsElements}
        </Select>
        {helperElement}
      </div>
    </FormControl>
  );
};

InlineSelectDropdownInput.propTypes = {
  label: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  subheaderName: PropTypes.string,
  firstMenuItem: PropTypes.exact({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }),
  menuItems: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.arrayOf(PropTypes.object),
  ]).isRequired,
};

InlineSelectDropdownInput.defaultProps = {
  menuItems: [],
};

export default InlineSelectDropdownInput;

import React, { Fragment, useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { parseISO } from "date-fns";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";

import { Grid, Chip, Typography, Fab, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Carousel from "react-material-ui-carousel";

import { useHttp } from "hooks";
import {
  endpoints,
  MAXIMUM_IMPLEMENTS,
  FILTER,
  SELECTED_PRODUCTS,
} from "utils/Config";

import { Modal, OutlinedChip } from "base-components";
import {
  MachineDetailCard,
  SelectImplementCard,
  MachineList,
  ProductsCountText,
} from "components";

const ModalCarousel = styled(Modal)(({ theme }) => ({
  "& img": {
    marginTop: theme.spacing(3),
    objectFit: "contain",
    borderRadius: theme.spacing(1),
    height: "100%",
    width: "100%",
  },
  "& .MuiIconButton-root": {
    padding: theme.spacing(0),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(1),
    },
  },
}));

const MachineDetailPage = (props) => {
  const { productId } = useParams();
  const history = useHistory();

  const { sendRequest: fetchHttpData } = useHttp();

  const [imagesCarousel, setImagesCarousel] = useState({
    openModal: false,
    imageIndex: 0,
  });
  const [openImplementsModal, setOpenImplementsModal] = useState(false);

  const [productDetail, setProductDetail] = useState({
    VIN: "",
    ad_name: "",
    hour: 0,
    year: "",
    model: "",
    mainImage: "",
    images: [],
    approvedDate: "",
    price: 0,
    name: "",
  });
  const [implementLists, setImplementLists] = useState([]);
  const [selectedImplements, setSelectedImplements] = useState([]);

  useEffect(() => {
    const abortCont = new AbortController();
    const { signal } = abortCont;

    const { product_name, start_date, end_date, detailPrice } = JSON.parse(
      localStorage.getItem(FILTER)
    );
    const diffDays = differenceInCalendarDays(
      parseISO(end_date),
      parseISO(start_date)
    );

    const setImplementsDetail = (implementsDetail) => {
      const implementsWithSelect =
        implementsDetail.length > 0
          ? implementsDetail.map((implement) => ({
              ...implement,
              mainImage: implement.mainimage,
              select: false,
            }))
          : [];
      setImplementLists(implementsWithSelect);
    };

    const setMainProductDetail = (productDetail) => {
      setProductDetail({
        VIN: productDetail.VIN,
        ad_name: productDetail.ad_name,
        hour: productDetail.hour,
        year: productDetail.year,
        model: productDetail.model,
        mainImage: productDetail.mainimage,
        images: productDetail.image,
        approvedDate: productDetail.approvedDate,
        price: detailPrice,
        name: product_name,
      });
      fetchHttpData(
        {
          endpoint: endpoints.queryImplements(
            productDetail.model,
            productDetail.ad_name,
            diffDays
          ),
        },
        setImplementsDetail
      );
    };

    const fetchData = (signal) => {
      fetchHttpData(
        {
          endpoint: endpoints.queryVIN(productId),
          signal,
        },
        setMainProductDetail
      );
    };

    fetchData(signal);

    window.addEventListener("storage", fetchData);

    return () => {
      window.removeEventListener("storage", fetchData);
      abortCont.abort();
    };
  }, [fetchHttpData, productId]);

  const clickSeeMoreHandler = (_, __) => {
    return;
  };

  const clickOpenImgsCarouselModal = (idx) => {
    setImagesCarousel(() => ({
      openModal: true,
      imageIndex: idx,
    }));
  };

  const clickCloseImgsCarouselModal = () => {
    setImagesCarousel((prevImagesCarousel) => ({
      ...prevImagesCarousel,
      openModal: false,
    }));
  };

  const clickOpenImplementsModal = () => {
    setOpenImplementsModal(true);
  };

  const clickCloseImplementsModal = () => {
    setOpenImplementsModal(false);
  };

  const clickSelectImplementList = (VIN, isSelect) => {
    setImplementLists((prevImpLists) => {
      const setSelectPropsImplements = prevImpLists.map((imp) => {
        return imp.VIN === VIN ? { ...imp, isSelect } : { ...imp };
      });
      return setSelectPropsImplements;
    });
  };

  const clearAllSelectImplements = () => {
    setImplementLists((prevImpLists) => {
      const setSelectFalseImplements = prevImpLists.map((imp) => ({
        ...imp,
        isSelect: false,
      }));
      return setSelectFalseImplements;
    });
    setSelectedImplements([]);
  };

  const confirmSelectImplements = () => {
    const filterSelectedImps = implementLists.filter((imp) => imp.isSelect);
    setSelectedImplements(filterSelectedImps);
  };

  const removeImplements = (VIN) => {
    setImplementLists((prevImpLists) => {
      const setSelectPropsImplements = prevImpLists.map((imp) => {
        return imp.VIN === VIN ? { ...imp, isSelect: false } : { ...imp };
      });
      setSelectedImplements((prevSeletedImps) => {
        const remainSelectedImps = prevSeletedImps.filter(
          (imp) => imp.VIN !== VIN
        );
        return remainSelectedImps;
      });
      return setSelectPropsImplements;
    });
  };

  const backSelectionPage = () => {
    history.go(-1);
  };

  const clickConfirmSelection = (vin) => () => {
    /* Set SELECTED_PRODUCT in local storage */
    const selectedProducts = {
      mainProduct: productDetail,
      implements: selectedImplements,
    };

    localStorage.setItem(SELECTED_PRODUCTS, JSON.stringify(selectedProducts));
    history.push(`/machine-selection/${vin}/confirm`);
  };

  return (
    <Fragment>
      <Grid container justifyContent="center" p={2}>
        <Grid item xs={12} mb={1}>
          <OutlinedChip
            color="primary"
            label="รายการสินค้าที่ให้บริการ"
            sx={{ width: "50%" }}
          />
        </Grid>
        <Grid item xs={12} mb={1} ml={3}>
          <Typography
            color="secondary"
            variant="caption"
          >{`> รายละเอียดสินค้า`}</Typography>
        </Grid>

        <Grid item xs={12} mb={4}>
          <MachineDetailCard
            vin={productDetail.VIN}
            name={productDetail.name}
            model={productDetail.model}
            hour={productDetail.hour}
            year={productDetail.year}
            price={productDetail.price}
            approvedDate={productDetail.approvedDate}
            mainImage={productDetail.mainImage}
            detailImgs={productDetail.images}
            onClickLink={backSelectionPage}
            onClickImage={clickOpenImgsCarouselModal}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          mr={{ xs: 0, sm: 4, md: 8 }}
          mb={{ xs: 4, sm: 6 }}
        >
          {selectedImplements.length > 0 &&
            selectedImplements.map((imp, idx) => (
              <SelectImplementCard
                key={idx}
                id={imp.VIN}
                mainImgUrl={imp.mainImage}
                model={imp.model}
                price={imp.price}
                implementCount={idx + 1}
                onClick={removeImplements}
              />
            ))}
          {selectedImplements.length < MAXIMUM_IMPLEMENTS && (
            <SelectImplementCard
              adding
              implementCount={selectedImplements.length}
              onClick={clickOpenImplementsModal}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Fab
            color="secondary"
            onClick={clickConfirmSelection(productDetail.VIN)}
            variant="extended"
            sx={{ height: (theme) => theme.spacing(5), minWidth: "100%" }}
          >
            ตกลง
          </Fab>
        </Grid>
      </Grid>

      {/* Implement lists modal */}
      <Modal
        fullWidth
        maxWidth="md"
        dialogTitle={<Chip color="primary" label="รายการสินค้าที่ให้บริการ" />}
        titleWithClose
        openModal={openImplementsModal}
        onCloseModal={clickCloseImplementsModal}
        footerChildren={
          <Fragment>
            <Button
              onClick={() => {
                clearAllSelectImplements();
                clickCloseImplementsModal();
              }}
            >
              ยกเลิก
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                confirmSelectImplements();
                clickCloseImplementsModal();
              }}
            >
              ตกลง
            </Button>
          </Fragment>
        }
      >
        <Fragment>
          <Grid container>
            {implementLists.length > 0 ? (
              <Fragment>
                <Grid item xs={12} ml={2}>
                  <Typography
                    color="secondary"
                    component="h6"
                    variant="caption"
                    sx={{ fontSize: ".8rem" }}
                  >
                    อุปกรณ์ต่อพ่วง
                  </Typography>
                </Grid>
                <Grid item xs={12} mb={1}>
                  <ProductsCountText productsCount={implementLists.length} />
                </Grid>
              </Fragment>
            ) : (
              <Typography align="center" variant="h6">
                ไม่พบอุปกรณ์ต่อพ่วงอื่นๆ
              </Typography>
            )}

            <Grid item xs={12}>
              {implementLists.length > 0
                ? implementLists.map((implement, idx, arrayImps) => {
                    const selectedImps = arrayImps.filter(
                      (imp) => imp.isSelect
                    );
                    return (
                      <MachineList
                        key={idx}
                        vin={implement.VIN}
                        name="อุปกรณ์ต่อพ่วง"
                        model={implement.model}
                        price={implement.price}
                        mainImgUrl={implement.mainimage}
                        moreImages={implement.image}
                        selectList
                        selected={implement.isSelect}
                        isDisabledSelect={
                          selectedImps.length === MAXIMUM_IMPLEMENTS &&
                          !implement.isSelect
                        }
                        onClickList={clickSeeMoreHandler}
                        onSelectList={clickSelectImplementList}
                        sx={{ pb: 0, mb: 2 }}
                      />
                    );
                  })
                : null}
            </Grid>
          </Grid>
        </Fragment>
      </Modal>

      <ModalCarousel
        fullWidth
        maxWidth="sm"
        openModal={imagesCarousel.openModal}
        onCloseModal={clickCloseImgsCarouselModal}
        titleWithClose
      >
        <Carousel
          index={imagesCarousel.imageIndex}
          navButtonsAlwaysVisible
          autoPlay={false}
          animation="slide"
          height="240px"
        >
          {productDetail?.images?.length > 0 &&
            productDetail.images.map(({ image }, idx) => (
              <img key={idx} src={image} alt={`${idx}-img`} />
            ))}
        </Carousel>
      </ModalCarousel>
    </Fragment>
  );
};

export default MachineDetailPage;

import { useState, useCallback, useContext } from "react";
import LineContext from "stores/LineLogin/line-context";

const useHttp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const lineCtx = useContext(LineContext);

  const sendRequest = useCallback(
    async (requestConfig, applyData) => {
      if (!lineCtx?.lineProfile?.accessToken) return;
      setIsLoading(true);
      setError(null);

      const {
        url,
        endpoint = "",
        method = "GET",
        headers,
        body,
        signal = null,
      } = requestConfig;
      const apiUrl = `${
        url ? url : process.env.REACT_APP_API_BASE_URL
      }${endpoint}`;
      const headersOption = {
        line_token: lineCtx.lineProfile.accessToken,
        ...((method === "POST" || method === "PUT") && {
          "Content-Type": "application/json",
        }),
        ...headers,
      };

      try {
        const response = await fetch(apiUrl, {
          method,
          headers: headersOption,
          body: body ? JSON.stringify(body) : null,
          signal: signal ? signal : null,
        });
        if (!response.ok) {
          throw new Error("Request failed!");
        }

        const data = await response.json();

        applyData(data);
      } catch (err) {
        setError(err.message || "Something went wrong!");
      }
      setIsLoading(false);
    },
    [lineCtx]
  );

  return {
    isLoading,
    error,
    sendRequest,
  };
};

export default useHttp;

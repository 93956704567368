import { MuiDialog, MuiPaper, MuiButton, MuiList } from "./components";

export const components = {
  MuiCssBaseline: {
    styleOverrides: `
      @font-face {
        font-family: 'Prompt';
        font-style: normal;
        font-display: swap;
        font-weight: 400;
        src:  url('https://krent-font.azureedge.net/fonts/Prompt/truetype/Prompt-Regular.ttf') format('truetype'),
              url('https://krent-font.azureedge.net/fonts/Prompt/woff/Prompt-Regular.woff') format('woff'),
              url('https://krent-font.azureedge.net/fonts/Prompt/woff2/Prompt-Regular.woff2') format('woff2');
      }
    `,
  },
  MuiDialog,
  MuiPaper,
  MuiButton,
  MuiList,
};

export const typography = {
  fontFamily: "Prompt",
};

export const breakpoints = {
  values: {
    xs: 0,
    nxs: 300,
    sm: 600,
    md: 960,
    lg: 1280,
    xl: 1920,
  },
};

export const palette = {
  background: {
    default: "#fff",
  },
  primary: {
    main: "#0DB14B",
    contrastText: "#fff",
    light: "#DBF3E4",
  },
  secondary: {
    main: "#7F0166",
    contrastText: "#fff",
    light: "#D7B0D0",
  },
};

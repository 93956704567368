import PropTypes from "prop-types";

import { Paper as MuiPaper, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const Paper = styled((props) => <MuiPaper elevation={0} {...props} />)(
  ({ theme, color }) => ({
    backgroundColor: theme.palette[color].light,
    padding: theme.spacing(1, 0),
    borderRadius: theme.spacing(1),
  })
);

const HighlightPromotionCard = ({
  title,
  color,
  discountLists,
  totalDiscount,
}) => {
  const promoCodeValueLists = discountLists.map((discount, idx) => (
    <Grid key={idx} container item alignItems="center" xs={12} mx={3} mb={1}>
      <Typography variant="subtitle2" sx={{ flexBasis: "40%", pl: 2 }}>
        {discount?.nameTH}
      </Typography>
      <Typography align="center" sx={{ flexGrow: 0.55 }}>
        - {discount?.value?.toLocaleString()}
      </Typography>
      <Typography variant="subtitle2" sx={{ flexGrow: 0.45 }}>
        บาท
      </Typography>
    </Grid>
  ));

  return (
    <Paper elevetion={0} color={color}>
      <Grid container item alignItems="center" xs={12} mx={3} mb={1}>
        <Typography
          variant="subtitle2"
          sx={{ color: (theme) => theme.palette[color].main }}
        >
          {title}
        </Typography>
      </Grid>

      {promoCodeValueLists}

      <Grid container item alignItems="center" xs={12} mx={3}>
        <Typography variant="subtitle2" sx={{ flexBasis: "40%", pl: 2 }}>
          รวมส่วนลด
        </Typography>
        <Typography align="center" sx={{ flexGrow: 0.55 }}>
          - {totalDiscount?.toLocaleString()}
        </Typography>
        <Typography variant="subtitle2" sx={{ flexGrow: 0.45 }}>
          บาท
        </Typography>
      </Grid>
    </Paper>
  );
};

HighlightPromotionCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
  ]),
  discountLists: PropTypes.array.isRequired,
  totalDiscount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

HighlightPromotionCard.defaultProps = {
  color: "secondary",
  discountLists: [],
  totalDiscount: 0,
};

export default HighlightPromotionCard;
